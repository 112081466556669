@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap);@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
/* > reset */
/******************************************/
/*		slick
/******************************************/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url(../assets/slick.eot?ced611da);
  src: url(../assets/slick.eot?ced611da?#iefix) format("embedded-opentype"), url(../assets/slick.woff?b7c9e1e4) format("woff"), url(../assets/slick.ttf?d41f55a7) format("truetype"), url(../assets/slick.svg?f97e3bbf#slick) format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		mCustomScrollbar
/******************************************/
/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
          box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
          box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
          box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
          box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
          box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  -webkit-box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
          box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
          box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
/******************************************/
/*		fullpage
/******************************************/
@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, textarea, p, blockquote {
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

table {
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

strong {
  font-weight: bold; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
  color: #444; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

/* Custom CSS
 * --------------------------------------- */
body {
  font-family: arial,helvetica;
  color: #333;
  color: rgba(0, 0, 0, 0.5); }

.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  position: relative; }

h1 {
  font-size: 6em; }

p {
  font-size: 2em; }

.intro p {
  width: 50%;
  margin: 0 auto;
  font-size: 1.5em; }

.section {
  text-align: center; }

#menu li {
  display: inline-block;
  margin: 10px;
  color: #000;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px; }

#menu li.active {
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  color: #fff; }

#menu li a {
  text-decoration: none;
  color: #000; }

#menu li.active a:hover {
  color: #000; }

#menu li:hover {
  background: rgba(255, 255, 255, 0.8); }

#menu li a,
#menu li.active a {
  padding: 9px 18px;
  display: block; }

#menu li.active a {
  color: #fff; }

#menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin: 0; }

.twitter-share-button {
  position: fixed;
  z-index: 99;
  right: 149px;
  top: 9px; }

#download {
  margin: 10px 0 0 0;
  padding: 15px 10px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5bc0de), to(#2f96b4));
  background-image: linear-gradient(top, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border-radius: 6px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

#download a {
  text-decoration: none;
  color: #fff; }

#download:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #2F96B4;
  background-position: 0 -15px;
  -webkit-transition: background-position .1s linear;
  -o-transition: background-position .1s linear;
  transition: background-position .1s linear; }

#infoMenu {
  height: 20px;
  color: #f2f2f2;
  position: fixed;
  z-index: 70;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
  padding: 8px 0 8px 0; }

#infoMenu ul {
  padding: 0 40px; }

#infoMenu li a {
  display: block;
  margin: 0 22px 0 0;
  color: #333; }

#infoMenu li a:hover {
  text-decoration: underline; }

#infoMenu li {
  display: inline-block;
  position: relative; }

#examplesList {
  display: none;
  background: #282828;
  border-radius: 6px;
  padding: 20px;
  float: left;
  position: absolute;
  bottom: 29px;
  right: 0;
  width: 638px;
  text-align: left; }

#examplesList ul {
  padding: 0; }

#examplesList ul li {
  display: block;
  margin: 5px 0; }

#examplesList ul li a {
  color: #BDBDBD;
  margin: 0; }

#examplesList ul li a:hover {
  color: #f2f2f2; }

#examplesList .column {
  float: left;
  margin: 0 20px 0 0; }

#examplesList h3 {
  color: #f2f2f2;
  font-size: 1.2em;
  margin: 0 0 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  padding: 0 0 5px 0; }

/* Demos Menu
 * --------------------------------------- */
#demosMenu {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

/*把變數帶入mixin中，此mixin只就單純把media queries寫到mixin中，
並且使用上面設立的變數來調動media queries的width*/
main[role="main"], section, article {
  zoom: 1; }
  main[role="main"]:before, section:before, article:before, main[role="main"]:after, section:after, article:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role="main"]:after, section:after, article:after {
    clear: both; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::-moz-selection,
h1::-moz-selection,
h2::-moz-selection,
h3::-moz-selection,
h4::-moz-selection,
b::-moz-selection,
strong::-moz-selection,
span::-moz-selection,
li::-moz-selection,
div::-moz-selection,
a::-moz-selection,
img::-moz-selection,
tr::-moz-selection,
td::-moz-selection,
th::-moz-selection {
  color: #ffffff;
  background: #0073d8; }
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection {
  color: #ffffff;
  background: #0073d8; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 16px;
  color: #000000;
  word-break: break-word;
  letter-spacing: 0.1em;
  z-index: 0;
  background: #ffffff;
  -webkit-text-size-adjust: 100%;
  opacity: 0;
  -webkit-transition: 1.2s;
  -o-transition: 1.2s;
  transition: 1.2s;
  font-family: "Noto Sans TC"; }

body.loading {
  opacity: 1;
  -webkit-transition: opacity 1.2s;
  -o-transition: opacity 1.2s;
  transition: opacity 1.2s; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  -webkit-transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  -webkit-transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 56px;
  outline: none;
  padding: 0;
  color: #000;
  border: 1px solid #ffffff;
  font-size: 1rem;
  border-radius: 5px; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 0.9375rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 218px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #666;
  line-height: 18px; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666;
  line-height: 43px; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666;
  line-height: 43px; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #666;
  line-height: 18px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type='date'],
input[type='time'] {
  -webkit-appearance: none; }

select {
  font-family: "Noto Sans TC", "微軟正黑體", Verdana, sans-serif;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

select::-ms-expand {
  display: none; }

.selectBox {
  background: #FFF; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 110px; }
  @media (max-width: 991px) {
    main[role="main"] {
      margin-top: 60px; } }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
/*----------------------tag_list------------------------*/
.tag_list a {
  color: #ffffff;
  text-decoration: none;
  border-radius: 10px;
  background: #434343;
  padding: 3px 7px;
  font-size: 0.9375rem; }
  .tag_list a:not(:last-child) {
    margin-right: 15px; }
  .tag_list a:hover {
    background: #2f3a88; }

/*----------------------編輯器------------------------*/
.editor_Content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 0;
  line-height: 1.88;
  color: #6d6d6d;
  letter-spacing: 0.4px; }
  .editor_Content header {
    width: 100%;
    padding-bottom: 31px;
    margin-bottom: 29px;
    border-bottom: solid 1px #7d7d7d;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .editor_Content header h2 {
      width: 80%;
      font-size: 1.25rem;
      line-height: 1.4;
      color: #ffffff;
      padding-right: 2%; }
    .editor_Content header .tag_list {
      text-align: right;
      width: calc(100% - 73%); }
  .editor_Content ul, .editor_Content ol {
    padding-left: 40px; }
  .editor_Content ol {
    list-style: decimal; }
  .editor_Content ul {
    list-style: disc; }
  .editor_Content img {
    max-width: 100%;
    height: auto !important; }
  .editor_Content b, .editor_Content strong {
    font-weight: bold; }
  .editor_Content strong {
    font-size: inherit;
    font-style: inherit; }
  .editor_Content em {
    font-style: italic; }
  .editor_Content a {
    text-decoration: underline;
    color: #0782C1; }
  .editor_Content iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Content iframe {
        height: auto !important; } }
  .editor_Content h1, .editor_Content h2, .editor_Content h3, .editor_Content h4, .editor_Content h5, .editor_Content h6 {
    margin: inherit; }
  .editor_Content blockquote {
    font-style: italic;
    padding: 2px 20px 0 8px;
    margin-left: 40px;
    border-left: 5px solid #ccc; }
  .editor_Content td, .editor_Content th, .editor_Content tr {
    margin: initial;
    padding: initial; }
  .editor_Content table {
    border-collapse: inherit; }

.editor_Box {
  margin: auto;
  line-height: 1.5;
  font-size: 0.9375rem;
  color: #6d6d6d; }
  .editor_Box ul,
  .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: auto !important; } }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------back------------------------*/
.back_btn,
.more_btn {
  width: 100%;
  margin-top: 70px;
  font-size: 1.125rem; }
  .back_btn a,
  .more_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9;
    border-radius: 5px; }
    .back_btn a:hover,
    .more_btn a:hover {
      background: #4759d9;
      opacity: .5; }

/*----------------------上下則------------------------*/
.seesaw {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin-top: 61px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .seesaw a {
    color: #de4242;
    text-decoration: none; }
  .seesaw .left_button:before,
  .seesaw .right_button:before,
  .seesaw .back_button {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s; }
  .seesaw .left_button,
  .seesaw .right_button {
    position: absolute; }
    .seesaw .left_button:before,
    .seesaw .right_button:before {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: #de4242;
      margin-bottom: 2px; }
    .seesaw .left_button:hover:before,
    .seesaw .right_button:hover:before {
      width: 90px; }
  .seesaw .left_button {
    right: 0; }
  .seesaw .right_button {
    left: 0;
    text-align: right; }
  .seesaw .back_button {
    font-size: 1rem;
    width: 230px;
    height: auto;
    padding: 11px 0;
    border: 1px solid #de4242;
    text-align: center; }
    .seesaw .back_button:hover {
      background: #de4242; }
      .seesaw .back_button:hover a {
        color: #ffffff; }

/*----------------------GO TOP------------------------*/
.show-topbtn.top_btn::before, .show-topbtn.top_btn::after {
  opacity: 1; }

.show-topbtn.top_btn span {
  opacity: 1; }

.top_btn {
  cursor: pointer;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  display: block;
  padding-right: 8px;
  padding-top: 30px;
  -webkit-transition: opacity,padding .4s;
  -o-transition: opacity,padding .4s;
  transition: opacity,padding .4s; }
  .top_btn span {
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.5px;
    font-weight: 500;
    color: #000000;
    color: #2cd9d2;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    opacity: 0;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    top: -6px;
    position: relative; }
    .top_btn span::before {
      position: absolute;
      content: '';
      left: -8px;
      top: 0px;
      width: 1px;
      height: 49px;
      background-color: #2cd9d2;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      opacity: 1; }
    .top_btn span::after {
      position: absolute;
      content: '';
      left: -4px;
      width: 1px;
      bottom: -17px;
      height: 10px;
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
      background-color: #2cd9d2;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      opacity: 1; }
  @media screen and (max-width: 768px) {
    .top_btn {
      bottom: 26px; } }
  .top_btn.active {
    opacity: 1; }
  .top_btn:hover {
    color: #2cd9d2; }
  @media (min-width: 768px) {
    .top_btn:hover {
      padding-top: 45px; } }
  .top_btn.fix {
    position: absolute;
    bottom: 7px; }
    @media screen and (max-width: 768px) {
      .top_btn.fix {
        position: absolute;
        bottom: 20px; } }

/*----------------------頁數------------------------*/
.page dt.ltbn a:before, .page dt.rtbn a:before {
  display: block;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%); }

.page {
  font-size: 1.125rem;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 0 auto; }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block; }
    .page dt a,
    .page dd a {
      display: block;
      height: 32px;
      line-height: 30px;
      text-decoration: none;
      color: #ffffff; }
      .page dt a:hover,
      .page dd a:hover {
        color: #2f3a88; }
  .page dd {
    width: 2.3%; }
  .page dd.active a {
    border-radius: 99em;
    background: none;
    padding: 0;
    color: #4759d9;
    font-weight: bold; }
  .page dt.rtbn a,
  .page dt.ltbn a {
    width: 32px;
    height: 32px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border-radius: 99em;
    background: #2b2c2e; }
  .page .nopage {
    opacity: 0.2; }
  .page dt.ltbn a {
    margin-right: 17px; }
    .page dt.ltbn a:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 7px 7px 0;
      border-color: transparent #ffffff transparent transparent; }
  .page dt.rtbn a {
    margin-left: 17px; }
    .page dt.rtbn a:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 0 7px 7px;
      border-color: transparent transparent transparent #ffffff; }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  .top_btn {
    right: 25px; }
  right: 20px; }

@media (max-width: 767px) {
  .page {
    font-size: 1.0625rem; }
    .page dd {
      width: 11%; }
    .page dt.ltbn a {
      margin-right: 0.875rem; }
      .page dt.ltbn a:before {
        border-width: 5px 5px 5px 0; }
    .page dt.rtbn a {
      margin-left: 0.875rem; }
      .page dt.rtbn a:before {
        border-width: 5px 0 5px 5px; }
    .page dt.ltbn a,
    .page dt.rtbn a {
      width: 28px;
      height: 28px; }
  .back_btn,
  .more_btn {
    font-size: 1.0625rem; }
    .back_btn a,
    .more_btn a {
      padding: 12.5px 25px;
      border-radius: 4px; } }

/*----------------------header------------------------*/
.header_show {
  -webkit-transition: .4S;
  -o-transition: .4S;
  transition: .4S;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 10; }
  .header_show li {
    list-style: none; }
  .header_show.active {
    background: #131314; }

.top-headebx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 46px;
  background-color: #0073d8;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 40px; }
  @media (max-width: 991px) {
    .top-headebx {
      display: none; } }
  .top-headebx .l-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .top-headebx .l-bx a {
      display: block;
      position: relative;
      padding-left: 25px;
      color: #ffffff; }
      .top-headebx .l-bx a:first-child {
        margin-right: 35px; }
    .top-headebx .l-bx .tel::before {
      position: absolute;
      content: '';
      left: 0;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAXVBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+EHRzTAAAAH3RSTlMA++9ypZMHG1Q1wOr0eNriKA/QQrmDzS2xSGCLYkuHqgMijAAAAJZJREFUCNdNT9cOAzEIcyaQdXt2/P9nNjm1p/rFwthGADntHoAxPRpYT+O7sU07VQ4GogiDO8nGakkb0DF8Xjt5FI+6y2UAu81oGg3iREqAzSWx7rnCOtFX8bK/Sq8waYpHm6cgkVQ94Ezxl8MLGOgTrxZ/YH2UcNro/FdYOsn7Q8I6/ATNLT7fMY4zE9lwdwx5VKr98wE1igZkf32t8wAAAABJRU5ErkJggg==) no-repeat center center/15px;
      top: 2px;
      width: 15px;
      height: 15px; }
    .top-headebx .l-bx .mail::before {
      position: absolute;
      content: '';
      left: 0;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAMAAABcOc2zAAAANlBMVEX///////////////////////////////9HcEz////////////////////////////////////d4/HTAAAAEXRSTlMQvvZqNnovIADvQnCKZNTeRHfm5BEAAABUSURBVAjXZc5ZDoAwCARQaAGhi8r9L2ttbaJ1/uYlLID+CYLL9oo4OOxJn6QDboiBRqcQOyhbFzLWAU1EVVqfQMVytnJOYIOKWGGO/JauZ9fH1tcvskUGw3Z4+lAAAAAASUVORK5CYII=) no-repeat center center/15px;
      top: 2px;
      width: 15px;
      height: 15px; }
  .top-headebx .r-bx ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    .top-headebx .r-bx ul li:not(:last-child) {
      margin-right: 30px; }
    .top-headebx .r-bx ul li a {
      color: #ffffff;
      height: 46px;
      display: block;
      line-height: 2.7;
      position: relative; }
      @media (min-width: 768px) {
        .top-headebx .r-bx ul li a:hover::before {
          opacity: 1; } }
      .top-headebx .r-bx ul li a::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ffffff;
        opacity: 0;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s; }

.header_box {
  position: relative;
  width: 100%;
  padding: 0 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.875rem;
  height: 64px; }

.header_box.sear-ing nav {
  -webkit-animation: downani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
          animation: downani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: none; }

@-webkit-keyframes downani {
  0% {
    top: 0;
    opacity: 1; }
  100% {
    top: 15px;
    opacity: 0; } }

@keyframes downani {
  0% {
    top: 0;
    opacity: 1; }
  100% {
    top: 15px;
    opacity: 0; } }

.header_box.sear-ing .search-img {
  -webkit-animation: sedownani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
          animation: sedownani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: none; }

@-webkit-keyframes sedownani {
  0% {
    top: 0;
    opacity: 1; }
  100% {
    top: 10px;
    opacity: 0; } }

@keyframes sedownani {
  0% {
    top: 0;
    opacity: 1; }
  100% {
    top: 10px;
    opacity: 0; } }

.header_box.sear-ing .search-clos {
  -webkit-animation: sedani 1s 0.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;
          animation: sedani 1s 0.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: auto; }

@-webkit-keyframes sedani {
  0% {
    top: -10px;
    opacity: 0; }
  100% {
    top: 0px;
    opacity: 1; } }

@keyframes sedani {
  0% {
    top: -10px;
    opacity: 0; }
  100% {
    top: 0px;
    opacity: 1; } }

.header_box.sear-ing .search .inp-bx {
  -webkit-animation: inputani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
          animation: inputani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: auto; }

@-webkit-keyframes inputani {
  0% {
    top: 10px;
    opacity: 0; }
  100% {
    top: 5px;
    opacity: 1; } }

@keyframes inputani {
  0% {
    top: 10px;
    opacity: 0; }
  100% {
    top: 5px;
    opacity: 1; } }

.header_box.searclos-ing nav {
  -webkit-animation: upani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
          animation: upani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: auto; }

@-webkit-keyframes upani {
  0% {
    top: 15px;
    opacity: 0; }
  100% {
    top: 0px;
    opacity: 1; } }

@keyframes upani {
  0% {
    top: 15px;
    opacity: 0; }
  100% {
    top: 0px;
    opacity: 1; } }

.header_box.searclos-ing .search-img {
  -webkit-animation: seaupani 1s 0.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;
          animation: seaupani 1s 0.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: auto;
  opacity: 0; }

@-webkit-keyframes seaupani {
  0% {
    top: 10px;
    opacity: 0; }
  100% {
    top: 0px;
    opacity: 1; } }

@keyframes seaupani {
  0% {
    top: 10px;
    opacity: 0; }
  100% {
    top: 0px;
    opacity: 1; } }

.header_box.searclos-ing .search-clos {
  -webkit-animation: clupani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
          animation: clupani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: none; }

@-webkit-keyframes clupani {
  0% {
    top: 0px;
    opacity: 1; }
  100% {
    top: -10px;
    opacity: 0; } }

@keyframes clupani {
  0% {
    top: 0px;
    opacity: 1; }
  100% {
    top: -10px;
    opacity: 0; } }

.header_box.searclos-ing .search .inp-bx {
  -webkit-animation: inputdoani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
          animation: inputdoani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: none; }

@-webkit-keyframes inputdoani {
  0% {
    top: 5px;
    opacity: 1; }
  100% {
    top: 10px;
    opacity: 0; } }

@keyframes inputdoani {
  0% {
    top: 5px;
    opacity: 1; }
  100% {
    top: 10px;
    opacity: 0; } }

.logo {
  width: 86px;
  height: 35px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAAxCAMAAAAm7Z1EAAAAPFBMVEVHcEwAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOnJ0x7kAAAAE3RSTlMA0HB/QN71D1CfBjK+j2GuJRnprUfoaQAAAw9JREFUWMPFWMeirCAMVSlKE4T//9dnAxLKvM0d5uwEJKSdBKbphggXuD6mwZjDg2WwXMl/JNi9cgMdLHiLgtdvStHzA5GHyCvXf1PuEbUzecy+Q3qIWfc8xuqz/D2WOpDWxln+HqIOJMVfqBFcwX7FFTaPrRH3gvghz4ldzIxps0314nN6okazc544FL10wzh9eizEREvP5AKKrRmmVlCSxEMGlkTTkEiORuOFIGQKoTyYVzpejjEUWxamVlBoC1PF5gKnbZGYAF7muMLrVfwiMAa4x0tNEZsGTz8mMbXca9e5NThNO/xZhh42FJslNFIBgK+tPTd0TNf593XL7UfemeXID8jDuQZl3OGo4b97+Kiy684e0A/ZwQSSZRESHloLeolrCy0r8B6COrd7pMObHl5FPB3NJoRIG9nzY1lR1RBQ/SuFLtu6PDDDZHvpdYMarzVFfCJMkJlA/aDfFXKGbrRFkEs4SWHI/p8wUdVI6vu1KmcBcA2PbSFvmEMsEYl3ZKitgYxA60J1AMEyFJUbkY9tp2ttVmwED9XnshbMSq4pycd3cr9djJOxNFR/nuoY0AXXlORz1Dm2VmaVdYdpoPqgCTJAKcQ1JfnQ0CwFdR1qtEKpaOa270hcpUCypc4RBPXS4QkYCo0O89oXEFO0Zc6mGXgxHRwORHOaPUJV7trrDvO2fk4d/kQBqI078KItA08ANx0fGj01ta0PCVMbYgBjzhIcnLSyhPd7KgvLQSuzbb9EUHjwrZElUXsmICTSDsQszkzWlUtQUqgG+TQLqu1eIlBmrl25AncBskE+tPWfwh7Z6iz1a+9fcFJeBjUkH9frS3LmcBB3GnJocplBjQbfC64hTfJhPYVT6MDr95OIfsEucyrvwsVRcE2smMU9bOMdQ8VsR1czekHJgofkJHd97ePtcoBW/UY1QOlzEne1/xk6soW8W3jy4YJU+PC5Lox8kRC/epEY/QZDfvUGY0Of5r8KNuINpoE1jHiDaUCFEW8wDewNKh8Cg66FA/ES5uxGvx17pq3Z1Rhh/wDBsp9E9q06FAAAAABJRU5ErkJggg==) no-repeat;
  background-size: contain;
  text-indent: -10000px;
  margin: 25px 0; }
  .logo a {
    width: 100%;
    height: 100%;
    display: block; }

.search {
  position: fixed;
  right: 37px;
  width: 47px;
  height: 47px;
  top: 54px; }
  @media (max-width: 991px) {
    .search {
      display: block;
      position: relative;
      right: auto;
      top: auto;
      width: 100%;
      height: 80px;
      padding: 0 40px;
      border-bottom: 1px solid #a4d4ff;
      border-top: 1px solid #a4d4ff; } }
  .search .search-img {
    border: 1px solid rgba(255, 255, 255, 0); }
    @media (min-width: 768px) {
      .search .search-img:hover {
        border: 1px solid #0073d8; } }
  .search a {
    display: block;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 50%;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    padding: 3px;
    width: 47px;
    height: 47px;
    position: absolute;
    top: 0;
    right: 0; }
    @media (max-width: 991px) {
      .search a {
        width: 20px;
        height: 20px;
        position: relative; } }
    .search a img {
      width: 41px;
      height: 40px; }
      @media (max-width: 991px) {
        .search a img {
          width: 20px;
          height: 20px; } }
  .search .inp-bx {
    position: absolute;
    height: 30px;
    width: 330px;
    right: 60px;
    top: 10px;
    opacity: 0;
    pointer-events: none; }
    @media (max-width: 991px) {
      .search .inp-bx {
        opacity: 1;
        pointer-events: auto;
        right: auto;
        left: 75px;
        width: 100%;
        top: 25px; } }
    .search .inp-bx input {
      height: 30px;
      width: 330px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #0073d8; }
      @media (max-width: 991px) {
        .search .inp-bx input {
          width: calc(100% - 115px); } }
      .search .inp-bx input:focus {
        border: none;
        border-bottom: 1px solid #0073d8; }
  .search .search-img {
    right: -3px; }
    @media (max-width: 991px) {
      .search .search-img {
        right: auto;
        padding-top: 25px;
        padding-left: 0; } }
  .search .search-clos {
    width: 47px;
    height: 47px;
    opacity: 0;
    pointer-events: none;
    top: 0;
    text-align: center;
    padding: 0;
    right: -3px; }
    .search .search-clos img {
      width: 27px;
      height: 28px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

/*----------------------nav------------------------*/
nav {
  position: relative; }

.nav_box {
  position: relative;
  font-size: 1.0625rem;
  line-height: 1;
  letter-spacing: 0px;
  font-weight: 400;
  color: #000000;
  margin: 0 auto;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  left: -43px; }
  @media (max-width: 991px) {
    .nav_box {
      left: auto; } }
  .nav_box nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    @media (max-width: 1200px) {
      .nav_box nav {
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; } }
  .nav_box ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative; }
    @media (max-width: 1200px) {
      .nav_box ul {
        width: 100%; } }
    @media (min-width: 1201px) {
      .nav_box ul .active a {
        color: #0073d8; }
        .nav_box ul .active a::before {
          opacity: 1; } }
    .nav_box ul li.hoing {
      background-color: #0073d8; }
      .nav_box ul li.hoing > a {
        color: #ffffff; }
    .nav_box ul li {
      position: relative;
      text-align: center;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s; }
      .nav_box ul li a {
        padding: 24px 20px;
        color: #0073d8;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s; }
        .nav_box ul li a::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #0073d8;
          left: 0;
          bottom: -2px;
          opacity: 0;
          -webkit-transition: .4s;
          -o-transition: .4s;
          transition: .4s; }
  .nav_box a {
    width: 100%;
    display: block;
    color: #000000;
    text-decoration: none;
    font-weight: 300;
    font-size: 1rem; }
    .nav_box a:after {
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      position: relative;
      content: '';
      width: 0;
      height: 2px;
      background: #4759d9;
      bottom: -26px;
      left: 8px;
      opacity: 0; }
    @media (max-width: 992px) {
      .nav_box a {
        font-weight: 400; } }
  .nav_box .activeh {
    color: #0073d8; }
  .nav_box .chlan-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 4px 15px;
    border: solid 1px #a0a0a0;
    border-radius: 99px; }
    .nav_box .chlan-box div:not(:last-child) {
      margin-right: 15px;
      position: relative; }
      @media (max-width: 1200px) {
        .nav_box .chlan-box div:not(:last-child)::before {
          content: '';
          position: absolute;
          width: 2px;
          height: 19px;
          top: 0;
          right: -1vw;
          background: #dedede; } }
    .nav_box .chlan-box div {
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s; }
      @media (min-width: 1200px) {
        .nav_box .chlan-box div:hover a {
          color: #0073d8; } }
      @media (max-width: 1200px) {
        .nav_box .chlan-box div {
          width: 30%;
          text-align: center; } }
    .nav_box .chlan-box .active {
      color: #0073d8; }
    @media (max-width: 1200px) {
      .nav_box .chlan-box {
        width: 100%;
        border: none;
        padding: 23px 0 20px;
        border-bottom: 1px solid #dedede;
        border-radius: 0; } }
  .nav_box .nav-moreboxbg {
    display: block; }
    @media (max-width: 992px) {
      .nav_box .nav-moreboxbg {
        display: block; } }
  .nav_box .nav-morebox {
    position: fixed;
    background: #0073d8;
    left: 0;
    top: 110px;
    width: 100%;
    text-align: left;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    -webkit-box-shadow: 0 0px 5px rgba(13, 5, 9, 0.1);
            box-shadow: 0 0px 5px rgba(13, 5, 9, 0.1);
    padding: 15px 0;
    display: none; }
    @media (max-width: 1200px) {
      .nav_box .nav-morebox {
        min-height: 215px;
        height: auto; } }
    @media (max-width: 992px) {
      .nav_box .nav-morebox {
        -webkit-box-shadow: none;
                box-shadow: none;
        padding-top: 20px;
        padding-bottom: 30px; } }
    .nav_box .nav-morebox .mo {
      display: none; }
    .nav_box .nav-morebox .h-leftimg {
      margin-right: 45px;
      width: 184px; }
      .nav_box .nav-morebox .h-leftimg .h-txbx {
        font-size: 1.875rem;
        color: #0072bc;
        font-weight: 700; }
        .nav_box .nav-morebox .h-leftimg .h-txbx span {
          font-size: 1rem;
          color: #000000;
          font-weight: 500; }
    .nav_box .nav-morebox .mr {
      margin-right: 40px; }
    .nav_box .nav-morebox .h-listitem-box {
      margin: 0 auto;
      margin-top: 0px;
      background-color: #0073d8; }
      @media (max-width: 992px) {
        .nav_box .nav-morebox .h-listitem-box {
          min-height: calc(100vh- 54px); } }
      .nav_box .nav-morebox .h-listitem-box a:not(:first-child) {
        margin-top: 0px; }
      .nav_box .nav-morebox .h-listitem-box a:before {
        width: 2px;
        height: 70%;
        opacity: 0;
        bottom: auto;
        top: 3; }
      .nav_box .nav-morebox .h-listitem-box a {
        font-weight: 400;
        position: relative; }
      .nav_box .nav-morebox .h-listitem-box h4 {
        font-weight: 400;
        color: #0073d8; }
        @media (max-width: 992px) {
          .nav_box .nav-morebox .h-listitem-box h4 {
            font-size: 1rem; } }
      .nav_box .nav-morebox .h-listitem-box ul {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center; }
        @media (max-width: 992px) {
          .nav_box .nav-morebox .h-listitem-box ul {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: start;
            -webkit-justify-content: start;
                -ms-flex-pack: start;
                    justify-content: start; } }
        .nav_box .nav-morebox .h-listitem-box ul .img-bx {
          position: relative;
          width: 50px;
          height: 50px;
          margin: 0 auto; }
          @media (max-width: 991px) {
            .nav_box .nav-morebox .h-listitem-box ul .img-bx {
              width: 34px;
              height: 34px; } }
          .nav_box .nav-morebox .h-listitem-box ul .img-bx img {
            width: 50px;
            height: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            opacity: 0;
            -webkit-transition: .4s;
            -o-transition: .4s;
            transition: .4s; }
            @media (max-width: 991px) {
              .nav_box .nav-morebox .h-listitem-box ul .img-bx img {
                width: 34px;
                height: 34px;
                position: relative;
                top: auto;
                left: auto;
                -webkit-transform: none;
                    -ms-transform: none;
                        transform: none; } }
          .nav_box .nav-morebox .h-listitem-box ul .img-bx .nthov {
            opacity: 1; }
        @media (min-width: 992px) {
          .nav_box .nav-morebox .h-listitem-box ul li:hover:hover .nthov {
            opacity: 0; }
          .nav_box .nav-morebox .h-listitem-box ul li:hover:hover .hov {
            opacity: 1; }
          .nav_box .nav-morebox .h-listitem-box ul li:hover:hover .des {
            color: #2cd9d2; } }
        .nav_box .nav-morebox .h-listitem-box ul li a {
          font-size: 1rem;
          line-height: 1.25;
          font-weight: 500;
          color: #ffffff;
          padding: 0; }
          @media (max-width: 992px) {
            .nav_box .nav-morebox .h-listitem-box ul li a {
              font-size: 1rem;
              background-color: #00a0e9;
              border-bottom: 1px solid #ffffff;
              display: block;
              height: 100%;
              padding: 40px 0; } }
        .nav_box .nav-morebox .h-listitem-box ul li {
          width: calc((58.5vw - 23.7%) / 5);
          margin-right: 0;
          text-align: center; }
          @media (max-width: 1200px) {
            .nav_box .nav-morebox .h-listitem-box ul li {
              width: calc((58.5vw - 23.7%) / 3); } }
          @media (max-width: 992px) {
            .nav_box .nav-morebox .h-listitem-box ul li {
              width: 50%;
              border-bottom: none; }
              .nav_box .nav-morebox .h-listitem-box ul li:nth-child(odd) {
                border-right: 1px solid #ffffff; } }
          .nav_box .nav-morebox .h-listitem-box ul li .des {
            -webkit-transition: .4s;
            -o-transition: .4s;
            transition: .4s;
            text-align: center;
            line-height: 1.5;
            font-weight: 400;
            margin-top: 4px;
            padding: 0 20px; }
      @media (min-width: 768px) {
        .nav_box .nav-morebox .h-listitem-box .h-topbor {
          border-top: 1px solid #0073d8;
          padding-top: 20px;
          margin-top: 20px !important;
          padding-bottom: 0; } }
      @media (min-width: 992px) {
        .nav_box .nav-morebox .h-listitem-box .h-solbx {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex; }
          .nav_box .nav-morebox .h-listitem-box .h-solbx a {
            width: auto; }
            .nav_box .nav-morebox .h-listitem-box .h-solbx a:not(:last-child) {
              margin-right: 5%; } }
      @media (min-width: 768px) {
        .nav_box .nav-morebox .h-listitem-box .h-inves {
          padding-bottom: 6px; } }
      @media (min-width: 992px) {
        .nav_box .nav-morebox .h-listitem-box .h-solul {
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid #0073d8; } }
      .nav_box .nav-morebox .h-listitem-box .h-scanerul {
        padding-bottom: 0px; }
        @media (max-width: 992px) {
          .nav_box .nav-morebox .h-listitem-box .h-scanerul {
            height: 100%;
            padding-bottom: 0; } }
      .nav_box .nav-morebox .h-listitem-box .h-invesull li {
        width: calc((58.5vw - 23.7%) / 7); }
        @media (max-width: 1200px) {
          .nav_box .nav-morebox .h-listitem-box .h-invesull li {
            width: calc((58.5vw - 23.7%) / 6); } }
      .nav_box .nav-morebox .h-listitem-box .h-listitem-sbx {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex; }
      .nav_box .nav-morebox .h-listitem-box .h-listitem {
        position: relative;
        margin-top: 20px;
        display: none; }
        @media (max-width: 992px) {
          .nav_box .nav-morebox .h-listitem-box .h-listitem {
            display: block; } }
        .nav_box .nav-morebox .h-listitem-box .h-listitem:before {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          width: 1px;
          height: 88%;
          background: #bfbfbf; }
          @media (min-width: 768px) {
            .nav_box .nav-morebox .h-listitem-box .h-listitem:before {
              width: 1px;
              height: 88%; } }
        .nav_box .nav-morebox .h-listitem-box .h-listitem a:not(:first-child) {
          margin-top: 5px; }
        .nav_box .nav-morebox .h-listitem-box .h-listitem a:before {
          width: 0px; }
        .nav_box .nav-morebox .h-listitem-box .h-listitem a {
          color: #000000;
          font-weight: 400;
          font-size: 0.9375rem;
          -webkit-transition: .4s;
          -o-transition: .4s;
          transition: .4s; }
          @media (min-width: 768px) {
            .nav_box .nav-morebox .h-listitem-box .h-listitem a:hover {
              color: #0073d8; } }
          @media (max-width: 992px) {
            .nav_box .nav-morebox .h-listitem-box .h-listitem a {
              font-size: 1rem; } }
    .nav_box .nav-morebox .h-supbx {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
      .nav_box .nav-morebox .h-supbx a {
        width: auto; }
        .nav_box .nav-morebox .h-supbx a:not(:last-child) {
          margin-right: 5%; }
      @media (max-width: 992px) {
        .nav_box .nav-morebox .h-supbx {
          display: block; } }
    .nav_box .nav-morebox .mt {
      margin-top: 0;
      width: 69.5vw; }
    @media (max-width: 992px) {
      .nav_box .nav-morebox {
        width: 50vw;
        left: auto;
        right: -50vw;
        display: block;
        padding-left: 0;
        top: 0;
        background: #ffffff;
        height: 100vh;
        z-index: 11;
        padding: 0;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s; }
        .nav_box .nav-morebox .mo {
          display: block; }
        .nav_box .nav-morebox .prevar {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAAvCAMAAACFfikyAAAAP1BMVEVHcEwAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gtQE2RAAAAFHRSTlMA5gyTo8cFgPFs+bXXHUQ2KVFeFMO9Nt4AAADtSURBVEjHxZXdDoMgFIOPgAIiiMr7P+t0/kDCzXpYtt43X0JLDxFPQfN8m51SmjlANaZdEvV53aVTBvINi7t8aVQBMMYHmCa7AcC1z0DkhaI0t7G34nOfsA/QSAQ4qwxcBZj9qU5GPPtDbhkAoM5A7YEogruBIwT0RfYQkF02ySubyGUzUNnmb5Qt/qBsscweAZJOrLIdsoyyXVq5TKLF8J72nUsZKPK+VY0kvOrFcEHlveaS+WWqkdYwuvitLqBxeeYotU1h2wA3/cS2Y9N04urD2tQU29IUHF1ck382xRK7Kbj1booRxNI+Z4peRb4j/gGEpCUAAAAASUVORK5CYII=) no-repeat 94%;
          background-size: 29px;
          width: 100%;
          height: 25px;
          padding: 27px 0;
          position: relative;
          text-align: center; }
          .nav_box .nav-morebox .prevar::before {
            content: none; }
          .nav_box .nav-morebox .prevar div {
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            left: 50%;
            font-weight: 500;
            font-size: 1.25rem; }
        .nav_box .nav-morebox .h-leftimg {
          display: none; }
        .nav_box .nav-morebox .h-listitem-box {
          width: 100%;
          margin-top: 0; }
          .nav_box .nav-morebox .h-listitem-box .h-listitem-sbx {
            display: block;
            position: fixed;
            top: 0;
            right: -60vw;
            margin-top: 0;
            height: 100%;
            background: #ffffff;
            z-index: 17;
            -webkit-transition: .4s;
            -o-transition: .4s;
            transition: .4s;
            width: 50vw;
            padding: 0 18px; }
            .nav_box .nav-morebox .h-listitem-box .h-listitem-sbx .h-listitem {
              position: relative;
              height: auto;
              padding: 0;
              width: 100%;
              -webkit-transition: none;
              -o-transition: none;
              transition: none; }
          .nav_box .nav-morebox .h-listitem-box .h-listitem {
            display: block;
            position: fixed;
            top: 0;
            margin-top: 0;
            background: #ffffff;
            z-index: 15;
            height: 100vh;
            width: 50vw;
            right: -60vw;
            padding: 0 18px;
            -webkit-transition: .4s;
            -o-transition: .4s;
            transition: .4s; }
            .nav_box .nav-morebox .h-listitem-box .h-listitem::before {
              height: 0; }
            .nav_box .nav-morebox .h-listitem-box .h-listitem a:not(:first-child) {
              margin-top: 0; }
            .nav_box .nav-morebox .h-listitem-box .h-listitem a::before {
              width: 100%;
              height: 1px;
              left: 0;
              bottom: 0; }
            .nav_box .nav-morebox .h-listitem-box .h-listitem a:hover {
              color: #000000; }
          .nav_box .nav-morebox .h-listitem-box a {
            padding-left: 0; }
          .nav_box .nav-morebox .h-listitem-box a:before {
            height: 1px;
            width: 100%;
            background: #dedede;
            top: auto;
            bottom: 0; }
          .nav_box .nav-morebox .h-listitem-box a:not(:first-child) {
            margin-top: 0; } }
    @media (max-width: 992px) {
      .nav_box .nav-morebox {
        width: 100vw;
        right: -100vw; }
        .nav_box .nav-morebox .h-listitem-box .h-listitem-sbx {
          width: 100vw;
          right: -100vw; }
        .nav_box .nav-morebox .h-listitem-box .h-listitem {
          width: 100vw;
          right: -100vw; }
        .nav_box .nav-morebox .h-listitem {
          width: 100vw;
          right: -100vw; } }
  @media (max-width: 992px) {
    .nav_box .shnextpage {
      right: 0 !important; }
      .nav_box .shnextpage .nav-morebox {
        right: 0; }
    .nav_box .nav-morebox .h-listitem-box .shnextpage .h-listitem {
      right: 0; }
    .nav_box .shnextpageb .nav-morebox {
      right: -50vw; }
    .nav_box .nextar {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAdCAMAAABymtfJAAAARVBMVEVHcEwAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9i9kg6JAAAAFnRSTlMAG93N+r1CBOgLb6DzsVFefBM1iiiTYaNuewAAAIhJREFUKM9tkUkShCAUQ2VQZkHF3P+o0ttOWL6ifqYttL7xO4GSGPcKezOeDn4wDg+wG8ZHRDz5+7UDrxDNHm4yvi1qZpwK0C6RqCIejM0SfQJ7HEu0JyWKwkecoCulzf8XhjC3cnN7S4jt/powIu8beJDaRQN2iola4k58FnGkIR6460abmPwDs/kHkG7o3fEAAAAASUVORK5CYII=) no-repeat 97%;
      background-size: 12px; } }
  .nav_box .selectli .svg {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media (max-width: 992px) {
      .nav_box .selectli .svg path {
        fill: #0073d8 !important; } }
  .nav_box .selectli::before {
    position: absolute;
    content: '';
    border-top: 8px solid #000;
    border-left: 5px dashed transparent;
    border-right: 5px dashed transparent;
    left: 130px;
    width: 10px;
    height: 10px;
    bottom: auto;
    opacity: 1;
    background: none;
    top: 53%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media (max-width: 992px) {
      .nav_box .selectli::before {
        border-top: 8px solid #0073d8; } }
  .nav_box select {
    border: none;
    font-size: 1rem;
    font-weight: 300;
    height: 58px;
    padding-left: 43px;
    background: none; }
    @media (max-width: 992px) {
      .nav_box select {
        color: #0073d8; } }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: none; }

.menu-wrapper {
  display: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 20px;
  bottom: auto;
  margin: auto;
  width: 25px;
  height: 10px;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s; }

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 25px;
  height: 2px;
  border-radius: 99em; }

.hamburger-menu {
  position: relative;
  -webkit-transform: translateY(4px);
      -ms-transform: translateY(4px);
          transform: translateY(4px);
  background: none;
  -webkit-transition: all 0ms 300ms;
  -o-transition: all 0ms 300ms;
  transition: all 0ms 300ms;
  border-radius: 99px; }
  .hamburger-menu span::before {
    position: absolute;
    content: '';
    width: 30px;
    height: 2px;
    background: #0073d8;
    top: -5px;
    left: -5px; }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: none;
  -webkit-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 99px; }

.hamburger-menu:after {
  content: "";
  position: absolute;
  right: 0;
  top: 4px;
  background: #0073d8;
  -webkit-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 99px;
  width: 15px; }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

@media (max-width: 992px) {
  .header_box {
    padding: 0 40px; }
  .nav_box ul li:not(:last-child) {
    margin-right: 22px; } }

@media (max-width: 992px) {
  .logo {
    width: 100px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    left: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    position: absolute;
    margin: 0;
    height: 25px; }
  .header_box {
    width: 100%;
    height: 60px;
    font-size: 1.0625rem;
    margin: 0 auto;
    padding: 0 20px; }
  .nav_box nav {
    width: 100%;
    padding: 0 40px; } }

@media (max-width: 992px) and (max-width: 992px) {
  .navop {
    right: 0; }
    .navop .nav-morebox {
      overflow: scroll; } }

@media (max-width: 992px) {
  .nav_box {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: #ffffff;
    -webkit-transition: left .4s;
    -o-transition: left .4s;
    transition: left .4s; } }
  @media (max-width: 992px) and (max-width: 1200px) {
    .nav_box {
      width: 50vw;
      left: auto;
      right: -50vw; }
      .nav_box .shnextpage.nav-morebox {
        right: -50vw; } }
  @media (max-width: 992px) and (max-width: 992px) {
    .nav_box {
      width: 100vw;
      height: 100vh;
      right: 0;
      top: 60px;
      overflow-x: hidden;
      overflow-y: scroll;
      display: none;
      padding-bottom: 90px; }
      .nav_box .shnextpage.nav-morebox {
        right: -100vw; } }
  @media (max-width: 992px) and (max-width: 350px) {
    .nav_box {
      height: 90vh; } }

@media (max-width: 992px) {
    .nav_box ul {
      display: block; }
      .nav_box ul li {
        width: 100%; }
        .nav_box ul li a {
          padding: 23px 0 20px 0;
          text-align: left;
          text-decoration: none;
          font-size: 0.9375rem; } }
        @media (max-width: 992px) and (max-width: 992px) {
          .nav_box ul li a {
            font-size: 1rem;
            padding: 28px 0; } }

@media (max-width: 992px) {
      .nav_box ul li.selectli {
        border-bottom: none !important; }
    .nav_box ul > li:not(:last-child) {
      margin-right: 0; }
  .menu-wrapper {
    display: block; } }

@media (max-width: 992px) {
  .navop {
    right: 0; } }

.h-mo-bgc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -1;
  height: 100%;
  background-color: rgba(220, 220, 220, 0.8);
  -webkit-transition: .1s;
  -o-transition: .1s;
  transition: .1s;
  -webkit-transition-delay: .4s;
       -o-transition-delay: .4s;
          transition-delay: .4s;
  opacity: 0;
  pointer-events: none; }

@media (max-width: 1200px) {
  .mobgshow {
    opacity: 1;
    -webkit-transition-delay: 0s !important;
         -o-transition-delay: 0s !important;
            transition-delay: 0s !important; } }

@media (max-width: 992px) {
  .h-lan {
    display: none; } }

.h-lan .svg {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0px; }
  .h-lan .svg path {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }

.h-lan .lanfrlink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

@media (min-width: 992px) {
  .h-lan:hover .svg path {
    fill: #0073d8 !important; }
  .h-lan:hover .lanfrlink {
    color: #0073d8; }
    .h-lan:hover .lanfrlink::before {
      border-top: 8px solid #0073d8; } }

.h-lan .h-hovbox {
  position: fixed;
  margin-top: 33px;
  background: #ffffff;
  opacity: 0;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  -webkit-box-shadow: 2px 0px 5px rgba(13, 5, 9, 0.1);
          box-shadow: 2px 0px 5px rgba(13, 5, 9, 0.1);
  padding-bottom: 5px;
  letter-spacing: 0; }
  .h-lan .h-hovbox a {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center; }

.showlanbx .h-hovbox {
  opacity: 1 !important; }

.thr-linkbx {
  display: none; }
  @media (max-width: 991px) {
    .thr-linkbx {
      display: block;
      width: 100%;
      padding: 0 40px; }
      .thr-linkbx ul li a {
        font-size: 1rem;
        padding: 28px 0;
        color: #0073d8; } }

.tm-bx {
  display: none; }
  @media (max-width: 991px) {
    .tm-bx {
      display: block;
      width: 100%; }
      .tm-bx ul li {
        border-top: 1px solid #a4d4ff; }
        .tm-bx ul li a {
          font-size: 1rem;
          padding: 28px 0;
          color: #0073d8;
          position: relative;
          display: block;
          width: calc(100% - 80px);
          margin: 0 auto; }
        .tm-bx ul li:first-child a {
          padding-left: 40px; }
          .tm-bx ul li:first-child a::before {
            position: absolute;
            content: '';
            left: 0;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAS1BMVEVHcEwAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9g8CmUEAAAAGHRSTlMApekp8x3LBuD8TF8Ns9RBmRTCgDNYjWl8CZP5AAABMElEQVQoz21SCZKEIAwMlxwqhyjT/3/pRpTZdXZSZZWhk053gIjM6qOiO/ZCv2EcJKS5M43ptQxog7AZ25XYHAE5X0nAZMlGzKNUZY+1N4p+mBD7+bwy3R6xnZhDHxzRlTRAV1omCE68PI8Wh3TRNY3VFomDCO6iXbvao5CK3BLOAVqfI+DKpck3Kg6BdQceYsjKm85U2VsmLs6UWaEatvoCEkszhRkDT1FcNuIqD+cgJlMkYd5L5NLGTA5Eldkrl46whlLbSTDRwhr42+lLHKz8Bbd8w06/Kzb7BeIt1cIrZdd2ruGBKY3DROjWHID170VT8D7ZqgEvjgj3kPSCDmRTOjkFtHpg3tfxn+97fXPygxlE04dLtbGCg91ZZjCf3vk1eTdp+Pm/w5SjhxSXih/YqBHoM4ZK0gAAAABJRU5ErkJggg==) no-repeat center center/15px;
            width: 26px;
            height: 26px;
            opacity: 1;
            top: 50%;
            -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                    transform: translateY(-50%); }
        .tm-bx ul li:nth-child(2) a {
          padding-left: 40px; }
          .tm-bx ul li:nth-child(2) a::before {
            position: absolute;
            content: '';
            left: 0;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAXBAMAAACYK1DSAAAAJ1BMVEUAc9hHcEwAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9g5U9s/AAAADHRSTlPRAA++CsnkqEdAUE4RZLZzAAAAkUlEQVQY02OYdgYFZDLkMKCAYwwHhAQRQGQhD8MBnSCEgOppoIDwUUUYXyjGBCggYgNXonrYESgg6AxTAlQgCBKAKwEqAAvAlIAUQASgSkAKIAIQJWAFUAGwErACqICg84FGCR4TRZiAiLFIzZkzx53gZjgfFpQoYG8UhNmC4Q50l2L4BcO3GOGBHmIYYYoe6gAkeFbPGLiimQAAAABJRU5ErkJggg==) no-repeat center center/15px;
            width: 26px;
            height: 26px;
            opacity: 1;
            top: 50%;
            -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                    transform: translateY(-50%); } }

.h-search {
  top: -3px; }
  @media (max-width: 992px) {
    .h-search {
      display: none; } }
  .h-search .svg {
    width: 20px;
    height: 20px; }
    .h-search .svg path {
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s; }
  @media (min-width: 768px) {
    .h-search:hover .svg path {
      fill: #0073d8 !important; } }

.mo-serach {
  display: none; }
  @media (max-width: 992px) {
    .mo-serach {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      right: 65px; } }

.animate::before {
  -webkit-animation: humanibe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
          animation: humanibe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

.animate::after {
  -webkit-animation: humaniaf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
          animation: humaniaf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

.animate span::before {
  -webkit-animation: humanspbe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
          animation: humanspbe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

@-webkit-keyframes humanibe {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes humanibe {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes humaniaf {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 8px;
    width: 15px; }
  70% {
    -webkit-transform: rotate(-55deg);
            transform: rotate(-55deg);
    top: 0px;
    width: 30px; }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 0px;
    width: 30px; } }

@keyframes humaniaf {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 8px;
    width: 15px; }
  70% {
    -webkit-transform: rotate(-55deg);
            transform: rotate(-55deg);
    top: 0px;
    width: 30px; }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 0px;
    width: 30px; } }

@-webkit-keyframes humanspbe {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: -9px; }
  70% {
    -webkit-transform: rotate(55deg);
            transform: rotate(55deg);
    top: 0px; }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 0px; } }

@keyframes humanspbe {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: -9px; }
  70% {
    -webkit-transform: rotate(55deg);
            transform: rotate(55deg);
    top: 0px; }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 0px; } }

.colsanimate::before {
  -webkit-animation: colshumbf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
          animation: colshumbf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

.colsanimate::after {
  -webkit-animation: colshumaf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
          animation: colshumaf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

.colsanimate span::before {
  -webkit-animation: colspanbe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
          animation: colspanbe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

@-webkit-keyframes colshumbf {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes colshumbf {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes colshumaf {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 0px; }
  70% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
    top: 8px; }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 8px; } }

@keyframes colshumaf {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 0px; }
  70% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
    top: 8px; }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 8px; } }

@-webkit-keyframes colspanbe {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 0px; }
  70% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    top: -4px; }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: -4px; } }

@keyframes colspanbe {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 0px; }
  70% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    top: -4px; }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: -4px; } }

.moshow {
  display: none; }
  @media (max-width: 992px) {
    .moshow {
      display: block; } }

.novalhid {
  display: none !important; }

.ioshead {
  top: 0 !important; }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(6) .h-listitem-box > a {
    padding-top: 32px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(6) .h-listitem-box a:first-child {
    padding-top: 48px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(5) .h-listitem-box > a {
    padding-top: 32px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(5) .h-listitem-box a:first-child {
    padding-top: 48px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(4) .h-listitem-box > a {
    padding-top: 32px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(4) .h-listitem-box a:first-child {
    padding-top: 48px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(7) .h-listitem-box > a {
    padding-top: 32px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(7) .h-listitem-box a:first-child {
    padding-top: 48px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(3) .h-listitem-box > a {
    padding-top: 32px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(3) .h-listitem-box a:first-child {
    padding-top: 48px; } }

.iostop {
  height: 100vh;
  position: fixed; }

footer {
  z-index: -10;
  padding: 25px 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #ffffff; }
  @media (max-width: 991px) {
    footer {
      display: block;
      padding: 45px 25px 65px 25px;
      border-top: 1px solid #0073d8; } }
  footer .footer-oubx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media (max-width: 991px) {
      footer .footer-oubx {
        display: block;
        max-width: 420px;
        margin: 0 auto;
        width: 100%; } }
  footer .l-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    @media (max-width: 991px) {
      footer .l-bx {
        display: block; } }
    footer .l-bx .f-logo {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAAxCAMAAAAm7Z1EAAAAPFBMVEVHcEwAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOkAoOnJ0x7kAAAAE3RSTlMA0HB/QN71D1CfBjK+j2GuJRnprUfoaQAAAw9JREFUWMPFWMeirCAMVSlKE4T//9dnAxLKvM0d5uwEJKSdBKbphggXuD6mwZjDg2WwXMl/JNi9cgMdLHiLgtdvStHzA5GHyCvXf1PuEbUzecy+Q3qIWfc8xuqz/D2WOpDWxln+HqIOJMVfqBFcwX7FFTaPrRH3gvghz4ldzIxps0314nN6okazc544FL10wzh9eizEREvP5AKKrRmmVlCSxEMGlkTTkEiORuOFIGQKoTyYVzpejjEUWxamVlBoC1PF5gKnbZGYAF7muMLrVfwiMAa4x0tNEZsGTz8mMbXca9e5NThNO/xZhh42FJslNFIBgK+tPTd0TNf593XL7UfemeXID8jDuQZl3OGo4b97+Kiy684e0A/ZwQSSZRESHloLeolrCy0r8B6COrd7pMObHl5FPB3NJoRIG9nzY1lR1RBQ/SuFLtu6PDDDZHvpdYMarzVFfCJMkJlA/aDfFXKGbrRFkEs4SWHI/p8wUdVI6vu1KmcBcA2PbSFvmEMsEYl3ZKitgYxA60J1AMEyFJUbkY9tp2ttVmwED9XnshbMSq4pycd3cr9djJOxNFR/nuoY0AXXlORz1Dm2VmaVdYdpoPqgCTJAKcQ1JfnQ0CwFdR1qtEKpaOa270hcpUCypc4RBPXS4QkYCo0O89oXEFO0Zc6mGXgxHRwORHOaPUJV7trrDvO2fk4d/kQBqI078KItA08ANx0fGj01ta0PCVMbYgBjzhIcnLSyhPd7KgvLQSuzbb9EUHjwrZElUXsmICTSDsQszkzWlUtQUqgG+TQLqu1eIlBmrl25AncBskE+tPWfwh7Z6iz1a+9fcFJeBjUkH9frS3LmcBB3GnJocplBjQbfC64hTfJhPYVT6MDr95OIfsEucyrvwsVRcE2smMU9bOMdQ8VsR1czekHJgofkJHd97ePtcoBW/UY1QOlzEne1/xk6soW8W3jy4YJU+PC5Lox8kRC/epEY/QZDfvUGY0Of5r8KNuINpoE1jHiDaUCFEW8wDewNKh8Cg66FA/ES5uxGvx17pq3Z1Rhh/wDBsp9E9q06FAAAAABJRU5ErkJggg==) no-repeat center center/cover;
      width: 86px;
      height: 35px; }
      @media (max-width: 991px) {
        footer .l-bx .f-logo {
          width: 60px;
          height: 25px; } }
    footer .l-bx ul {
      margin-left: 30px; }
      @media (max-width: 991px) {
        footer .l-bx ul {
          margin-left: 0;
          margin-top: 15px; } }
      footer .l-bx ul li {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        font-size: 1rem;
        line-height: 2.5;
        letter-spacing: 0.32px;
        font-weight: 400;
        color: #6d6d6d; }
        @media (max-width: 1200px) {
          footer .l-bx ul li {
            display: block; } }
        @media (max-width: 991px) {
          footer .l-bx ul li {
            line-height: 1; } }
        footer .l-bx ul li a {
          display: block;
          font-size: 1rem;
          line-height: 2.5;
          letter-spacing: 0.32px;
          font-weight: 400;
          color: #6d6d6d; }
        footer .l-bx ul li .tel, footer .l-bx ul li .dress {
          margin-left: 25px; }
          @media (max-width: 1200px) {
            footer .l-bx ul li .tel, footer .l-bx ul li .dress {
              margin-left: 0; } }
        footer .l-bx ul li .cony {
          padding-left: 23px;
          position: relative;
          min-width: 195px; }
          @media (max-width: 991px) {
            footer .l-bx ul li .cony {
              line-height: 2.2; } }
          footer .l-bx ul li .cony::before {
            position: absolute;
            content: '';
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAgCAMAAAA7dZg3AAAAM1BMVEUAc9gAc9gAc9gAc9gAc9gAc9gAc9hHcEwAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9jtmstXAAAAEHRSTlPhfmIfwKBPABDw+DTSlgUWR7twegAAAKBJREFUKM/lktsOgyAQRAddQRbQ+f+v7YKtgk2TvvWhJ9w2JxA2GYSOnPsKfRHju5rLUlFtW5kvtU0cmLpbjm4+seJUgsiIk1rIoVYSig4FuT6VLdtm8xg20StryWlK6lpzg7KWFnrPpTX37woxlKpKiLiplMLuQ/B7PY1q5FuV9BUMvT84EdJADc1NHVmxBP1EFRGqSL5+mEWUIgXgB/AAfRIZWg3sZu8AAAAASUVORK5CYII=) no-repeat center/cover;
            width: 15px;
            height: 19px;
            left: 0;
            top: 11px; }
            @media (max-width: 991px) {
              footer .l-bx ul li .cony::before {
                width: 13px;
                height: 17px;
                top: 9px; } }
        footer .l-bx ul li .tel {
          padding-left: 23px;
          position: relative; }
          @media (max-width: 991px) {
            footer .l-bx ul li .tel {
              line-height: 2.2; } }
          footer .l-bx ul li .tel::before {
            position: absolute;
            content: '';
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAS1BMVEVHcEwAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9g8CmUEAAAAGHRSTlMApekp8x3LBuD8TF8Ns9RBmRTCgDNYjWl8CZP5AAABMElEQVQoz21SCZKEIAwMlxwqhyjT/3/pRpTZdXZSZZWhk053gIjM6qOiO/ZCv2EcJKS5M43ptQxog7AZ25XYHAE5X0nAZMlGzKNUZY+1N4p+mBD7+bwy3R6xnZhDHxzRlTRAV1omCE68PI8Wh3TRNY3VFomDCO6iXbvao5CK3BLOAVqfI+DKpck3Kg6BdQceYsjKm85U2VsmLs6UWaEatvoCEkszhRkDT1FcNuIqD+cgJlMkYd5L5NLGTA5Eldkrl46whlLbSTDRwhr42+lLHKz8Bbd8w06/Kzb7BeIt1cIrZdd2ruGBKY3DROjWHID170VT8D7ZqgEvjgj3kPSCDmRTOjkFtHpg3tfxn+97fXPygxlE04dLtbGCg91ZZjCf3vk1eTdp+Pm/w5SjhxSXih/YqBHoM4ZK0gAAAABJRU5ErkJggg==) no-repeat center/cover;
            width: 15px;
            height: 15px;
            left: 0;
            top: 12px; }
            @media (max-width: 991px) {
              footer .l-bx ul li .tel::before {
                width: 13px;
                height: 13px; } }
        footer .l-bx ul li .mail {
          padding-left: 23px;
          position: relative;
          min-width: 195px; }
          @media (max-width: 991px) {
            footer .l-bx ul li .mail {
              line-height: 2.2; } }
          footer .l-bx ul li .mail::before {
            position: absolute;
            content: '';
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAXBAMAAACYK1DSAAAAJ1BMVEUAc9hHcEwAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9g5U9s/AAAADHRSTlPRAA++CsnkqEdAUE4RZLZzAAAAkUlEQVQY02OYdgYFZDLkMKCAYwwHhAQRQGQhD8MBnSCEgOppoIDwUUUYXyjGBCggYgNXonrYESgg6AxTAlQgCBKAKwEqAAvAlIAUQASgSkAKIAIQJWAFUAGwErACqICg84FGCR4TRZiAiLFIzZkzx53gZjgfFpQoYG8UhNmC4Q50l2L4BcO3GOGBHmIYYYoe6gAkeFbPGLiimQAAAABJRU5ErkJggg==) no-repeat center/cover;
            width: 19px;
            height: 14px;
            left: 0;
            top: 14px; }
            @media (max-width: 991px) {
              footer .l-bx ul li .mail::before {
                width: 16px;
                height: 11px; } }
        footer .l-bx ul li .dress {
          padding-left: 23px;
          position: relative; }
          @media (max-width: 991px) {
            footer .l-bx ul li .dress {
              line-height: 1.6;
              margin-top: 5px; } }
          footer .l-bx ul li .dress::before {
            position: absolute;
            content: '';
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAgCAMAAAA7dZg3AAAARVBMVEVHcEwAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9i9kg6JAAAAFnRSTlMAtSAT4Agv5/rxSIDTApKrX27BPqM2t3uZrQAAASBJREFUKM9tkomShCAMRMNlEsRztP//UxdFGR02ZVnGhid2h6jUEMekgKYxDvSsYfGApHVNAvjlIdoE2WI4HkPcBMneykdh7GOhgX7KY6foX3jqod2J8Fjopxb4Az9ioqYmjERO1DXK4FRc3jtefTcxT93VjPkrBtdpokAVEq9TwxAjFIbHTDTDl/8NwpSvgsN6LFhxIT0IL4mqxCCWQhi4APlqM60uy8dgrsc4IH11aTfMZq9e9WTBoXUjMOzhSd9K/emeVel+lU7UFpvTDzKkOwzT5mXuWBX7U9mhNfT5hcy4+duZmsyZh3kgnN7RHHG8s53LMGTvgn/i6jDQv6PiGKexEdyMSoTP75xHbGaINmxUbk3lDd6fW9uyRuQ5+39veA5+wXngXgAAAABJRU5ErkJggg==) no-repeat center/cover;
            width: 16px;
            height: 20px;
            left: 0;
            top: 11px; }
            @media (max-width: 991px) {
              footer .l-bx ul li .dress::before {
                width: 14px;
                height: 17px;
                top: 5px; } }
  @media (max-width: 991px) {
    footer .r-bx {
      margin-top: 58px; } }
  footer .r-bx .pch-btn {
    margin-left: auto;
    margin-bottom: 16px; }
    @media (max-width: 991px) {
      footer .r-bx .pch-btn {
        margin: 0;
        margin-bottom: 10px; } }
    footer .r-bx .pch-btn div {
      position: relative;
      padding-left: 34px; }
      footer .r-bx .pch-btn div::before {
        position: absolute;
        content: '';
        width: 22px;
        height: 21px;
        left: 19px;
        top: 13px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAmCAMAAACBKikvAAAAPFBMVEVHcEwAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gAc9gC4NnkAAAAE3RSTlMAgO3LsPoJUKBhLr0Wbz3dIKmTLcUaDgAAATBJREFUOMuNVNsWgyAMA5SqgBfs///rCjKH0G7Lk2LkNElbNSPBeqd+wWKGCb+I2hCIvap/sCB6pUDPEla4iIHKBLWijFiuHBAnNX0h2neliLNS28KDvppCdJ9HTnC+5sKOuInEXFjBiagl3pGlFpCQQSKSG+P9AuT5IRA94vJ5G8Vw0h2hDYdDU1WoK35gbnTWHjxgEJ3kao0NcX8cSOGQw+fzRAinP6Zf49SB3DDQ2cCjLR0EnjmYqIaxRdSBm5zxrxGTw2EaVPe6D7blubnqt0jYWSbTArDGTvYojAg85IC0kjbSM9zRhmjRsH2/5d1mCxOukjXvj7X3TKRdRF1re38gnR73oolp3jynOhGdq4ka+Anxl3H+zj6XzCh3to4i3SaIUc7bavHDaXAvS+IFGFIeOrQE3OgAAAAASUVORK5CYII=) no-repeat center/cover;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s; }
        @media (max-width: 991px) {
          footer .r-bx .pch-btn div::before {
            width: 20px;
            height: 19px;
            top: 11px; } }
    @media (min-width: 768px) {
      footer .r-bx .pch-btn:hover div:before {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAVCAMAAAB1/u6nAAAAUVBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////////////////9jlXTzAAAAGnRSTlMA+m6e0AVBkSDgevOuyCvoX07XL4QYE8AhMi13JVUAAACiSURBVBjTbY9HFsMgEEMpBptiU+IW3f+gCbOgONFG8GHmSSwA4c1+tEsJz9QySJWXyymJQWvBFknPvQIveMM87D25JzdmwBGRfMarxx4HuYbtqHLr4/D45HE23FZGiFrlbgGOocxeBw1vZYSq2EKzP9oQpqruh82mqh9MYqMKSdwdtQB/U2i4peFsIgVzk4Bo+OIrXbObkLoomfuSVxsuv/4BhHoLSSiBUbIAAAAASUVORK5CYII=) no-repeat center/cover; } }
  footer .r-bx .copright {
    font-size: 1rem;
    line-height: 1.88;
    letter-spacing: 0.32px;
    font-weight: 400;
    color: #6d6d6d; }
    @media (max-width: 991px) {
      footer .r-bx .copright {
        letter-spacing: 0; } }
  footer .r-bx .a-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    @media (max-width: 991px) {
      footer .r-bx .a-bx {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
                justify-content: flex-start; } }
  footer .r-bx .rule {
    font-size: 1rem;
    line-height: 1.88;
    letter-spacing: 0.32px;
    font-weight: 500;
    color: #0073d8;
    padding-bottom: 3px;
    border-bottom: 1px solid #0073d8; }
  footer .r-bx .mak {
    font-size: 1rem;
    line-height: 1.88;
    letter-spacing: 0.32px;
    font-weight: 400;
    color: #6d6d6d;
    padding-left: 12px;
    margin-left: 8px;
    position: relative; }
    footer .r-bx .mak::before {
      position: absolute;
      content: '';
      left: 0;
      top: 8px;
      height: 60%;
      width: 1px;
      background-color: #6d6d6d; }

.r-fixfb {
  position: fixed;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 64px;
  height: 88px;
  border-radius: 15px 0 0 0;
  z-index: 999;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAA+BAMAAADOsranAAAALVBMVEVHcEz////////////////////////////////////////////////////////NXt0CAAAADnRSTlMAIDCgj2D3zqhzVOcSs1dARuoAAACSSURBVCjPY2CAgqPm+969210B40q8A4Fn76Bczrx3EADl+71D4XO+Q+WLofHtUPk8eah8Vgj7ZmhoKJjPDOYmwdzCwA7mL4Dz2UDc53AuwzkQ/wmCLwfiv0LjP6IN3wUIwN57AWK5MLxDAc/R+I/R+C/R+K/Q+A/R+Alo/AI0fgODMRD0gZhvQawFNPf/KH9Q8wE/z4z0wPuIzAAAAABJRU5ErkJggg==) no-repeat center center/21px;
  background-color: #0073d8; }
  @media (max-width: 991px) {
    .r-fixfb {
      width: 46px;
      height: 63px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAA+BAMAAADOsranAAAALVBMVEVHcEz////////////////////////////////////////////////////////NXt0CAAAADnRSTlMAIDCgj2D3zqhzVOcSs1dARuoAAACSSURBVCjPY2CAgqPm+969210B40q8A4Fn76Bczrx3EADl+71D4XO+Q+WLofHtUPk8eah8Vgj7ZmhoKJjPDOYmwdzCwA7mL4Dz2UDc53AuwzkQ/wmCLwfiv0LjP6IN3wUIwN57AWK5MLxDAc/R+I/R+C/R+K/Q+A/R+Alo/AI0fgODMRD0gZhvQawFNPf/KH9Q8wE/z4z0wPuIzAAAAABJRU5ErkJggg==) no-repeat center center/17px;
      background-color: #0073d8;
      border-radius: 7.5px 0 0 0; } }
  .r-fixfb::before {
    position: absolute;
    content: '';
    width: 68px;
    height: 96px;
    border-radius: 15px 0 0 0;
    border: 1px solid #0073d8;
    opacity: 0;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    right: 0;
    top: -4px; }
    @media (max-width: 991px) {
      .r-fixfb::before {
        border-radius: 7.5px 0 0 0; } }
  @media (min-width: 991px) {
    .r-fixfb:hover::before {
      opacity: 1; } }

/******************************************/
/*		pages
/******************************************/
.indexPage .index-banner {
  position: relative;
  margin-bottom: 120px;
  /* Animation */
  /*Shrinking for mobile*/ }
  @media (max-width: 768px) {
    .indexPage .index-banner {
      margin-bottom: 70px; } }
  .indexPage .index-banner .container {
    padding: 0 10px; }
    @media (min-width: 1200px) {
      .indexPage .index-banner .container {
        max-width: 1386px; } }
  .indexPage .index-banner .ind-cbx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    @media (max-width: 991px) {
      .indexPage .index-banner .ind-cbx {
        display: block; } }
    @media (max-width: 991px) {
      .indexPage .index-banner .ind-cbx {
        padding: 0 40px;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
        padding-top: 16%; } }
    .indexPage .index-banner .ind-cbx .l-bx, .indexPage .index-banner .ind-cbx .r-img {
      z-index: 50;
      position: relative; }
    .indexPage .index-banner .ind-cbx .ti {
      font-size: 3.75rem;
      line-height: 1.33;
      letter-spacing: 1.2px;
      font-weight: 500;
      color: #ffffff;
      max-height: 158px;
      overflow: hidden;
      text-shadow: #424141 1px 0 4px; }
      @media (max-width: 768px) {
        .indexPage .index-banner .ind-cbx .ti {
          font-size: 2.5rem;
          max-height: 110px; } }
    .indexPage .index-banner .ind-cbx .des {
      margin-top: 40px;
      font-size: 1.375rem;
      line-height: 1.64;
      letter-spacing: 0.4px;
      font-weight: 500;
      color: #ffffff;
      max-height: 109px;
      overflow: hidden;
      text-shadow: #424141 1px 0 4px;
      text-align: justify; }
      @media (max-width: 768px) {
        .indexPage .index-banner .ind-cbx .des {
          font-size: 1.25rem;
          max-height: 124px;
          margin-top: 10px;
          font-weight: 400; } }
    .indexPage .index-banner .ind-cbx .l-bx {
      margin-left: 6.8%;
      margin-right: 3.2%;
      max-width: 445px;
      min-width: 445px;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1; }
      @media (max-width: 991px) {
        .indexPage .index-banner .ind-cbx .l-bx {
          margin: 0 auto;
          margin-top: 30px;
          max-width: 100%;
          min-width: 100%; } }
    .indexPage .index-banner .ind-cbx .r-img {
      width: calc( 90% - 445px);
      -webkit-box-ordinal-group: 3;
      -webkit-order: 2;
          -ms-flex-order: 2;
              order: 2; }
      @media (max-width: 991px) {
        .indexPage .index-banner .ind-cbx .r-img {
          width: 58%;
          margin: 0 auto; } }
      @media (max-width: 768px) {
        .indexPage .index-banner .ind-cbx .r-img {
          width: 87%;
          margin: 0 auto; } }
  .indexPage .index-banner .bn-it {
    display: none; }
  .indexPage .index-banner .pc-banner {
    position: relative;
    height: 806px; }
    @media (max-width: 768px) {
      .indexPage .index-banner .pc-banner {
        height: 0;
        padding-bottom: 160%; } }
  .indexPage .index-banner .pc-banner .bgcover {
    width: 100vw;
    position: absolute;
    padding-bottom: 806px;
    top: 0;
    left: 0;
    background-size: cover !important; }
    @media (max-width: 768px) {
      .indexPage .index-banner .pc-banner .bgcover {
        padding-bottom: 160%; } }
  .indexPage .index-banner .bgcover.mo {
    display: block; }
    @media (min-width: 768px) {
      .indexPage .index-banner .bgcover.mo {
        display: none; } }
  .indexPage .index-banner .bgcover.pc {
    display: block; }
    @media (max-width: 767px) {
      .indexPage .index-banner .bgcover.pc {
        display: none; } }
  .indexPage .index-banner .wave-bx {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
  .indexPage .index-banner .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px; }
  .indexPage .index-banner .content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: white; }
  .indexPage .index-banner .parallax > use {
    -webkit-animation: move-forever 15s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
            animation: move-forever 15s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite; }
  .indexPage .index-banner .parallax > use:nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    -webkit-animation-duration: 4s;
            animation-duration: 4s; }
  .indexPage .index-banner .parallax > use:nth-child(2) {
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    -webkit-animation-duration: 5s;
            animation-duration: 5s; }
  .indexPage .index-banner .parallax > use:nth-child(3) {
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    -webkit-animation-duration: 7s;
            animation-duration: 7s; }
  .indexPage .index-banner .parallax > use:nth-child(4) {
    -webkit-animation-delay: -5s;
            animation-delay: -5s;
    -webkit-animation-duration: 10s;
            animation-duration: 10s; }

@-webkit-keyframes move-forever {
  0% {
    -webkit-transform: translate3d(-90px, 0, 0);
            transform: translate3d(-90px, 0, 0); }
  100% {
    -webkit-transform: translate3d(85px, 0, 0);
            transform: translate3d(85px, 0, 0); } }

@keyframes move-forever {
  0% {
    -webkit-transform: translate3d(-90px, 0, 0);
            transform: translate3d(-90px, 0, 0); }
  100% {
    -webkit-transform: translate3d(85px, 0, 0);
            transform: translate3d(85px, 0, 0); } }
  @media (max-width: 768px) {
    .indexPage .index-banner .waves {
      height: 40px;
      min-height: 40px; }
    .indexPage .index-banner .content {
      height: 30vh; }
    .indexPage .index-banner h1 {
      font-size: 24px; } }
  .indexPage .index-banner .show-me::before {
    background-color: #ffffff; }
  .indexPage .index-banner .show-box {
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 10;
    display: block; }
    @media (max-width: 767px) {
      .indexPage .index-banner .show-box {
        left: 0px; } }
    .indexPage .index-banner .show-box .go-it {
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: relative;
      margin-bottom: 15px; }
      @media (max-width: 991px) {
        .indexPage .index-banner .show-box .go-it {
          margin-bottom: 30px; } }
      .indexPage .index-banner .show-box .go-it::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        border-radius: 3px;
        border: solid 1px #ffffff;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        width: 6px;
        height: 38px; }
        @media (max-width: 767px) {
          .indexPage .index-banner .show-box .go-it::before {
            width: 5px;
            height: 48px; } }
      @media (min-width: 768px) {
        .indexPage .index-banner .show-box .go-it:hover::before {
          background-color: #ffffff; } }

.indexPage .item2 .it2-bx {
  position: relative; }
  @media (max-width: 991px) and (min-width: 769px) {
    .indexPage .item2 .it2-bx {
      max-width: 100%; } }
  .indexPage .item2 .it2-bx .tit-blsq {
    text-align: center;
    margin-bottom: 70px; }
    @media (max-width: 767px) {
      .indexPage .item2 .it2-bx .tit-blsq {
        margin-bottom: 30px; } }
  .indexPage .item2 .it2-bx ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    @media (max-width: 767px) {
      .indexPage .item2 .it2-bx ul {
        display: block; } }
    .indexPage .item2 .it2-bx ul li {
      width: 50%;
      border-bottom: 1px solid #d2d2d2;
      padding: 20px 40px;
      padding-bottom: 50px;
      text-align: center;
      position: relative; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-bx ul li {
          width: 100%;
          padding: 20px 0px 20px; } }
      .indexPage .item2 .it2-bx ul li a {
        display: block; }
      .indexPage .item2 .it2-bx ul li:nth-child(odd) {
        border-right: 1px solid #d2d2d2; }
        @media (max-width: 767px) {
          .indexPage .item2 .it2-bx ul li:nth-child(odd) {
            border-right: none; } }
      .indexPage .item2 .it2-bx ul li:last-child, .indexPage .item2 .it2-bx ul li:nth-last-child(2) {
        border-bottom: none; }
        .indexPage .item2 .it2-bx ul li:last-child::before, .indexPage .item2 .it2-bx ul li:nth-last-child(2)::before {
          -webkit-transform: none;
              -ms-transform: none;
                  transform: none;
          -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
                  transform: translateX(-50%);
          top: auto;
          bottom: 0;
          height: 97%; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-bx ul li:nth-last-child(2) {
          border-bottom: 1px solid #d2d2d2; } }
      .indexPage .item2 .it2-bx ul li::before {
        position: absolute;
        content: '';
        width: 95%;
        height: 95%;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #0073d8;
        pointer-events: none;
        opacity: 0;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        border-radius: 15px 0 15px 0; }
      .indexPage .item2 .it2-bx ul li::after {
        position: absolute;
        content: '';
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABJCAMAAACpb5DQAAAASFBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////8FevL4AAAAGHRSTlMApXwELeYY094hbJfvColeOUYPslO9yPmMBUlHAAACZklEQVRYw62Y24KrIAxFA8hF7gjo///paaenM7UqosBbkS5Cwg4BgGIbg8RWKWVlGgjcaybYeaFZWSzlgxbFwpVjVykkID3bMK36Bhl1ltMVY6TgcmdyAiQhrcZaDKZoOP7MHp+rUEmcjTNYY3OGYZH788kYEqE8IlBcF+WTgZj62oCwnA/jR9B8YZ8Qyw98aXI0lzYbprvBJRFdFYGjezaheF1Mjm59YWdzQ5CYf/8rCHZL2gh9BbMU95K4JuFWvzMuzMoLyoNBfy7FcXIXBPhjcVN5Q5dB5kN2FsF9ECT+62k9toCAv/2NFTSB3iYROrSBgL+8lDI0gv4HLrpWENNPoUx6agVBTjUrqwDJ59qUbAcN9DnMt4PgkeHMQjqAYgLPoQPIWkixB8ghwLYHyM9gcQ/QIADJHiCmAbkeoGkDUmLbFrrTKdbV3AJqDWLjtvGw07nOhRMF28dHAnCfqHGQqgcoRAi5B+hhDqM9QM9cRFkH0Ox/MkAzaFoMVHj7HJTml96aQepnD53m2lMQedWSZyf2OSjMr8udNo2gdyqKsg00vo9YL0gTSP36JrsW0PhXRXo6NYDQx/GB1H1QEOYzdw93QYavJOa4uQlSsXwVWEmyENT07V7Dz3P3bt2/0ddI03XOSN1esRSucpjYPTmCvkga+UEB4qm75B+Kj2sKW38dTaVpWZ4rX1CIKt+oCNayxijP49nhM8zzaZnLUI03iaOxiGJK27rXKCNpdkfa8xdeoh5WpazR9kHNBCsEvnivZzIuXMkwsKdtZvTO5oXb4Y4iiZcqc708m5gRDhO0NVLz2PEP5t0Y2L20YDMAAAAASUVORK5CYII=) no-repeat center center/cover;
        width: 72px;
        height: 73px;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        pointer-events: none;
        opacity: 0;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease; }
      @media (min-width: 768px) {
        .indexPage .item2 .it2-bx ul li:hover::before {
          opacity: .5; }
        .indexPage .item2 .it2-bx ul li:hover::after {
          opacity: 1; } }
    .indexPage .item2 .it2-bx ul .des-ti {
      font-size: 1.625rem;
      line-height: 1.46;
      letter-spacing: 0.5px;
      font-weight: 400;
      color: #0073d8;
      max-height: 37px;
      overflow: hidden; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-bx ul .des-ti {
          margin-top: 20px; } }
    .indexPage .item2 .it2-bx ul .des {
      margin-top: 20px;
      font-size: 1rem;
      line-height: 1.88;
      letter-spacing: 0.3px;
      font-weight: 300;
      color: #6d6d6d;
      max-height: 150px;
      overflow: hidden; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-bx ul .des {
          max-height: 185px;
          margin-top: 12px; } }

@-webkit-keyframes fadel {
  0% {
    opacity: .2; }
  50% {
    opacity: 0; }
  100% {
    opacity: .2; } }

@keyframes fadel {
  0% {
    opacity: .2; }
  50% {
    opacity: 0; }
  100% {
    opacity: .2; } }
  .indexPage .item2 .it2-bx .l-sqbx {
    position: absolute;
    left: -540px;
    top: 220px; }
    @media (max-width: 767px) {
      .indexPage .item2 .it2-bx .l-sqbx {
        display: none; } }
    .indexPage .item2 .it2-bx .l-sqbx .t-bx, .indexPage .item2 .it2-bx .l-sqbx .b-bx {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
    .indexPage .item2 .it2-bx .l-sqbx .b-bx {
      margin-left: 15px; }
    .indexPage .item2 .it2-bx .l-sqbx .sq {
      width: 90px;
      height: 150px;
      -webkit-transform-origin: bottom;
          -ms-transform-origin: bottom;
              transform-origin: bottom;
      -webkit-transform: skewX(-32deg);
          -ms-transform: skewX(-32deg);
              transform: skewX(-32deg);
      background-color: #bce0ff;
      opacity: .2;
      border-radius: 15px 0 15px 0;
      margin: 0 15px;
      margin-bottom: 30px; }
    .indexPage .item2 .it2-bx .l-sqbx .ani1 {
      -webkit-animation: fadel 5s 1s linear infinite;
              animation: fadel 5s 1s linear infinite; }
    .indexPage .item2 .it2-bx .l-sqbx .ani2 {
      -webkit-animation: fadel 5s 1.5s linear infinite;
              animation: fadel 5s 1.5s linear infinite; }
    .indexPage .item2 .it2-bx .l-sqbx .ani3 {
      -webkit-animation: fadel 5s 2s linear infinite;
              animation: fadel 5s 2s linear infinite; }
    .indexPage .item2 .it2-bx .l-sqbx .ani4 {
      -webkit-animation: fadel 5s 2.5s linear infinite;
              animation: fadel 5s 2.5s linear infinite; }
    .indexPage .item2 .it2-bx .l-sqbx .ani5 {
      -webkit-animation: fadel 5s 3s linear infinite;
              animation: fadel 5s 3s linear infinite; }
    .indexPage .item2 .it2-bx .l-sqbx .ani6 {
      -webkit-animation: fadel 5s 3.5s linear infinite;
              animation: fadel 5s 3.5s linear infinite; }
  .indexPage .item2 .it2-bx .r-sqbx {
    position: absolute;
    right: -540px;
    bottom: 120px; }
    @media (max-width: 767px) {
      .indexPage .item2 .it2-bx .r-sqbx {
        display: none; } }
    .indexPage .item2 .it2-bx .r-sqbx .t-bx, .indexPage .item2 .it2-bx .r-sqbx .b-bx {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
    .indexPage .item2 .it2-bx .r-sqbx .b-bx {
      margin-left: 15px; }
    .indexPage .item2 .it2-bx .r-sqbx .sq {
      width: 90px;
      height: 150px;
      -webkit-transform-origin: bottom;
          -ms-transform-origin: bottom;
              transform-origin: bottom;
      -webkit-transform: skewX(-32deg);
          -ms-transform: skewX(-32deg);
              transform: skewX(-32deg);
      background-color: #0073d8;
      opacity: .2;
      border-radius: 15px 0 15px 0;
      margin: 0 15px;
      margin-bottom: 30px; }
    .indexPage .item2 .it2-bx .r-sqbx .ani1 {
      -webkit-animation: fadel 5s 1s linear infinite;
              animation: fadel 5s 1s linear infinite; }
    .indexPage .item2 .it2-bx .r-sqbx .ani2 {
      -webkit-animation: fadel 5s 1.5s linear infinite;
              animation: fadel 5s 1.5s linear infinite; }
    .indexPage .item2 .it2-bx .r-sqbx .ani3 {
      -webkit-animation: fadel 5s 2s linear infinite;
              animation: fadel 5s 2s linear infinite; }
    .indexPage .item2 .it2-bx .r-sqbx .ani4 {
      -webkit-animation: fadel 5s 2.5s linear infinite;
              animation: fadel 5s 2.5s linear infinite; }
    .indexPage .item2 .it2-bx .r-sqbx .ani5 {
      -webkit-animation: fadel 5s 3s linear infinite;
              animation: fadel 5s 3s linear infinite; }
    .indexPage .item2 .it2-bx .r-sqbx .ani6 {
      -webkit-animation: fadel 5s 3.5s linear infinite;
              animation: fadel 5s 3.5s linear infinite; }

.indexPage .item3 {
  position: relative;
  height: 801px;
  background: url(../images/2bg.jpg?c7bcab09) no-repeat center center/cover;
  margin-top: 100px; }
  @media (max-width: 767px) {
    .indexPage .item3 {
      background: url(../images/2bg_mb.jpg?0990b7ad) no-repeat center center/cover;
      height: auto;
      margin-top: 30px; } }
  .indexPage .item3 .it3-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media (max-width: 991px) {
      .indexPage .item3 .it3-bx {
        display: block; } }
    @media (max-width: 767px) {
      .indexPage .item3 .it3-bx {
        top: 0;
        -webkit-transform: none;
            -ms-transform: none;
                transform: none;
        padding: 90px 25px; } }
    .indexPage .item3 .it3-bx .l-img {
      margin-right: 6%;
      width: calc(94% - 450px); }
      @media (max-width: 991px) {
        .indexPage .item3 .it3-bx .l-img {
          width: 59%;
          margin: 0 auto; } }
      @media (max-width: 767px) {
        .indexPage .item3 .it3-bx .l-img {
          width: 90%; } }
    .indexPage .item3 .it3-bx .r-bx {
      max-width: 450px; }
      @media (max-width: 991px) {
        .indexPage .item3 .it3-bx .r-bx {
          margin: 0 auto; } }
      .indexPage .item3 .it3-bx .r-bx .tit-blsq {
        position: relative; }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .r-bx .tit-blsq {
            margin-top: 45px;
            padding-left: 25px; } }
        .indexPage .item3 .it3-bx .r-bx .tit-blsq::before {
          position: absolute;
          -webkit-transform-origin: bottom;
              -ms-transform-origin: bottom;
                  transform-origin: bottom;
          -webkit-transform: skewX(-32deg);
              -ms-transform: skewX(-32deg);
                  transform: skewX(-32deg);
          content: "";
          top: -5px;
          background-color: #348ddb;
          opacity: 1;
          z-index: -1;
          width: 31px;
          height: 48px;
          left: -25px;
          border-radius: 5px 0 5px 0; }
          @media (max-width: 767px) {
            .indexPage .item3 .it3-bx .r-bx .tit-blsq::before {
              z-index: 1;
              left: 0;
              background-color: #3598d6; } }
        .indexPage .item3 .it3-bx .r-bx .tit-blsq div {
          color: #ffffff;
          line-height: 1.3;
          z-index: 2;
          max-height: 104px;
          overflow: hidden;
          margin-bottom: 32px;
          text-shadow: #424141 1px 0 4px; }
          @media (max-width: 767px) {
            .indexPage .item3 .it3-bx .r-bx .tit-blsq div {
              max-height: 157px; } }
          .indexPage .item3 .it3-bx .r-bx .tit-blsq div::before {
            width: 0; }
      .indexPage .item3 .it3-bx .r-bx .des {
        font-size: 1rem;
        line-height: 1.88;
        letter-spacing: 0.32px;
        font-weight: 300;
        color: #ffffff;
        max-height: 210px;
        overflow: hidden;
        text-shadow: #424141 1px 0 4px;
        text-align: justify; }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .r-bx .des {
            max-height: inherit;
            overflow: auto; } }
      .indexPage .item3 .it3-bx .r-bx .sh-mbtn {
        margin-top: 60px; }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .r-bx .sh-mbtn {
            margin-top: 50px; } }
  .indexPage .item3 .t-wav {
    width: 100%;
    position: absolute;
    top: -1px; }
    .indexPage .item3 .t-wav img {
      width: 100%; }
  .indexPage .item3 .b-wav {
    width: 100%;
    position: absolute;
    bottom: -1px; }
    .indexPage .item3 .b-wav img {
      width: 100%; }

.indexPage .item4 {
  margin-top: 90px; }
  @media (max-width: 767px) {
    .indexPage .item4 {
      margin-top: 70px; } }
  .indexPage .item4 .it4-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative; }
    @media (max-width: 991px) {
      .indexPage .item4 .it4-bx {
        display: block; } }
    .indexPage .item4 .it4-bx .l-img {
      -webkit-box-ordinal-group: 3;
      -webkit-order: 2;
          -ms-flex-order: 2;
              order: 2;
      width: calc(94% - 450px); }
      @media (max-width: 991px) {
        .indexPage .item4 .it4-bx .l-img {
          width: 59%;
          margin: 0 auto; } }
      @media (max-width: 767px) {
        .indexPage .item4 .it4-bx .l-img {
          width: 90%; } }
    .indexPage .item4 .it4-bx .r-bx {
      max-width: 450px;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1;
      margin-right: 6%; }
      @media (max-width: 991px) {
        .indexPage .item4 .it4-bx .r-bx {
          margin: 0 auto; } }
      .indexPage .item4 .it4-bx .r-bx .tit-blsq {
        position: relative; }
        @media (max-width: 767px) {
          .indexPage .item4 .it4-bx .r-bx .tit-blsq {
            margin-top: 45px;
            padding-left: 25px; } }
        .indexPage .item4 .it4-bx .r-bx .tit-blsq::before {
          position: absolute;
          -webkit-transform-origin: bottom;
              -ms-transform-origin: bottom;
                  transform-origin: bottom;
          -webkit-transform: skewX(-32deg);
              -ms-transform: skewX(-32deg);
                  transform: skewX(-32deg);
          content: "";
          top: -5px;
          opacity: .2;
          z-index: -1;
          width: 31px;
          height: 48px;
          left: -25px;
          border-radius: 5px 0 5px 0;
          background-color: #0073d8; }
          @media (max-width: 767px) {
            .indexPage .item4 .it4-bx .r-bx .tit-blsq::before {
              left: 0; } }
        .indexPage .item4 .it4-bx .r-bx .tit-blsq div {
          color: #0073d8;
          line-height: 1.3;
          z-index: 2;
          max-height: 104px;
          overflow: hidden;
          margin-bottom: 32px; }
          .indexPage .item4 .it4-bx .r-bx .tit-blsq div::before {
            width: 0; }
          @media (max-width: 767px) {
            .indexPage .item4 .it4-bx .r-bx .tit-blsq div {
              max-height: 157px; } }
      .indexPage .item4 .it4-bx .r-bx .des {
        font-size: 1rem;
        line-height: 1.88;
        letter-spacing: 0.32px;
        font-weight: 300;
        color: #6d6d6d;
        max-height: 210px;
        overflow: hidden;
        text-align: justify; }
        @media (max-width: 767px) {
          .indexPage .item4 .it4-bx .r-bx .des {
            max-height: inherit;
            overflow: auto; } }
      .indexPage .item4 .it4-bx .r-bx .sh-mbtn {
        margin-top: 60px;
        color: #0073d8; }
        .indexPage .item4 .it4-bx .r-bx .sh-mbtn::before, .indexPage .item4 .it4-bx .r-bx .sh-mbtn::after {
          background-color: #0073d8; }
        @media (max-width: 767px) {
          .indexPage .item4 .it4-bx .r-bx .sh-mbtn {
            margin-top: 50px; } }

.indexPage .hoving5.item5::before {
  left: 200%;
  -webkit-transition: 3s ease;
  -o-transition: 3s ease;
  transition: 3s ease; }

.indexPage .item5 {
  background-color: #0073d8;
  text-align: center;
  position: relative;
  margin-top: 90px; }
  @media (max-width: 767px) {
    .indexPage .item5 {
      margin-top: 55px; } }
  .indexPage .item5::before {
    position: absolute;
    content: '';
    background: -webkit-linear-gradient(305deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(305deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(145deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%; }
  .indexPage .item5 a {
    display: block;
    padding-top: 60px;
    padding-bottom: 75px;
    position: relative; }
    @media (max-width: 767px) {
      .indexPage .item5 a {
        padding-top: 35px;
        padding-bottom: 60px; } }
  .indexPage .item5 .tit-blsq {
    position: relative; }
    @media (max-width: 767px) {
      .indexPage .item5 .tit-blsq {
        margin-top: 45px;
        padding-left: 25px; } }
    .indexPage .item5 .tit-blsq div {
      color: #ffffff;
      line-height: 1.3;
      z-index: 2;
      margin-bottom: 27px; }
      @media (max-width: 767px) {
        .indexPage .item5 .tit-blsq div {
          max-height: 157px; } }
      .indexPage .item5 .tit-blsq div::before {
        content: "";
        width: 31px;
        height: 48px;
        left: -25px;
        border-radius: 5px 0 5px 0;
        top: -5px;
        opacity: 1;
        z-index: -1;
        background-color: #348ddb; }
  .indexPage .item5 .des {
    font-size: 1rem;
    line-height: 1.88;
    letter-spacing: 0.32px;
    font-weight: 300;
    color: #ffffff; }
    @media (max-width: 767px) {
      .indexPage .item5 .des {
        max-width: 228px;
        margin: 0 auto; } }

@media (max-width: 991px) {
  .scalistPage main {
    padding-bottom: 95px; } }

.scalistPage .item1 {
  margin-top: 85px;
  margin-bottom: 75px; }
  @media (max-width: 991px) {
    .scalistPage .item1 {
      margin-top: 55px;
      margin-bottom: 25px; } }
  .scalistPage .item1 .Breadcrumb ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .scalistPage .item1 .Breadcrumb ul li {
      font-size: 1rem;
      line-height: 2.25;
      letter-spacing: 0px;
      font-weight: 500;
      color: #6d6d6d;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; }
      .scalistPage .item1 .Breadcrumb ul li:last-child {
        color: #0073d8; }
      .scalistPage .item1 .Breadcrumb ul li:not(:last-child) {
        margin-right: 15px; }
      @media (max-width: 767px) {
        .scalistPage .item1 .Breadcrumb ul li {
          line-height: 1.25; } }
    .scalistPage .item1 .Breadcrumb ul span {
      margin-right: 15px;
      position: relative;
      top: -1px; }

@media (min-width: 1200px) {
  .scalistPage .item2 .it2-bx {
    max-width: 1120px; } }

.scadetailPage .item1 {
  margin-top: 70px;
  margin-bottom: 65px; }
  @media (max-width: 991px) {
    .scadetailPage .item1 {
      margin-top: 30px;
      margin-bottom: 25px; } }
  .scadetailPage .item1 .Breadcrumb ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .scadetailPage .item1 .Breadcrumb ul li {
      font-size: 1rem;
      line-height: 2.25;
      letter-spacing: 0px;
      font-weight: 500;
      color: #6d6d6d;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; }
      .scadetailPage .item1 .Breadcrumb ul li:last-child {
        color: #0073d8; }
      .scadetailPage .item1 .Breadcrumb ul li:not(:last-child) {
        margin-right: 15px; }
      @media (max-width: 767px) {
        .scadetailPage .item1 .Breadcrumb ul li {
          line-height: 1.25; } }
      .scadetailPage .item1 .Breadcrumb ul li a {
        font-size: 1rem;
        line-height: 2.25;
        letter-spacing: 0px;
        font-weight: 500;
        color: #6d6d6d;
        position: relative; }
        @media (max-width: 767px) {
          .scadetailPage .item1 .Breadcrumb ul li a {
            line-height: 1.25; } }
        .scadetailPage .item1 .Breadcrumb ul li a::before {
          position: absolute;
          content: '';
          bottom: -5px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #6d6d6d;
          opacity: 0;
          -webkit-transition: .4s ease;
          -o-transition: .4s ease;
          transition: .4s ease; }
        @media (min-width: 768px) {
          .scadetailPage .item1 .Breadcrumb ul li a:hover::before {
            opacity: 1; } }
    .scadetailPage .item1 .Breadcrumb ul span {
      margin-right: 15px;
      position: relative;
      top: -1px; }

.scadetailPage .item2 .it2-bx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 991px) {
    .scadetailPage .item2 .it2-bx {
      display: block;
      margin-bottom: 65px; } }
  .scadetailPage .item2 .it2-bx .l-bx {
    max-width: 495px;
    width: 100%;
    margin-right: 30px; }
    @media (max-width: 991px) {
      .scadetailPage .item2 .it2-bx .l-bx {
        margin: 0 auto; } }
  .scadetailPage .item2 .it2-bx .r-bx {
    width: calc(100% - 525px); }
    @media (max-width: 991px) {
      .scadetailPage .item2 .it2-bx .r-bx {
        width: 100%; } }
    .scadetailPage .item2 .it2-bx .r-bx .ti {
      position: relative;
      font-size: 1.625rem;
      line-height: 1.3;
      letter-spacing: 0px;
      font-weight: 500;
      color: #0073d8;
      margin-bottom: 50px; }
      @media (max-width: 991px) {
        .scadetailPage .item2 .it2-bx .r-bx .ti {
          margin-top: 40px;
          margin-bottom: 35px; } }
      .scadetailPage .item2 .it2-bx .r-bx .ti::before {
        position: absolute;
        content: '';
        top: -5px;
        left: -16px;
        -webkit-transform-origin: bottom;
            -ms-transform-origin: bottom;
                transform-origin: bottom;
        -webkit-transform: skewX(-32deg);
            -ms-transform: skewX(-32deg);
                transform: skewX(-32deg);
        background-color: #0073d8;
        opacity: .2;
        border-radius: 5px 0 5px 0;
        width: 19px;
        height: 32px; }
        @media (max-width: 991px) {
          .scadetailPage .item2 .it2-bx .r-bx .ti::before {
            border-radius: 2.5px 0 2.5px 0;
            width: 19px;
            height: 29px;
            top: 0px;
            left: -17px; } }
    .scadetailPage .item2 .it2-bx .r-bx .des-ti {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0px;
      font-weight: 300;
      color: #0073d8;
      padding-bottom: 15px;
      border-bottom: 1px solid #0073d8;
      margin-bottom: 10px; }
      @media (max-width: 991px) {
        .scadetailPage .item2 .it2-bx .r-bx .des-ti {
          margin-bottom: 15px; } }
    .scadetailPage .item2 .it2-bx .r-bx .editor_Content {
      font-size: 1rem;
      line-height: 1.88;
      letter-spacing: 0.32px;
      font-weight: 300;
      color: #6d6d6d; }
      @media (max-width: 767px) {
        .scadetailPage .item2 .it2-bx .r-bx .editor_Content {
          padding: 0; } }
    .scadetailPage .item2 .it2-bx .r-bx .editor_Content ul {
      padding-left: 0px;
      list-style: none;
      margin-left: 5px; }
      .scadetailPage .item2 .it2-bx .r-bx .editor_Content ul li {
        position: relative;
        padding-left: 10px; }
        .scadetailPage .item2 .it2-bx .r-bx .editor_Content ul li::before {
          position: absolute;
          content: '';
          left: 0;
          top: 14px;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #6d6d6d; }
    .scadetailPage .item2 .it2-bx .r-bx .down-bx {
      margin-top: 38px; }
      @media (max-width: 991px) {
        .scadetailPage .item2 .it2-bx .r-bx .down-bx {
          margin-top: 40px; } }
      .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 12px 0;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center; }
        @media (max-width: 991px) {
          .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li {
            padding: 8px 0; } }
        .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li:not(:last-child) {
          border-bottom: 1px solid #dcdcdc; }
        .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li div {
          font-size: 1.125rem;
          line-height: 2.67;
          letter-spacing: 0px;
          font-weight: 500;
          color: #6d6d6d; }
        .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li a {
          display: block;
          width: 40px;
          height: 40px;
          position: relative; }
          @media (max-width: 991px) {
            .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li a {
              width: 36px;
              height: 36px; } }
          .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li a::before {
            position: absolute;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            width: 48px;
            height: 48px;
            border: 1px solid #0073d8;
            border-radius: 50%;
            opacity: 0;
            -webkit-transition: .4s;
            -o-transition: .4s;
            transition: .4s; }
          @media (min-width: 992px) {
            .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li a:hover::before {
              opacity: 1; } }

.scadetailPage .item3 .des-ti {
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: 0px;
  font-weight: 300;
  color: #0073d8;
  margin-top: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #0073d8;
  text-align: left; }
  @media (max-width: 991px) {
    .scadetailPage .item3 .des-ti {
      margin-top: 0; } }

.scadetailPage .item3 .it3-bx .editor_Content {
  margin-top: 40px;
  margin-bottom: 45px; }
  @media (max-width: 767px) {
    .scadetailPage .item3 .it3-bx .editor_Content {
      padding: 0; } }

.scadetailPage .item3 .it3-bx .sh-btn1 {
  margin: 0 auto; }

.scadetailPage .item3 .it3-bx .it3-obx {
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid #0073d8; }
  @media (max-width: 991px) {
    .scadetailPage .item3 .it3-bx .it3-obx {
      margin-bottom: 45px; } }

.scadetailPage .item3 .it3-bx .sh-mbtn {
  color: #0073d8; }
  .scadetailPage .item3 .it3-bx .sh-mbtn::before, .scadetailPage .item3 .it3-bx .sh-mbtn::after {
    background-color: #0073d8; }

.scadetailPage .item4 {
  margin-top: 60px; }
  @media (max-width: 991px) {
    .scadetailPage .item4 {
      margin-top: 70px;
      margin-bottom: 15px; } }
  .scadetailPage .item4 .re-ti {
    font-size: 1.625rem;
    line-height: 1.38;
    letter-spacing: 0.65px;
    font-weight: 500;
    color: #0073d8;
    text-align: center;
    margin-bottom: 35px; }
  .scadetailPage .item4 .thr-list > li {
    width: 33.33333%;
    margin-bottom: 100px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    @media (max-width: 767px) {
      .scadetailPage .item4 .thr-list > li {
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 25px; } }
    @media (max-width: 767px) {
      .scadetailPage .item4 .thr-list > li:not(:last-child) {
        border-bottom: 1px solid #d2d2d2; } }
  .scadetailPage .item4 .thr-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    @media (min-width: 992px) {
      .scadetailPage .item4 .thr-list li:not(:last-child) a .top-img {
        border-right: 1px solid #d2d2d2; } }
    @media (max-width: 991px) and (min-width: 768px) {
      .scadetailPage .item4 .thr-list li:not(:nth-child(3n)) a .top-img {
        border-right: 1px solid #d2d2d2; }
      .scadetailPage .item4 .thr-list li:last-child a .top-img {
        border-right: none; } }
    .scadetailPage .item4 .thr-list li a {
      display: block; }
      .scadetailPage .item4 .thr-list li a .top-img {
        position: relative;
        padding: 0 16px;
        margin-bottom: 5px;
        padding-bottom: 100%; }
        @media (max-width: 767px) {
          .scadetailPage .item4 .thr-list li a .top-img {
            margin-bottom: 0px; } }
        .scadetailPage .item4 .thr-list li a .top-img::before {
          position: absolute;
          content: '';
          width: calc(100% - 32px);
          height: 100%;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          background-color: #0073d8;
          pointer-events: none;
          opacity: 0;
          -webkit-transition: .4s;
          -o-transition: .4s;
          transition: .4s;
          border-radius: 15px 0 15px 0; }
        .scadetailPage .item4 .thr-list li a .top-img::after {
          position: absolute;
          content: '';
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABJCAMAAACpb5DQAAAASFBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////8FevL4AAAAGHRSTlMApXwELeYY094hbJfvColeOUYPslO9yPmMBUlHAAACZklEQVRYw62Y24KrIAxFA8hF7gjo///paaenM7UqosBbkS5Cwg4BgGIbg8RWKWVlGgjcaybYeaFZWSzlgxbFwpVjVykkID3bMK36Bhl1ltMVY6TgcmdyAiQhrcZaDKZoOP7MHp+rUEmcjTNYY3OGYZH788kYEqE8IlBcF+WTgZj62oCwnA/jR9B8YZ8Qyw98aXI0lzYbprvBJRFdFYGjezaheF1Mjm59YWdzQ5CYf/8rCHZL2gh9BbMU95K4JuFWvzMuzMoLyoNBfy7FcXIXBPhjcVN5Q5dB5kN2FsF9ECT+62k9toCAv/2NFTSB3iYROrSBgL+8lDI0gv4HLrpWENNPoUx6agVBTjUrqwDJ59qUbAcN9DnMt4PgkeHMQjqAYgLPoQPIWkixB8ghwLYHyM9gcQ/QIADJHiCmAbkeoGkDUmLbFrrTKdbV3AJqDWLjtvGw07nOhRMF28dHAnCfqHGQqgcoRAi5B+hhDqM9QM9cRFkH0Ox/MkAzaFoMVHj7HJTml96aQepnD53m2lMQedWSZyf2OSjMr8udNo2gdyqKsg00vo9YL0gTSP36JrsW0PhXRXo6NYDQx/GB1H1QEOYzdw93QYavJOa4uQlSsXwVWEmyENT07V7Dz3P3bt2/0ddI03XOSN1esRSucpjYPTmCvkga+UEB4qm75B+Kj2sKW38dTaVpWZ4rX1CIKt+oCNayxijP49nhM8zzaZnLUI03iaOxiGJK27rXKCNpdkfa8xdeoh5WpazR9kHNBCsEvnivZzIuXMkwsKdtZvTO5oXb4Y4iiZcqc708m5gRDhO0NVLz2PEP5t0Y2L20YDMAAAAASUVORK5CYII=) no-repeat center center/cover;
          width: 67px;
          height: 67px;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          -webkit-transition: .2s ease;
          -o-transition: .2s ease;
          transition: .2s ease;
          pointer-events: none;
          opacity: 0; }
        .scadetailPage .item4 .thr-list li a .top-img img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1; }
      .scadetailPage .item4 .thr-list li a .ti {
        font-size: 1.625rem;
        line-height: 1.85;
        letter-spacing: 0px;
        font-weight: 500;
        color: #0073d8;
        padding: 0 16px; }
        @media (max-width: 767px) {
          .scadetailPage .item4 .thr-list li a .ti {
            padding: 0; } }
      @media (min-width: 768px) {
        .scadetailPage .item4 .thr-list li a:hover .top-img::before {
          opacity: .5; }
        .scadetailPage .item4 .thr-list li a:hover .top-img::after {
          opacity: 1; } }
    .scadetailPage .item4 .thr-list .editor_Content {
      font-size: 0.875rem;
      line-height: 2.14;
      letter-spacing: 0.28px;
      font-weight: 300;
      color: #6d6d6d;
      padding: 0 16px; }
      @media (max-width: 767px) {
        .scadetailPage .item4 .thr-list .editor_Content {
          padding: 0; } }
    .scadetailPage .item4 .thr-list .editor_Content ul {
      padding-left: 0px;
      list-style: none;
      margin-left: 5px; }
      .scadetailPage .item4 .thr-list .editor_Content ul li {
        position: relative;
        padding-left: 10px; }
        .scadetailPage .item4 .thr-list .editor_Content ul li::before {
          position: absolute;
          content: '';
          left: 0;
          top: 14px;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #6d6d6d; }

.rulePage .item1 {
  margin-top: 70px;
  margin-bottom: 90px; }
  @media (max-width: 767px) {
    .rulePage .item1 {
      margin-top: 60px;
      margin-bottom: 70px; } }

.aboutPage .sh-banner1 {
  height: 370px; }
  @media (max-width: 991px) {
    .aboutPage .sh-banner1 {
      background-image: none; } }
  @media (max-width: 768px) {
    .aboutPage .sh-banner1 {
      height: 0;
      padding-bottom: 84.3%; } }
  .aboutPage .sh-banner1 .bgcover.pc {
    height: 370px; }
  @media (max-width: 767px) {
    .aboutPage .sh-banner1 .bgcover.mo {
      padding-bottom: 84.3%; } }
  @media (max-width: 991px) {
    .aboutPage .sh-banner1 .center-bx {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-top: 7%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media (max-width: 767px) {
    .aboutPage .sh-banner1 .center-bx {
      display: block;
      padding-top: 10%; } }
  @media (max-width: 991px) {
    .aboutPage .sh-banner1 .center-bx .r-img {
      max-width: calc(100% - 381px); } }
  @media (max-width: 768px) {
    .aboutPage .sh-banner1 .center-bx .r-img {
      max-width: 65%; } }
  .aboutPage .sh-banner1 .center-bx .l-bx {
    margin-right: 13%; }
    @media (max-width: 991px) {
      .aboutPage .sh-banner1 .center-bx .l-bx {
        margin-right: 0;
        margin: 0; } }
  .aboutPage .sh-banner1 .center-bx .l-bx .des {
    font-size: 1.25rem; }
    @media (max-width: 768px) {
      .aboutPage .sh-banner1 .center-bx .l-bx .des {
        margin-top: 0;
        font-size: 1rem;
        margin-top: 15px; } }

.aboutPage .item1 {
  margin-top: 90px;
  margin-bottom: 130px; }
  @media (max-width: 991px) {
    .aboutPage .item1 {
      margin-top: 85px;
      margin-bottom: 60px; } }

.articlesearchPage .item1 {
  margin-top: 70px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .articlesearchPage .item1 {
      margin-bottom: 25px; } }
  .articlesearchPage .item1 .key-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    .articlesearchPage .item1 .key-bx .ti {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0.45px;
      font-weight: 300;
      color: #6d6d6d; }
    .articlesearchPage .item1 .key-bx .tag {
      background-color: #0073d8;
      color: #ffffff;
      margin-left: 10px; }

.articlesearchPage .item2 {
  margin-bottom: 70px; }
  @media (max-width: 767px) {
    .articlesearchPage .item2 {
      margin-bottom: 40px; } }
  .articlesearchPage .item2 ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 6px;
    border-bottom: 1px solid #0073d8; }
    .articlesearchPage .item2 ul li:first-child {
      margin-right: 6px; }
    .articlesearchPage .item2 ul li a {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0.45px;
      font-weight: 300;
      color: #0073d8;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      border-radius: 10px 0 10px 0;
      padding: 10px 15px;
      display: block; }
      @media (min-width: 768px) {
        .articlesearchPage .item2 ul li a:hover {
          color: #ffffff;
          background-color: #0073d8; } }
      @media (max-width: 767px) {
        .articlesearchPage .item2 ul li a {
          border-radius: 5px 0 5px 0; } }
    .articlesearchPage .item2 ul li.active a {
      color: #ffffff;
      background-color: #0073d8; }

.articlesearchPage .item3 .it3-bx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 767px) {
    .articlesearchPage .item3 .it3-bx {
      display: block; } }
  .articlesearchPage .item3 .it3-bx .r-bx {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2;
    width: 17.6%; }
    @media (max-width: 767px) {
      .articlesearchPage .item3 .it3-bx .r-bx {
        width: 100%;
        margin-bottom: 40px; } }
    .articlesearchPage .item3 .it3-bx .r-bx .ti {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0.45px;
      font-weight: 300;
      color: #0073d8;
      padding-bottom: 15px;
      padding-left: 12px;
      border-left: 1px solid #dcdcdc; }
      @media (max-width: 767px) {
        .articlesearchPage .item3 .it3-bx .r-bx .ti {
          padding-left: 0;
          border-left: none; } }
    .articlesearchPage .item3 .it3-bx .r-bx .tag-bx {
      padding-left: 12px;
      border-left: 1px solid #dcdcdc;
      position: relative; }
      @media (max-width: 767px) {
        .articlesearchPage .item3 .it3-bx .r-bx .tag-bx {
          padding-left: 0;
          border-left: none; } }
      .articlesearchPage .item3 .it3-bx .r-bx .tag-bx::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: -3px;
        width: 4px;
        height: 9px;
        background-color: #ffffff; }
      .articlesearchPage .item3 .it3-bx .r-bx .tag-bx a {
        margin-bottom: 10px; }
  .articlesearchPage .item3 .it3-bx .l-bx {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
    width: 79.3%;
    margin-right: 3%; }
    @media (max-width: 767px) {
      .articlesearchPage .item3 .it3-bx .l-bx {
        width: 100%;
        margin-right: 0; } }
    .articlesearchPage .item3 .it3-bx .l-bx ul {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      @media (max-width: 767px) {
        .articlesearchPage .item3 .it3-bx .l-bx ul {
          display: block; } }
      .articlesearchPage .item3 .it3-bx .l-bx ul li {
        width: 48.5%;
        margin-bottom: 24px; }
        @media (max-width: 767px) {
          .articlesearchPage .item3 .it3-bx .l-bx ul li {
            width: 100%;
            margin-bottom: 30px; } }
        @media (min-width: 768px) {
          .articlesearchPage .item3 .it3-bx .l-bx ul li:nth-last-child(2) {
            margin-bottom: 0; } }
        .articlesearchPage .item3 .it3-bx .l-bx ul li:last-child {
          margin-bottom: 0; }
        .articlesearchPage .item3 .it3-bx .l-bx ul li a {
          position: relative; }
          .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx {
            position: relative;
            margin-bottom: 24px;
            border-radius: 15px 0 15px 0; }
            @media (max-width: 767px) {
              .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx {
                border-radius: 7px 0 7px 0;
                margin-bottom: 15px; } }
            .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx img {
              border-radius: 15px 0 15px 0; }
              @media (max-width: 767px) {
                .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx img {
                  border-radius: 7px 0 7px 0; } }
            .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx::before {
              position: absolute;
              content: '';
              width: 100%;
              height: 100%;
              left: 50%;
              top: 50%;
              -webkit-transform: translate(-50%, -50%);
                  -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              background-color: #0073d8;
              pointer-events: none;
              opacity: 0;
              -webkit-transition: .4s;
              -o-transition: .4s;
              transition: .4s;
              border-radius: 15px 0 15px 0; }
              @media (max-width: 767px) {
                .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx::before {
                  border-radius: 7px 0 7px 0; } }
            .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx::after {
              position: absolute;
              content: '';
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABJCAMAAACpb5DQAAAASFBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////8FevL4AAAAGHRSTlMApXwELeYY094hbJfvColeOUYPslO9yPmMBUlHAAACZklEQVRYw62Y24KrIAxFA8hF7gjo///paaenM7UqosBbkS5Cwg4BgGIbg8RWKWVlGgjcaybYeaFZWSzlgxbFwpVjVykkID3bMK36Bhl1ltMVY6TgcmdyAiQhrcZaDKZoOP7MHp+rUEmcjTNYY3OGYZH788kYEqE8IlBcF+WTgZj62oCwnA/jR9B8YZ8Qyw98aXI0lzYbprvBJRFdFYGjezaheF1Mjm59YWdzQ5CYf/8rCHZL2gh9BbMU95K4JuFWvzMuzMoLyoNBfy7FcXIXBPhjcVN5Q5dB5kN2FsF9ECT+62k9toCAv/2NFTSB3iYROrSBgL+8lDI0gv4HLrpWENNPoUx6agVBTjUrqwDJ59qUbAcN9DnMt4PgkeHMQjqAYgLPoQPIWkixB8ghwLYHyM9gcQ/QIADJHiCmAbkeoGkDUmLbFrrTKdbV3AJqDWLjtvGw07nOhRMF28dHAnCfqHGQqgcoRAi5B+hhDqM9QM9cRFkH0Ox/MkAzaFoMVHj7HJTml96aQepnD53m2lMQedWSZyf2OSjMr8udNo2gdyqKsg00vo9YL0gTSP36JrsW0PhXRXo6NYDQx/GB1H1QEOYzdw93QYavJOa4uQlSsXwVWEmyENT07V7Dz3P3bt2/0ddI03XOSN1esRSucpjYPTmCvkga+UEB4qm75B+Kj2sKW38dTaVpWZ4rX1CIKt+oCNayxijP49nhM8zzaZnLUI03iaOxiGJK27rXKCNpdkfa8xdeoh5WpazR9kHNBCsEvnivZzIuXMkwsKdtZvTO5oXb4Y4iiZcqc708m5gRDhO0NVLz2PEP5t0Y2L20YDMAAAAASUVORK5CYII=) no-repeat center center/cover;
              width: 67px;
              height: 67px;
              left: 50%;
              top: 50%;
              -webkit-transform: translate(-50%, -50%);
                  -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
              -webkit-transition: .2s ease;
              -o-transition: .2s ease;
              transition: .2s ease;
              pointer-events: none;
              opacity: 0; }
          @media (min-width: 768px) {
            .articlesearchPage .item3 .it3-bx .l-bx ul li a:hover .img-bx::before {
              opacity: .5; }
            .articlesearchPage .item3 .it3-bx .l-bx ul li a:hover .img-bx::after {
              opacity: 1; } }
          .articlesearchPage .item3 .it3-bx .l-bx ul li a .date {
            font-size: 1rem;
            line-height: 1;
            letter-spacing: 0.4px;
            font-weight: 300;
            color: #bebebe;
            margin-bottom: 10px; }
            @media (max-width: 767px) {
              .articlesearchPage .item3 .it3-bx .l-bx ul li a .date {
                margin-bottom: 13px; } }
          .articlesearchPage .item3 .it3-bx .l-bx ul li a .des-ti {
            font-size: 1.625rem;
            line-height: 1.38;
            letter-spacing: 0.6px;
            font-weight: 500;
            color: #0073d8;
            margin-bottom: 3px; }
        .articlesearchPage .item3 .it3-bx .l-bx ul li .des {
          font-size: 1rem;
          line-height: 1.88;
          letter-spacing: 0.3px;
          font-weight: 300;
          color: #6d6d6d;
          overflow: hidden;
          display: -webkit-inline-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          vertical-align: middle; }
        .articlesearchPage .item3 .it3-bx .l-bx ul li .tag-bx {
          margin-top: 20px;
          height: 82px;
          overflow: hidden; }
          @media (max-width: 767px) {
            .articlesearchPage .item3 .it3-bx .l-bx ul li .tag-bx {
              margin-top: 20px;
              height: auto;
              max-height: 73px; } }
          .articlesearchPage .item3 .it3-bx .l-bx ul li .tag-bx a {
            margin-right: 5px;
            margin-bottom: 5px; }

.articlesearchPage .item3 .page {
  margin-top: 40px;
  margin-bottom: 100px; }
  @media (max-width: 767px) {
    .articlesearchPage .item3 .page {
      margin-bottom: 130px;
      margin-top: 50px; } }

@media (max-width: 991px) and (min-width: 768px) {
  .articlesearchPage .container {
    max-width: 100%; } }

.articlelistPage .item2 {
  margin-top: 70px; }
  @media (max-width: 767px) {
    .articlelistPage .item2 {
      margin-top: 40px; } }

.hiddendiv {
  display: none; }

@media (max-width: 991px) and (min-width: 768px) {
  .contactPage .container {
    max-width: 100%; } }

.contactPage .item {
  margin-top: 90px;
  margin-bottom: 100px;
  position: relative; }
  @media (max-width: 767px) {
    .contactPage .item {
      margin-top: 75px;
      margin-bottom: 70px; } }
  .contactPage .item .container {
    position: relative; }
  .contactPage .item .b-ti {
    font-size: 3.75rem;
    line-height: 1;
    letter-spacing: 1.5px;
    font-weight: 500;
    color: #0073d8;
    margin-bottom: 60px; }
    @media (max-width: 767px) {
      .contactPage .item .b-ti {
        font-size: 2.5rem;
        margin-bottom: 20px;
        position: relative; }
        .contactPage .item .b-ti::before {
          position: absolute;
          content: '';
          left: -125px;
          top: -36px;
          width: 115px;
          height: 175px;
          -webkit-transform-origin: bottom;
              -ms-transform-origin: bottom;
                  transform-origin: bottom;
          -webkit-transform: skewX(-32deg);
              -ms-transform: skewX(-32deg);
                  transform: skewX(-32deg);
          background-color: #bce0ff;
          opacity: .2;
          border-radius: 15px 0 15px 0;
          z-index: -1; } }
  .contactPage .item .con-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 70px; }
    @media (max-width: 767px) {
      .contactPage .item .con-bx {
        display: block;
        margin-bottom: 35px; } }
    .contactPage .item .con-bx .l-bx {
      background-color: #c7e5ff;
      padding-top: 70px;
      padding-left: 47px;
      padding-right: 20px;
      padding-bottom: 70px;
      width: 41.2%; }
      @media (max-width: 767px) {
        .contactPage .item .con-bx .l-bx {
          width: 100%;
          padding: 50px 20px 20px 20px; } }
      .contactPage .item .con-bx .l-bx ul {
        max-width: 305px; }
        .contactPage .item .con-bx .l-bx ul li {
          position: relative;
          padding-left: 50px;
          margin-bottom: 27px; }
          @media (max-width: 767px) {
            .contactPage .item .con-bx .l-bx ul li {
              margin-bottom: 20px; } }
          .contactPage .item .con-bx .l-bx ul li::before {
            position: absolute;
            content: '';
            left: 0;
            width: 35px;
            height: 35px; }
          .contactPage .item .con-bx .l-bx ul li .ti {
            font-size: 1.125rem;
            line-height: 1.75;
            letter-spacing: 0.3px;
            font-weight: 500;
            color: #004887;
            margin-bottom: 2px; }
          .contactPage .item .con-bx .l-bx ul li .des {
            font-size: 1rem;
            line-height: 1.75;
            letter-spacing: 0.3px;
            font-weight: 300;
            color: #004887; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(1)::before {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABFCAMAAAArU9sbAAAAeFBMVEVHcEz///////////////////////////////////////////////////////////////////////////////8Awro40MoMxb6B4d0pzMZV19H0/fwbycK07utq3Neh6eaR5eLk+fjK8/FD0szB8e/t+/rR9PPc9/afttdoAAAAFHRSTlMA2FdmLfShDeQEfrscdEqujj3Oxuj1B38AAAMtSURBVFjDpVjpwmswEKUIpZa2IYju2/u/4Y2lGnJi+e78I8kxy5klDEMvu72VkDg4BjHxN/bWWC/uhph0IE7g79dhRMShSEx/txRia5lUL8ReBLLx6LTE8/rYAZ0Vx5/xdOjQJXKYMssN6EJxNnp+mHS5JJrQ2B5dIwQ6x3boOokBzM6ja0U1yjXpevHHTgnoXyQaovhoz+daFixNWVFePxjFG9DYBjsuWSpJdoIwgWzPQVl+Fs3hKivLrGpx7ghGYp+lLObNucvta1qjV45scvv4jIPMS3HmMfj2s8Ypp+KkqFKDXJF6AMbplNl66n72VPefGDQq7MrS2LHio0/kyRNc8NpEGBOuAOa0ck3TQkO93ZgnIjg6rgoXX9SsRL4Vqtx1KHekjFObFCsbM5nAjGWXgTJvBaZuU44SoP4Uf7T0f3DJ3BxRZpxC4uBH5k02oNtNQKJkGse5SivJuEro/64kT0nLv1irNUFyy9c42YwsZarTXYMoKL3K5864T5qeJYNVFFvh3FCXU8dZSZcU8e44esN+jBCpUIjacCsk4hfIoo1hqvTkkvqsLJlkJIfEtoxxQp87M5q4tgWv0ahPyDNCMdXE/e3juYhylXP4DRllXHI5S9lLfubSw0sscoSiNKIzVHpyLVL4Qm8MZVwjb+HtG0V8CVH9z7TlBfUBujUi9aWu2OFS1+TRDtYiBlrqh2nqV13sTOhENf9rDmK3112AqK95BeqryOyKQxQbNJKOegPStFRJccf3mv6KJrFS6YMlbrD9SBWDlVc1+rLQrnphlPaOEaGlkRegp7obRtftDxTbdB5ETWOPSCLcqPtEeEr1ClL/16YxZVqmdjZxfevuVTGM/UzW6DOLHqTRmcAR85vcb5wRUoC6wRtO718VdKlcXwaGFzS4p2iO55pUrqPsDsfmRDNotH1aM4o49qIR/tr2AF181KuWe4SjXKYdu/u58O83NXQjmdJGK5bu8hkvx5i4foKGoAvx5A1/v+zeR9yZfx3JPIYXzf9nsGe844Xuov8VNvl/jIY7Iax/DolW/hHaWWTgaeeYRK7xF3H3m9BPEj+0ounfUv8AgTUna09VtpMAAAAASUVORK5CYII=) center center/cover; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(2)::before {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABFCAMAAAArU9sbAAAAb1BMVEVHcEz////////////////////////////////6/v7////////////////////////////////////////Y9vX///8Awrq/8O5+4NwOxr7s+/qc6OXc9/ZL1M8iy8Rc2dPK8/E2z8m07euJ4+Bp3NevzPfRAAAAFXRSTlMA5fF4D1ocBbP+LKFohtQ+TcLdmfF1kE27AAACb0lEQVRYw62Y6ZaDIAyF3QWXunDitFq7zvs/46S2VURQhLl/1U+yEBIcZ0VRkeU5S1nuh0ngmCjxiQe8ahZGOxF5DTKRMNZmFASU8nI904oKVqXDCRhsyg03IKULOiJry4lz0JRbqK2pQFs0U+XYAfYol0NqAGtMcIC98peOrWC/FhFPDSBAkzkkBCPVs7yJPDMKpDyFgKm47CuNIXAYS0Vcm1Mgs3TtZ0d9FzPlW3tttHRtF4vhvNI3muq5aC8ChI9/NIQ/E8MUUJ5ypMcjXc3X6+XnOKMw0bdIuTXNvVVD2nvTPOcULxZ2EFIAHXw5qyDnC7qWzimDSa5AAXzndJNDbqfvG0KFiECkQIeYhwzyQEgHCwoRsv9NGd8WNNIFCsbal1C+K59rslSgQOwwGWXw4u8s4vR38rpISeZFYaTQV0R7DkN7LgNESulUUgomBv/ZkCZ9CwpK6BwUFEzSyYR3moCKkjm1ivJ2Z4ff0u60eCBQFGtpr+hcDG1zut+RgYE/X5R+yRR+eZmA1tw+paLHCD/UMQrlMXrly/ON657P7ryRL+X8ODPLXcwX334fQSDZR7v3tItNKVjXF9zTjmdb64ZGhoBl3R1qXQaWZ8BQd/liZ3IeffoGziSTsxFzzvqYHg9qq5Zh6hEzG4j3bcpi9x+WYuUZl+sPK2MK3/Im1BBCZv2ub2iPMI+a9aqF/TAhGyei/eFmshl6LyaVztY7MSz+h5EvV4+f2pHyyrV5OtMbcaqNe4tIYzletn1nUW6Vm1TrAiUOV7xM00T/7iSV++fg77vIiUsmrMir/MTkUikosjwlFSEp88NkzaN/6lgyYsW9jy8AAAAASUVORK5CYII=) center center/cover; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(3)::before {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABGCAMAAACtx6m1AAAAflBMVEVHcEz////////////////////////9//////////////////////////////////////////////////////8Awrr2/f0VyMAJxL1o29bA8O41z8ni+fhD0sxb2NPa9/UhysMozMV339qF4t7r+vpO1c/P9POz7eum6ueV5uMwxpWnAAAAFHRSTlMA1xI3RvYm/nBaCMal7eKVsIcavBAexi4AAAPxSURBVFjDrVjZoqIwDFURCyiuLRSUfRH//wcnbUFLKVhnJi+Xi3Cac5I0KavVol2treturd1m9ZdmOYc1woP5+5P7M5R1OmLFEEZ752oO4TlrPGP22TLF8PGS7U1wtkf8zQ7feF3P2MB8Z9kRH5vZYSFeDsKmtt7NyXrCP5i/1aMc8E9mb/8DiB7mgn82f6KNi//CjkqkLPvzW1CGNAqMYM7j8EiF8ygI2H0EU0VdrYVxZkQJACTPCYnkh++A2zx1CkvS7KRsSwlJghqg5Jdywv2LFzndPnefgkxLSCEncvZKGI5Grne4LelmQmjG/paEvMaPtw1z5zHZKAYUqY4fhKT8og5JqC4cA7H8MePMTrr1IqQnXxHSvUOUVNzBGmjlarBuAkUuwpTQ3oOakkZSnCQtCyCwStTC52Hy/FFI78NlQ8LhMqJM2hJeD8CbStnVL3xnkm+FvSwchbxXDZ4sREnA9SKZstWohDCs2F915OMWT4FQkIFUaDSU1loUWJO2gyfvyoiEj+2kDDajG3TI/FTin4cRI1HnPICtUh0YHRRZ3rowPuhzU0DGguT9HcePMA7G0xihvE9hESJWRqVwMJZzaihJRVwcEYrEqnI8UUd5dWY8sx+fELzlHbexfh1I3HGmt5TkiLma8wpP1CLYj/7PxDpQi0o0Sw5fcvSGY0nmKii4IIXwRUktQePJsYC2irLWrRlP9gURvZjLM/HUUVGEgEE4TlwuRspzpZuiIEdlBNsUo16q0QROwLEtqJ7RTXn4yVMTZEj0zQMiVcedGqNJY71zZdPx9r1s1qSzopi7AfrkgSnKVdNam0HCyLRfjxtALyMl4QMHuTEnaAOePbn74vsRMKOtEcpp3NKkaJecUzhkcJ3ND2vIXalbw7DRARsEcQo5qQfAhtUcDtqsdMLwTGfS8A7yejW8S5NEPzb0DUk3rleiXVdUAJB7CVd5tjB9ODovgUMKFLKU4dw7hDPIxlCnti0Gqo2t+a3ud3wctLFgwviFD32EZgfDDBSOkLKdKiMW93qYg646Z3ALMM3oJdRM2ivM8l+mVAZTyEogyGd1HJFGMk9/mslAGzpKk2rSFy/fp102aJC8Q7LkY33Xnsn43rG+WFTi1WdBPiOF4Dg+t21mTmiojnjW5U3ahJMxGKunLcueK7YsGhJ4MmOep8e0WRgcdGlBCS0iRdm9Nz2UzJ8nZop5rT31uebnPQ4yc5J17R9A9rPnT+toDHLw5g+xm7MZhu0uH8tdkxP1bfftC8Hm9E3ko2vyuWJ3WMI5Op7hp5PdZU7mvev98hlne5ps6vbtl4847+8f7uW89oEd8o/7g7P1Vv9iBm//ATsmLCOiJb1/AAAAAElFTkSuQmCC) center center/cover; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(4)::before {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABFCAMAAAArU9sbAAAAaVBMVEVHcEz///////////////////////////////////////////////////////////////////////////8AwrrQ9PNP1dB/4d1e2dRv3dgfycJ439qu7OmO5OHw/PsPxr890cua5+Sx7OrYzBpeAAAAE3RSTlMA/A4d7nJXBcSa4DpjtNKFLkf1DJry/wAAAnJJREFUWMPNmNmCoyAQRYOKLIpG0Y6dRZP+/48cl0kEu0pB52HuGxFPoApr4XRaEQuLmFKapnESnHYpiEVW6lmRookngsWKaKJNDaNIeIBCEWlQPSlLmSOD6DXJ1GEvtNRb4ucNyDnTDiL56rbSUruJh/huhHZWVGAnRGkPlTEM4dpLBPIV84T0AlYjvCGa/LJNbj6uuwpRV5uUaOGpwvqTS4XqYk3MrHMTyCXl+QXouaTofMUoPaWGDFH/ohDjY0j0XormM4Xvp8zuLvQBimTIUrwo78Uk+hCFY6fWi6JHNwXlQcp4ZmJ9kCKRz9CPMm4pO0yhfeIwhu3PfVRTVc0N0PD7NOOnNb4CZR+5a+Wsq20YagwrD5lbCizj9gHhMar5QtRMz5825XxSFqVxi5WNTSmsj2gvJbYcvZeSnuR/RPk3O4Ks2z6+EV0Q6wqA8sIP2wuiJFZSfFM2s9qCElrhxaDca0B3mBL1NRhMWY8MNqWPvKw8TBlCpkIpL9M7NU4ZChCKUWrLrE+UUg55LTQp3XVQN1Jaq4r5niifCfNbapEajZfuw+Ez/TM8v4NRakqO6Wd8u16NtYDW7f5Ouc1+nhI1i7Ac0BpCc8C7EKIwpbWC+QOhlO/aLohAyoqPDIlPFUThHV3MuF3DlGhuBVm2OzdSozpMyE4KZ2jR7F6pLspmuwtwrpqXnUAo/St4wz+fXi/y7iYU0PclpSeEQ/0+88Qo5NLgLD0gAm1jQ+d+jdC1njwnThC5cd+QOLTlRATbVwRbLt+8IJgCRb7G4YXz9UuKmNnrAmZwF1XL4yNFwfzvlNg5zgXPpMy4ErQIV6b+AaPsGbRKE/hTAAAAAElFTkSuQmCC) center center/cover; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(5)::before {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABFCAMAAAArU9sbAAAAZlBMVEVHcEz///////////////////////////////////////////////////////////////////8Awrp/4Nxf2dSf6OXB8e+v7Ok/0csHxLwuzcfR9PMeycLq+vpM1M9p3Ne47uyX5uMkU4RJAAAAEXRSTlMA5aaWWS4bfAu48mvVQtxOx7IL/3cAAAJ1SURBVFjDrZjZtoMgDEUVtYJiNWid7fD/P3kd2jpBkHrPS1cf3IskB0iwLLUuUegQ1os4vutZP4jGDoeVAuYbkiISgEzcvxxmuBzUIscW5F4BF9Gvx0tAL5/ikDCAI0qwsCiBgwpctT84HJevSokNJnLkkADMRGTh2ACnMXTOSV4LTHWuzg2bIZXAVc2YeA3x53WWQtwzte5ClHPBVzb2FtFWIkOzkYlq/sOWlKXthUhRSirE4t/CfS6oKUVdZwjF/m4paiOUW18VhDLXKYQTlOCzmCtC6TIh2gKhfBbjgppym8xTN2qKvTXcjpJLnLalQDRuIFBTeotV92zYEamaQva5XVNaIV7jhw91RFN+CU4pu/73iWR3CslGKI8hJVmDVXqqkgcIpWvH3JY5SmG7Om8qPdhl0Auj2KszQerd5jFapkEoQHfJ3e/pbsjOA6N4W8+tKWn6SXKJUWIrQShFf+z1oXT98ZdhFNfiCGWsUFsPiblhlHBnlyWl+d4GJeAUjvolnfZ0BhpKoqlR/1Hb4d7t88I0FN1ZN9XI+QeKp/Vu0bZ3HYVasda76H303keX8xSGnC/P21ZP9fmiPOvKXbNQKije/iIxp4xXCQ3klCbfqpFTpgaPnMxLNI0O5yLi73uan6KEsvblS8mLrXIZZW5g+AnXhd9eKv6dwqV9qmF3+C7Qu/EOfutUN8PAIiaTrplvxq3FYXW8gw+2wxZlYC733IilHrSMMfJp7ZL8A8QwN+rxc38hqHRFJ/zo2NxHNGM5dQ4sJNa/M3ia7AS694FPWAxjHH87ufhy8zCXmj3keD5bXw7cMUXMD1O+08sPXQ8l/AEt7PsaOtXPEwAAAABJRU5ErkJggg==) center center/cover; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(6)::before {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAABGCAMAAACtx6m1AAAAb1BMVEVHcEz////////////////////////8//7////////////////////////////////////////i+Pf///8AwroFxLzg+PdL1M+M5OAly8Tu+/s3z8le2dS67+1w3dkTx7+A4d2m6ufH8vCb6OTU9fQ/TXo0AAAAE3RSTlMAB89pFCE8/vTgfZEtolyxwUb7vCv6uwAAA1dJREFUWMOtWNm2qyAMPbXOQ2sXOOF01P7/N14mW4NUoffkpQusW9jZCQk/P8d2cT3Pvfx8bRc/zpwQcQuiPLl59hC3OwcI0MaixLfB8LMQ6S2KXUOMR44OLExMcLw7OrHwesb2JQ7RuTnH/HgOMrIgPmIkRKZ2/8jONUDm5nyQT4ysLNLCXBH6f5gbsrZox40f2KOgXAFxI/SNKQ4/EexU4LHtJrJ78LBhtsPcimo+oMY9U1s5VO3IgaoSPkneKIkRCWXdsPUscDZ9RY+xf3qGM8BQsFuKCMNfFSZIDVlR3YXr7UT2Vfz0BS62rgqFm04ENwxDDyZqjJstk1cGkp58m3r4F85UGC9qHMRap7YvDvcozwIuhm0p10FQa+SIstmRnZS3m7zRfB3qIKi1bEiE9otfIKmeAkP9+sCNrYyXhWqdiWGFxO12OcG4rlQkTyU7FRyCxm4pvrawccMjaFD43aCGinDpU0HlU7xGxyMNv7nBFdjSgPFzM/RgZgkGyUcvBCpREJG8kD5YJbOl11dcVMu1TBhP9IdFTdG9ldpKVmvVSY4qqHLzN9JwapslWHmr+C9Fn4F6I0WoDdg4qYSLGo5NVg938mN6lHLzN+mEuSs4DHlzjlCDRwRQwI7mVe0VLl6TpB6l6yTn7GMVArzkiihruRbuqqfAJKN4TXLOaKnhSXDXogQ9EeTybB00AmV1DVX0EAAUoLrXxoOXCIt26Jin6g3nTAAVUB04igh82o9rGAl2uWvIVPFAAREAopGRv10qEQ6igi7X9FSJmXYC0egeZQ6WKehbbb9KV2LWpZq/nYPMoeS6RgMhEy+gl3qjeH5EGTQQnFxaEoKZRRGULu+qFd7+JGHSqCEzZUkMihh4wM700OqtjjlR3nlITTHFZAGyHveZchiy2DMv89ZySj0dGUxjuqv8Y1W3MH02Oqfu7d1YeGrtUYoUN7bVcAKVHRbvs4yXnZSVXmlbOF80HQ2ZOloRnrjrCurd1Kqe2hd137cBukYgtgcJ031TkliDaLvH7C9ALDcVpX/QODoHtw6+aZuUHTbmrhE54e30luF8OZnBTcPleoxzT00vXz5eFIRZanEDkyaaBYX5zfpuybu9LpSYPPL48fX1lJv6j4efeicA/wBr2BnXgusb5QAAAABJRU5ErkJggg==) center center/cover; }
    .contactPage .item .con-bx .r-bx {
      padding-left: 8%;
      padding-right: 7%;
      width: 59%;
      padding-top: 70px;
      background-color: #f6f6f6; }
      @media (max-width: 767px) {
        .contactPage .item .con-bx .r-bx {
          width: 100%;
          padding: 0 15px;
          padding-top: 50px;
          padding-bottom: 45px; } }
      .contactPage .item .con-bx .r-bx .form-bx .flex-bx {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex; }
        @media (max-width: 767px) {
          .contactPage .item .con-bx .r-bx .form-bx .flex-bx {
            display: block; } }
      .contactPage .item .con-bx .r-bx .form-bx .input-bx {
        width: 100%; }
        @media (max-width: 767px) {
          .contactPage .item .con-bx .r-bx .form-bx .input-bx {
            margin-top: 15px; } }
      .contactPage .item .con-bx .r-bx .form-bx .form-group {
        margin-bottom: 20px; }
      .contactPage .item .con-bx .r-bx .form-bx label {
        font-size: 1.125rem;
        line-height: 1;
        letter-spacing: 0.45px;
        font-weight: 500;
        color: #6d6d6d;
        margin-right: 5%;
        min-width: 84px; }
        .contactPage .item .con-bx .r-bx .form-bx label i {
          color: #0073d8; }
        @media (max-width: 767px) {
          .contactPage .item .con-bx .r-bx .form-bx label {
            font-size: 1rem; } }
      .contactPage .item .con-bx .r-bx .form-bx input {
        height: 40px;
        border-radius: 0;
        background-color: #ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 0);
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        padding-left: 11px; }
        .contactPage .item .con-bx .r-bx .form-bx input:focus {
          border-bottom: 1px solid #0073d8; }
      .contactPage .item .con-bx .r-bx .form-bx .form-textbx {
        margin-bottom: 30px; }
        @media (max-width: 767px) {
          .contactPage .item .con-bx .r-bx .form-bx .form-textbx {
            margin-bottom: 0; } }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .text-bx {
          width: 100%; }
          @media (max-width: 767px) {
            .contactPage .item .con-bx .r-bx .form-bx .form-textbx .text-bx {
              margin-top: 15px; } }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .focus.textarea-wrapper {
          border-bottom: 1px solid #0073d8; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .textarea-wrapper {
          height: 160px;
          overflow: hidden;
          border: none;
          padding-top: 5px;
          padding-bottom: 5px;
          background-color: #ffffff;
          -webkit-transition: .4s;
          -o-transition: .4s;
          transition: .4s;
          border-bottom: 1px solid rgba(255, 255, 255, 0); }
          @media (max-width: 767px) {
            .contactPage .item .con-bx .r-bx .form-bx .form-textbx .textarea-wrapper {
              height: 180px; } }
          .contactPage .item .con-bx .r-bx .form-bx .form-textbx .textarea-wrapper textarea {
            min-height: 148px;
            height: 0;
            overflow: hidden;
            border-bottom: none;
            padding-top: 5px;
            padding-left: 11px;
            text-rendering: auto;
            -webkit-writing-mode: horizontal-tb !important;
            background-color: #ffffff;
            resize: none;
            border: none;
            font-family: "Noto Sans TC", "微軟正黑體", Verdana, sans-serif;
            cursor: text;
            line-height: 1; }
            @media (max-width: 767px) {
              .contactPage .item .con-bx .r-bx .form-bx .form-textbx .textarea-wrapper textarea {
                min-height: 168px; } }
            .contactPage .item .con-bx .r-bx .form-bx .form-textbx .textarea-wrapper textarea:focus {
              border: none; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
          background-color: #000000; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerRail {
          background-color: #000000;
          width: 1px; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .mCSB_scrollTools {
          right: -5px; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .mCSB_inside > .mCSB_container {
          margin-right: 13px; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerContainer {
          top: 0px;
          left: -12px; }
      .contactPage .item .con-bx .r-bx .form-bx .help-block {
        margin-top: 5px;
        font-size: 0.875rem;
        line-height: 1;
        letter-spacing: 0.35px;
        font-weight: 300;
        color: #0073d8; }
      .contactPage .item .con-bx .r-bx .form-bx .send-btn {
        width: 96px;
        height: 48px;
        margin-left: auto; }
        @media (max-width: 767px) {
          .contactPage .item .con-bx .r-bx .form-bx .send-btn div {
            line-height: 3.1; } }
  .contactPage .item .l-sqbx {
    position: absolute;
    left: -370px;
    top: 30px;
    z-index: -1; }
    @media (max-width: 767px) {
      .contactPage .item .l-sqbx {
        display: none; } }
    .contactPage .item .l-sqbx .t-bx, .contactPage .item .l-sqbx .b-bx {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
    .contactPage .item .l-sqbx .b-bx {
      margin-left: 15px; }
      .contactPage .item .l-sqbx .b-bx .sq:first-child {
        position: relative;
        top: -50px; }
    .contactPage .item .l-sqbx .t-bx .sq:first-child {
      margin-top: 60px;
      position: relative;
      left: -50px; }
    .contactPage .item .l-sqbx .sq {
      width: 150px;
      height: 250px;
      -webkit-transform-origin: bottom;
          -ms-transform-origin: bottom;
              transform-origin: bottom;
      -webkit-transform: skewX(-32deg);
          -ms-transform: skewX(-32deg);
              transform: skewX(-32deg);
      background-color: #bce0ff;
      opacity: .2;
      border-radius: 15px 0 15px 0;
      margin: 0 15px;
      margin-bottom: 30px; }
  .contactPage .item .r-sqbx {
    position: absolute;
    right: -240px;
    bottom: 330px;
    z-index: -1; }
    @media (max-width: 767px) {
      .contactPage .item .r-sqbx {
        display: none; } }
    .contactPage .item .r-sqbx .t-bx {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
      .contactPage .item .r-sqbx .t-bx .sq:nth-child(2) {
        position: relative;
        top: 130px;
        left: -70px; }
    .contactPage .item .r-sqbx .sq {
      width: 150px;
      height: 250px;
      -webkit-transform-origin: bottom;
          -ms-transform-origin: bottom;
              transform-origin: bottom;
      -webkit-transform: skewX(-32deg);
          -ms-transform: skewX(-32deg);
              transform: skewX(-32deg);
      background-color: #bce0ff;
      opacity: .2;
      border-radius: 15px 0 15px 0;
      margin: 0 15px;
      margin-bottom: 30px; }
  .contactPage .item .map-bx iframe {
    height: 320px; }

.searchlistPage .item1 {
  margin-top: 70px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .searchlistPage .item1 {
      margin-bottom: 25px; } }
  .searchlistPage .item1 .key-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    .searchlistPage .item1 .key-bx .ti {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0.45px;
      font-weight: 300;
      color: #6d6d6d; }
    .searchlistPage .item1 .key-bx .s-des {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0.2px;
      font-weight: 300;
      color: #0073d8;
      position: relative;
      margin-left: 5px; }
      .searchlistPage .item1 .key-bx .s-des::before {
        position: absolute;
        content: '';
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #0073d8; }

.articledetailPage .item2 {
  margin-top: 70px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .articledetailPage .item2 {
      margin-bottom: 25px; } }

.articledetailPage .item3 .t-bx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%; }
  @media (max-width: 767px) {
    .articledetailPage .item3 .t-bx {
      display: block; } }
  .articledetailPage .item3 .t-bx .tit-blsq {
    max-height: 52px;
    overflow: hidden; }
    @media (max-width: 767px) {
      .articledetailPage .item3 .t-bx .tit-blsq {
        margin-top: 20px;
        margin-bottom: 20px;
        max-height: initial;
        overflow: inherit; } }
    .articledetailPage .item3 .t-bx .tit-blsq div {
      font-size: 26px; }
      .articledetailPage .item3 .t-bx .tit-blsq div::before {
        top: 2px;
        left: 6px; }
        @media (max-width: 767px) {
          .articledetailPage .item3 .t-bx .tit-blsq div::before {
            top: -3px;
            left: 0;
            width: 40px;
            height: 60px; } }
  .articledetailPage .item3 .t-bx .date {
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.4px;
    font-weight: 500;
    color: #bebebe; }

.articledetailPage .item3 .it3-bx {
  display: block; }
  .articledetailPage .item3 .it3-bx .editor_Content {
    margin-top: 15px;
    margin-bottom: 60px; }
    @media (max-width: 767px) {
      .articledetailPage .item3 .it3-bx .editor_Content {
        margin-bottom: 40px; } }
  .articledetailPage .item3 .it3-bx .it3-inbx {
    padding-bottom: 30px;
    border-bottom: 1px solid #0073d8; }
    @media (max-width: 767px) {
      .articledetailPage .item3 .it3-bx .it3-inbx {
        padding-bottom: 45px; } }
  .articledetailPage .item3 .it3-bx .sh-mbtn {
    color: #0073d8;
    margin-top: 32px; }
    .articledetailPage .item3 .it3-bx .sh-mbtn::before, .articledetailPage .item3 .it3-bx .sh-mbtn::after {
      background-color: #0073d8; }
  .articledetailPage .item3 .it3-bx .tag-bx .tag {
    margin-right: 10px;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      .articledetailPage .item3 .it3-bx .tag-bx .tag {
        margin-right: 5px;
        margin-bottom: 5px; } }

.articledetailPage .item4 {
  margin-top: 60px;
  margin-bottom: 75px; }
  @media (max-width: 991px) {
    .articledetailPage .item4 {
      margin-top: 70px;
      margin-bottom: 15px; } }
  .articledetailPage .item4 .re-ti {
    font-size: 1.625rem;
    line-height: 1.38;
    letter-spacing: 0.65px;
    font-weight: 500;
    color: #0073d8;
    text-align: center;
    margin-bottom: 35px; }
  .articledetailPage .item4 .thr-list > li {
    width: 33.33333%;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    @media (max-width: 767px) {
      .articledetailPage .item4 .thr-list > li {
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 25px; } }
    @media (max-width: 767px) {
      .articledetailPage .item4 .thr-list > li:not(:last-child) {
        border-bottom: 1px solid #d2d2d2; } }
  .articledetailPage .item4 .thr-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .articledetailPage .item4 .thr-list li {
      width: 32%; }
      .articledetailPage .item4 .thr-list li:not(:last-child) {
        margin-right: 2%; }
      @media (max-width: 767px) {
        .articledetailPage .item4 .thr-list li {
          width: 100%;
          margin-bottom: 25px;
          border-bottom: none !important; } }
      .articledetailPage .item4 .thr-list li a {
        position: relative; }
        .articledetailPage .item4 .thr-list li a .img-bx {
          position: relative;
          margin-bottom: 24px;
          border-radius: 15px 0 15px 0; }
          @media (max-width: 767px) {
            .articledetailPage .item4 .thr-list li a .img-bx {
              border-radius: 7px 0 7px 0;
              margin-bottom: 15px; } }
          .articledetailPage .item4 .thr-list li a .img-bx img {
            border-radius: 15px 0 15px 0; }
            @media (max-width: 767px) {
              .articledetailPage .item4 .thr-list li a .img-bx img {
                border-radius: 7px 0 7px 0; } }
          .articledetailPage .item4 .thr-list li a .img-bx::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            background-color: #0073d8;
            pointer-events: none;
            opacity: 0;
            -webkit-transition: .4s;
            -o-transition: .4s;
            transition: .4s;
            border-radius: 15px 0 15px 0; }
            @media (max-width: 767px) {
              .articledetailPage .item4 .thr-list li a .img-bx::before {
                border-radius: 7px 0 7px 0; } }
          .articledetailPage .item4 .thr-list li a .img-bx::after {
            position: absolute;
            content: '';
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABJCAMAAACpb5DQAAAASFBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////8FevL4AAAAGHRSTlMApXwELeYY094hbJfvColeOUYPslO9yPmMBUlHAAACZklEQVRYw62Y24KrIAxFA8hF7gjo///paaenM7UqosBbkS5Cwg4BgGIbg8RWKWVlGgjcaybYeaFZWSzlgxbFwpVjVykkID3bMK36Bhl1ltMVY6TgcmdyAiQhrcZaDKZoOP7MHp+rUEmcjTNYY3OGYZH788kYEqE8IlBcF+WTgZj62oCwnA/jR9B8YZ8Qyw98aXI0lzYbprvBJRFdFYGjezaheF1Mjm59YWdzQ5CYf/8rCHZL2gh9BbMU95K4JuFWvzMuzMoLyoNBfy7FcXIXBPhjcVN5Q5dB5kN2FsF9ECT+62k9toCAv/2NFTSB3iYROrSBgL+8lDI0gv4HLrpWENNPoUx6agVBTjUrqwDJ59qUbAcN9DnMt4PgkeHMQjqAYgLPoQPIWkixB8ghwLYHyM9gcQ/QIADJHiCmAbkeoGkDUmLbFrrTKdbV3AJqDWLjtvGw07nOhRMF28dHAnCfqHGQqgcoRAi5B+hhDqM9QM9cRFkH0Ox/MkAzaFoMVHj7HJTml96aQepnD53m2lMQedWSZyf2OSjMr8udNo2gdyqKsg00vo9YL0gTSP36JrsW0PhXRXo6NYDQx/GB1H1QEOYzdw93QYavJOa4uQlSsXwVWEmyENT07V7Dz3P3bt2/0ddI03XOSN1esRSucpjYPTmCvkga+UEB4qm75B+Kj2sKW38dTaVpWZ4rX1CIKt+oCNayxijP49nhM8zzaZnLUI03iaOxiGJK27rXKCNpdkfa8xdeoh5WpazR9kHNBCsEvnivZzIuXMkwsKdtZvTO5oXb4Y4iiZcqc708m5gRDhO0NVLz2PEP5t0Y2L20YDMAAAAASUVORK5CYII=) no-repeat center center/cover;
            width: 67px;
            height: 67px;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            -webkit-transition: .2s ease;
            -o-transition: .2s ease;
            transition: .2s ease;
            pointer-events: none;
            opacity: 0; }
        @media (min-width: 768px) {
          .articledetailPage .item4 .thr-list li a:hover .img-bx::before {
            opacity: .5; }
          .articledetailPage .item4 .thr-list li a:hover .img-bx::after {
            opacity: 1; } }
        .articledetailPage .item4 .thr-list li a .date {
          font-size: 1rem;
          line-height: 1;
          letter-spacing: 0.4px;
          font-weight: 300;
          color: #bebebe;
          margin-bottom: 10px; }
          @media (max-width: 767px) {
            .articledetailPage .item4 .thr-list li a .date {
              margin-bottom: 13px; } }
        .articledetailPage .item4 .thr-list li a .des-ti {
          font-size: 1.625rem;
          line-height: 1.38;
          letter-spacing: 0.6px;
          font-weight: 500;
          color: #0073d8;
          margin-bottom: 3px;
          height: 70px;
          overflow: hidden; }
          @media (max-width: 767px) {
            .articledetailPage .item4 .thr-list li a .des-ti {
              height: auto; } }
      .articledetailPage .item4 .thr-list li .des {
        font-size: 1rem;
        line-height: 1.88;
        letter-spacing: 0.3px;
        font-weight: 300;
        color: #6d6d6d;
        overflow: hidden;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        vertical-align: middle; }
      .articledetailPage .item4 .thr-list li .tag-bx {
        margin-top: 20px;
        height: 82px;
        overflow: hidden; }
        @media (max-width: 767px) {
          .articledetailPage .item4 .thr-list li .tag-bx {
            margin-top: 20px;
            height: auto;
            max-height: 73px; } }
        .articledetailPage .item4 .thr-list li .tag-bx a {
          margin-right: 5px;
          margin-bottom: 5px; }

.bgcover {
  width: 100%;
  height: 0;
  background: 50%/cover; }

.mo {
  display: none; }
  @media (max-width: 767px) {
    .mo {
      display: block; } }

.pc {
  display: none; }
  @media (min-width: 768px) {
    .pc {
      display: block; } }

.container {
  padding: 0 33px;
  margin: 0 auto;
  width: 100%; }
  @media (max-width: 767px) {
    .container {
      padding: 0 25px; } }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1088px; } }

#top-menu-ul {
  position: relative;
  z-index: 1; }
  @media (max-width: 767px) {
    #top-menu-ul {
      margin: 0 auto 35px; } }
  @media (min-width: 768px) {
    #top-menu-ul {
      margin: 0 auto 50px; } }
  #top-menu-ul .item_Menu {
    margin: 0 auto;
    overflow: hidden;
    height: 30px; }
  #top-menu-ul .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: center; }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex; }
    #top-menu-ul .item_menu_Box li {
      height: 30px; }
    #top-menu-ul .item_menu_Box li a {
      position: relative;
      display: block;
      line-height: 1;
      letter-spacing: 0.6px;
      border-bottom: 1px solid #dbdbdb;
      font-size: 0.875rem;
      color: #000000; }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li a {
          padding: 7px 0 3px;
          margin: 0 20px 0 0; } }
      @media (min-width: 768px) {
        #top-menu-ul .item_menu_Box li a {
          margin: 0 10px;
          padding: 7px 0; } }
    #top-menu-ul .item_menu_Box li.active a {
      border-color: black;
      color: black; }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    z-index: 10;
    background-color: #000000; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 0; }
  .flex-direction-nav a.flex-next {
    right: -18px; }
  .flex-direction-nav a.flex-prev {
    left: -18px; }

.open_flexslider .flex-direction-nav {
  display: block; }

.sh-btn1 {
  border-radius: 15px 0 15px 0;
  border: 1px solid #0073d8;
  width: 162px;
  height: 49px;
  text-align: center;
  display: block;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s; }
  .sh-btn1 div {
    font-size: 1rem;
    line-height: 2.9;
    letter-spacing: 0.32px;
    font-weight: 500;
    color: #0073d8;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }
  @media (min-width: 768px) {
    .sh-btn1:hover {
      background-color: #0073d8; }
      .sh-btn1:hover div {
        color: #ffffff; } }
  @media (max-width: 991px) {
    .sh-btn1 {
      border-radius: 7px 0 7px 0;
      width: 156px;
      height: 45px; }
      .sh-btn1 div {
        font-size: 0.9375rem; } }

.tit-blsq div {
  position: relative;
  font-size: 2.5rem;
  line-height: 2;
  letter-spacing: 0.8px;
  font-weight: 500;
  color: #0073d8;
  display: inline-block; }

.tit-blsq div:before {
  position: absolute;
  content: '';
  top: 10px;
  left: -23px;
  -webkit-transform-origin: bottom;
      -ms-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transform: skewX(-32deg);
      -ms-transform: skewX(-32deg);
          transform: skewX(-32deg);
  background-color: #0073d8;
  opacity: .2;
  border-radius: 5px 0 5px 0;
  width: 34px;
  height: 48px; }

.sh-mbtn {
  position: relative;
  font-size: 1rem;
  line-height: 2;
  letter-spacing: 0.8px;
  font-weight: 500;
  color: #ffffff;
  display: inline-block;
  padding-right: 96px;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s; }
  @media (min-width: 768px) {
    .sh-mbtn:hover {
      padding-right: 110px; } }
  .sh-mbtn::before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 7px;
    width: 80px;
    height: 1px;
    background-color: #ffffff;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }
  .sh-mbtn::after {
    position: absolute;
    content: '';
    right: -1px;
    bottom: 11px;
    width: 11px;
    height: 1px;
    background-color: #ffffff;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg); }
    @media (max-width: 767px) {
      .sh-mbtn::after {
        bottom: 10px;
        width: 8px; } }

.four-list > li {
  width: 25%;
  margin-bottom: 100px;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media (max-width: 991px) and (min-width: 768px) {
    .four-list > li {
      width: 50%; } }
  @media (max-width: 767px) {
    .four-list > li {
      width: 100%;
      padding-bottom: 15px;
      margin-bottom: 25px; } }
  @media (max-width: 767px) {
    .four-list > li:not(:last-child) {
      border-bottom: 1px solid #d2d2d2; } }

.four-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media (min-width: 992px) {
    .four-list li:not(:nth-child(4n)) a .top-img {
      border-right: 1px solid #d2d2d2; }
    .four-list li:last-child a .top-img {
      border-right: none; } }
  @media (max-width: 991px) and (min-width: 768px) {
    .four-list li:not(:nth-child(2n)) a .top-img {
      border-right: 1px solid #d2d2d2; }
    .four-list li:last-child a .top-img {
      border-right: none; } }
  .four-list li a {
    display: block; }
    .four-list li a .top-img {
      position: relative;
      padding: 0 16px;
      padding-bottom: 100%; }
      .four-list li a .top-img::before {
        position: absolute;
        content: '';
        width: calc(100% - 32px);
        height: 100%;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #0073d8;
        pointer-events: none;
        opacity: 0;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        border-radius: 15px 0 15px 0; }
      .four-list li a .top-img::after {
        position: absolute;
        content: '';
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABJCAMAAACpb5DQAAAASFBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////8FevL4AAAAGHRSTlMApXwELeYY094hbJfvColeOUYPslO9yPmMBUlHAAACZklEQVRYw62Y24KrIAxFA8hF7gjo///paaenM7UqosBbkS5Cwg4BgGIbg8RWKWVlGgjcaybYeaFZWSzlgxbFwpVjVykkID3bMK36Bhl1ltMVY6TgcmdyAiQhrcZaDKZoOP7MHp+rUEmcjTNYY3OGYZH788kYEqE8IlBcF+WTgZj62oCwnA/jR9B8YZ8Qyw98aXI0lzYbprvBJRFdFYGjezaheF1Mjm59YWdzQ5CYf/8rCHZL2gh9BbMU95K4JuFWvzMuzMoLyoNBfy7FcXIXBPhjcVN5Q5dB5kN2FsF9ECT+62k9toCAv/2NFTSB3iYROrSBgL+8lDI0gv4HLrpWENNPoUx6agVBTjUrqwDJ59qUbAcN9DnMt4PgkeHMQjqAYgLPoQPIWkixB8ghwLYHyM9gcQ/QIADJHiCmAbkeoGkDUmLbFrrTKdbV3AJqDWLjtvGw07nOhRMF28dHAnCfqHGQqgcoRAi5B+hhDqM9QM9cRFkH0Ox/MkAzaFoMVHj7HJTml96aQepnD53m2lMQedWSZyf2OSjMr8udNo2gdyqKsg00vo9YL0gTSP36JrsW0PhXRXo6NYDQx/GB1H1QEOYzdw93QYavJOa4uQlSsXwVWEmyENT07V7Dz3P3bt2/0ddI03XOSN1esRSucpjYPTmCvkga+UEB4qm75B+Kj2sKW38dTaVpWZ4rX1CIKt+oCNayxijP49nhM8zzaZnLUI03iaOxiGJK27rXKCNpdkfa8xdeoh5WpazR9kHNBCsEvnivZzIuXMkwsKdtZvTO5oXb4Y4iiZcqc708m5gRDhO0NVLz2PEP5t0Y2L20YDMAAAAASUVORK5CYII=) no-repeat center center/cover;
        width: 67px;
        height: 67px;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        pointer-events: none;
        opacity: 0; }
      .four-list li a .top-img img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1; }
    .four-list li a .ti {
      font-size: 1.625rem;
      line-height: 1.85;
      letter-spacing: 0px;
      font-weight: 500;
      color: #0073d8;
      padding: 0 16px; }
      @media (max-width: 767px) {
        .four-list li a .ti {
          padding: 0;
          margin-top: 15px; } }
    @media (min-width: 768px) {
      .four-list li a:hover .top-img::before {
        opacity: .5; }
      .four-list li a:hover .top-img::after {
        opacity: 1; } }
  .four-list .editor_Content {
    font-size: 0.875rem;
    line-height: 2.14;
    letter-spacing: 0.28px;
    font-weight: 300;
    color: #6d6d6d;
    padding: 0 16px; }
    @media (max-width: 767px) {
      .four-list .editor_Content {
        padding: 0;
        margin-top: 5px; } }
  .four-list .editor_Content ul {
    padding-left: 0px;
    list-style: none;
    margin-left: 5px; }
    .four-list .editor_Content ul li {
      position: relative;
      padding-left: 10px; }
      .four-list .editor_Content ul li::before {
        position: absolute;
        content: '';
        left: 0;
        top: 14px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #6d6d6d; }

.sh-banner1 {
  height: 460px;
  width: 100%;
  position: relative; }
  @media (max-width: 991px) {
    .sh-banner1 {
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(71%, #006de7), color-stop(89%, #21a0b3), color-stop(109%, #01dba5));
      background-image: -webkit-linear-gradient(top, #006de7 71%, #21a0b3 89%, #01dba5 109%);
      background-image: -o-linear-gradient(top, #006de7 71%, #21a0b3 89%, #01dba5 109%);
      background-image: linear-gradient(180deg, #006de7 71%, #21a0b3 89%, #01dba5 109%);
      padding-bottom: 50px; } }
  @media (max-width: 768px) {
    .sh-banner1 {
      height: auto; } }
  @media (max-width: 540px) {
    .sh-banner1 {
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(54%, #006de7), color-stop(89%, #21a0b3), color-stop(109%, #01dba5));
      background-image: -webkit-linear-gradient(top, #006de7 54%, #21a0b3 89%, #01dba5 109%);
      background-image: -o-linear-gradient(top, #006de7 54%, #21a0b3 89%, #01dba5 109%);
      background-image: linear-gradient(180deg, #006de7 54%, #21a0b3 89%, #01dba5 109%); } }
  @media (max-width: 390px) {
    .sh-banner1 {
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(39%, #006de7), color-stop(82%, #21a0b3), color-stop(115%, #01dba5));
      background-image: -webkit-linear-gradient(top, #006de7 39%, #21a0b3 82%, #01dba5 115%);
      background-image: -o-linear-gradient(top, #006de7 39%, #21a0b3 82%, #01dba5 115%);
      background-image: linear-gradient(180deg, #006de7 39%, #21a0b3 82%, #01dba5 115%); } }
  .sh-banner1 .bgcover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
  .sh-banner1 .bgcover.pc {
    display: block;
    width: 100%;
    height: 460px;
    background-size: cover !important; }
    @media (max-width: 767px) {
      .sh-banner1 .bgcover.pc {
        display: none; } }
  .sh-banner1 .bgcover.mo {
    display: none;
    padding-bottom: 83.9%;
    background-size: cover !important;
    z-index: 0; }
    @media (min-width: 768px) {
      .sh-banner1 .bgcover.mo {
        display: none; } }
  .sh-banner1 .center-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 1054px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media (max-width: 768px) {
      .sh-banner1 .center-bx {
        display: block;
        top: auto;
        -webkit-transform: none;
            -ms-transform: none;
                transform: none;
        padding: 0 25px;
        padding-top: 8%; } }
    .sh-banner1 .center-bx .r-img {
      -webkit-box-ordinal-group: 3;
      -webkit-order: 2;
          -ms-flex-order: 2;
              order: 2;
      min-width: 395px;
      max-width: 395px;
      margin-left: auto; }
      @media (max-width: 991px) {
        .sh-banner1 .center-bx .r-img {
          min-width: 39%; } }
      @media (max-width: 768px) {
        .sh-banner1 .center-bx .r-img {
          max-width: 100%;
          margin: 0 auto;
          text-align: center; } }
    .sh-banner1 .center-bx .l-bx {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1;
      margin-right: 4%;
      max-width: calc(96% - 395px); }
      @media (max-width: 991px) {
        .sh-banner1 .center-bx .l-bx {
          max-width: 581px;
          margin: 0 auto; } }
      @media (max-width: 768px) {
        .sh-banner1 .center-bx .l-bx {
          max-width: 100%;
          margin-right: 0; } }
      .sh-banner1 .center-bx .l-bx .ti {
        font-size: 3.75rem;
        line-height: 1;
        letter-spacing: 1.5px;
        font-weight: 500;
        color: #ffffff;
        height: 71px;
        overflow: hidden;
        text-shadow: #424141 1px 0 4px; }
        @media (max-width: 991px) {
          .sh-banner1 .center-bx .l-bx .ti {
            max-height: initial;
            font-size: 2.5rem;
            line-height: 1.2;
            height: 53px; } }
      .sh-banner1 .center-bx .l-bx .des {
        font-size: 0.875rem;
        line-height: 1.71;
        letter-spacing: 0.35px;
        font-weight: 300;
        color: #ffffff;
        max-height: 235px;
        overflow: hidden;
        margin-top: 15px;
        text-shadow: #424141 1px 0 4px;
        text-align: justify; }
        @media (max-width: 991px) {
          .sh-banner1 .center-bx .l-bx .des {
            max-height: initial;
            margin-top: 20px;
            line-height: 1.9; } }
  .sh-banner1 .b-wave {
    position: absolute;
    bottom: -1px;
    width: 100%; }
    .sh-banner1 .b-wave img {
      width: 100%; }

.sh-banner2 {
  height: 254px;
  width: 100%;
  position: relative; }
  @media (max-width: 767px) {
    .sh-banner2 {
      padding-bottom: 52%;
      height: 0; } }
  .sh-banner2 .bgcover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
  .sh-banner2 .bgcover.pc {
    display: block;
    width: 100%;
    height: 254px;
    background-size: cover !important; }
    @media (max-width: 767px) {
      .sh-banner2 .bgcover.pc {
        display: none; } }
  .sh-banner2 .bgcover.mo {
    display: block;
    padding-bottom: 52%;
    background-size: cover !important;
    z-index: 0; }
    @media (min-width: 768px) {
      .sh-banner2 .bgcover.mo {
        display: none; } }
  .sh-banner2 .center-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 1054px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100%; }
    @media (max-width: 768px) {
      .sh-banner2 .center-bx {
        position: absolute;
        display: block; } }
    .sh-banner2 .center-bx .b-ti {
      font-size: 3.75rem;
      line-height: 1;
      letter-spacing: 1.5px;
      font-weight: 500;
      color: #ffffff; }
      @media (max-width: 768px) {
        .sh-banner2 .center-bx .b-ti {
          font-size: 2.5rem; } }
  .sh-banner2 .b-wave {
    position: absolute;
    bottom: -1px;
    width: 100%; }
    .sh-banner2 .b-wave img {
      width: 100%; }

.page {
  text-align: center;
  font-size: 1rem;
  font-family: "Noto Sans TC", "微軟正黑體", Verdana, sans-serif;
  margin-bottom: 70px; }
  @media (max-width: 1200px) {
    .page {
      text-align: center; } }
  @media (max-width: 767px) {
    .page {
      margin-bottom: 55px;
      margin-top: 15px; } }
  @media (max-width: 567px) {
    .page {
      position: relative; } }
  .page dd a {
    color: #6d6d6d;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    position: relative; }
    @media (min-width: 768px) {
      .page dd a:hover {
        color: #0073d8; }
        .page dd a:hover::before {
          opacity: .2; } }
    .page dd a::before {
      position: absolute;
      content: '';
      width: 15px;
      height: 25px;
      background-color: #0073d8;
      opacity: 0;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      z-index: -1;
      border-radius: 5px 0 5px 0;
      -webkit-transform: skewX(-32deg);
          -ms-transform: skewX(-32deg);
              transform: skewX(-32deg);
      -webkit-transform-origin: bottom;
          -ms-transform-origin: bottom;
              transform-origin: bottom;
      top: 8px;
      left: 18%; }
  .page dd {
    width: 40px; }
    @media (max-width: 767px) {
      .page dd {
        width: 13%; } }
    @media (max-width: 370px) {
      .page dd {
        width: 9%; } }
  .page .active a {
    color: #0073d8 !important;
    font-weight: 400 !important; }
    .page .active a::before {
      opacity: .2 !important; }
    @media (min-width: 768px) {
      .page .active a:hover {
        color: #0073d8 !important; } }

.page .ltbn {
  margin-right: 10px; }
  @media (max-width: 1200px) {
    .page .ltbn {
      margin-right: 0; } }
  @media (max-width: 567px) {
    .page .ltbn {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      left: 0px; } }

.page .rtbn {
  margin-left: 5px; }
  @media (max-width: 1200px) {
    .page .rtbn {
      margin-right: 0; } }

@media (min-width: 992px) {
  .page .nopage.rtbn a:hover::before {
    right: 7px; }
  .page .nopage.rtbn a:hover::after {
    left: 0; } }

@media (min-width: 992px) {
  .page .nopage.ltbn a:hover::before {
    left: 7px; }
  .page .nopage.ltbn a:hover::after {
    right: 0; } }

.page dt.ltbn a {
  border-radius: 0;
  background: none;
  height: 40px;
  width: 40px;
  opacity: 1;
  position: relative;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  top: 4px; }
  @media (min-width: 992px) {
    .page dt.ltbn a:hover::before {
      left: -5%; }
    .page dt.ltbn a:hover::after {
      right: 22%; } }
  @media (max-width: 1200px) {
    .page dt.ltbn a {
      margin-right: 0; } }
  .page dt.ltbn a::before {
    border: none;
    width: 8px;
    height: 1px;
    background: #0073d8;
    position: absolute;
    top: 17px;
    left: 7px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    -webkit-transform: rotate(-42deg);
        -ms-transform: rotate(-42deg);
            transform: rotate(-42deg); }
  .page dt.ltbn a::after {
    position: absolute;
    content: '';
    bottom: 19px;
    right: 0;
    width: 80%;
    height: 1px;
    background: #0073d8;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }

.page dt.rtbn a {
  border-radius: 0;
  background: none;
  height: 40px;
  width: 40px;
  opacity: 1;
  position: relative;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  margin-left: 0;
  top: 4px; }
  @media (min-width: 992px) {
    .page dt.rtbn a:hover::before {
      right: -5%; }
    .page dt.rtbn a:hover::after {
      left: 20%; } }
  @media (max-width: 567px) {
    .page dt.rtbn a {
      position: absolute;
      top: 62%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      right: 0px; } }
  .page dt.rtbn a::before {
    border: none;
    width: 8px;
    height: 1px;
    background: #0073d8;
    position: absolute;
    top: 17px;
    right: 7px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    -webkit-transform: rotate(42deg);
        -ms-transform: rotate(42deg);
            transform: rotate(42deg); }
  .page dt.rtbn a::after {
    position: absolute;
    content: '';
    bottom: 19px;
    left: 0;
    width: 80%;
    height: 1px;
    background: #0073d8;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }

.tag {
  display: inline-block;
  padding: 10px;
  border: 1px solid #0073d8;
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.28px;
  font-weight: 300;
  color: #0073d8;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  background-color: #ffffff; }
  @media (min-width: 768px) {
    .tag:hover {
      background-color: #0073d8;
      color: #ffffff; } }
  @media (max-width: 767px) {
    .tag {
      border-radius: 4px;
      padding: 8px; } }

#chat-application {
  right: auto;
  left: 35px; }
  @media (max-width: 767px) {
    #chat-application {
      left: 15px;
      bottom: -10px !important; } }
