/// 需要推的padding-bottom以及預設圖用當前頁指定到此calss
.bgcover{
    width: 100%;
    height: 0;
    background: 50%/cover;
}
.mo{
    display:none;
    @media (max-width:767px) {
        display: block;
    }
}
.pc{
    display: none;
    @media (min-width:768px) {
        display: block;
    }
}
.container{
    padding: 0 33px;
    margin: 0 auto;
    width: 100%;
    @media (max-width:767px) {
        padding: 0 25px;
    }
    @media (min-width:576px) {
        max-width: 540px;
    }
    @media (min-width:768px) {
        max-width: 720px;
    }
    @media (min-width:992px) {
        max-width: 960px;
    }
    @media (min-width:1200px) {
        max-width: 1088px;
    }
}

#top-menu-ul{
   
    @media (max-width:767px) {
        margin:0 auto 35px;
    }
    @media (min-width:768px) {
        margin:0 auto 50px;
    }
    
    position: relative;
    z-index: 1;
    .item_Menu{
        margin: 0 auto;   
        overflow: hidden;    
        height: 30px;
    }
    .item_menu_Box{    
        width:100%;
        overflow:hidden;
        overflow-x: scroll;
        position: relative;
        text-align:center;
        .item_menu_list{
            white-space: nowrap !important;
            padding-bottom: 0px;
            text-align: center;
            font-size:0px;
            display:inline-flex; 
        }

        li{
            height: 30px;
        }
        li a{
            position: relative;
            display: block;
            line-height: 1;
            letter-spacing: 0.6px;
            border-bottom: 1px solid #dbdbdb;
            font-size: pxToEm(14);
            color: #000000;
            @media (max-width:767px) {
                padding:7px 0 3px;
                margin:0 20px 0 0; 
            }
            @media (min-width:768px) {
                margin:0 10px; 
                padding:7px 0;
            }
          
        }
        li.active a{
            border-color:black;
            color:black;
        }
    }
}
.flex-direction-nav{
    display:none;
    a{
        display:block;
        width:40px;   
        height: 40px;
        position: absolute;        
        transform: translateY(-50%);
        top:50%;
        z-index: 10;
        background-color: #000000;
        &:after{
            position: absolute;
            top: 0;
        }
    }
    a.flex-next{
        // background: url(../svg/slick-next.svg) no-repeat center / 15px;
        right: -18px;
    }

    a.flex-prev{
        // background: url(../svg/slick-prev.svg) no-repeat center / 15px;
        left:  -18px;
    }
    }
    .open_flexslider{
    .flex-direction-nav{
        display: block;
    }
}

.sh-btn1{
    border-radius: 15px 0 15px 0 ;
    border: 1px solid $hover;
    width: 162px;
    height: 49px;
    text-align: center;
    display: block;
    transition: .4s;
    div{
        @include fontset(16,2.9,.32,500,$hover);
        transition: .4s;
    }
    @media (min-width:768px) {
        &:hover{
            background-color: $hover;
            div{
                color: #ffffff;
            }
        }
    }
    @media (max-width:991px) {
        border-radius: 7px 0 7px 0 ;
        width: 156px;
        height: 45px;
        div{
            font-size: pxToEm(15);
        }
    }
}
.tit-blsq{
 
    div{
        position: relative;
        @include fontset(40,2,.8,500,$hover);
        display: inline-block;
    }
    div:before{
        position: absolute;
        content: '';
        top: 10px;
        left: -23px;
        transform-origin:bottom;
        transform:skewX(-32deg);
        background-color: $hover;
        opacity: .2;
        border-radius: 5px 0 5px 0 ;
        width: 34px;
        height: 48px;
    }
}
.sh-mbtn{
    position: relative;
    @include fontset(16,2,.8,500,#ffffff);
    display: inline-block;
    padding-right: 96px;
    transition: .4s;
    
    @media (min-width:768px) {
        &:hover{
            padding-right: 110px;
            &::before{
                // width: 44.5%;
            }
        }
    }
    &::before{
        position: absolute;
        content: '';
        right: 0;
        bottom: 7px;
        width: 80px;
        height: 1px;
        background-color: #ffffff;
        transition: .4s;
    }
    &::after{
        position: absolute;
        content: '';
        right: -1px;
        bottom: 11px;
        width: 11px;
        height: 1px;
        background-color: #ffffff;
        transform: rotate(45deg);
        @media (max-width:767px) {
            bottom: 10px;
            width: 8px;
        }
    }
}
.four-list>li{
    width: 25%;
    margin-bottom: 100px;
    flex-wrap: wrap;
    @media (max-width:991px) and (min-width:768px) {
        width: 50%;
    }
    @media (max-width:767px) {
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 25px;
    }
    @media (max-width:767px) {
        &:not(:last-child){
            border-bottom: 1px solid #d2d2d2;
        }
    }
}
.four-list{
    display: flex;
    flex-wrap: wrap;
    li{
        @media (min-width:992px) {
            &:not(:nth-child(4n)){
                a .top-img{
                    border-right: 1px solid #d2d2d2;
                }
            }
            &:last-child{
                a .top-img{
                    border-right:none;
                }
            }
        }
        @media (max-width:991px) and (min-width:768px) {
            &:not(:nth-child(2n)){
                a .top-img{
                    border-right: 1px solid #d2d2d2;
                }
            }
            &:last-child{
                a .top-img{
                    border-right: none;
                }
            }
        }
       
       
        a{
            display: block;
          
            .top-img{
                position: relative;
                padding: 0 16px;
                padding-bottom: 100%;
                @media (max-width:767px) {
                }
                &::before{
                    position: absolute;
                    content: '';
                    width: calc(100% - 32px);
                    height: 100%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    background-color: $hover;
                    pointer-events: none;
                    opacity: 0;
                    transition: .4s;
                    border-radius: 15px 0 15px 0;
                    
                }
                &::after{
                    position: absolute;
                    content: '';
                    background: url(../images/b_bt.png) no-repeat center center / cover;
                    width: 67px;
                    height: 67px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    transition: .2s ease;
                    pointer-events: none;
                    opacity: 0;
                }
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }
            }
            .ti{
                @include fontset(26,1.85,0,500,$hover);
                padding: 0 16px;
                @media (max-width:767px) {
                    padding: 0;
                    margin-top: 15px;
                }
            }
            @media (min-width:768px) {
                &:hover{
                    .top-img::before{
                        opacity: .5;
                    }
                    .top-img::after{
                        opacity: 1;
                    }
                }
            }
        }
    }
    .editor_Content{
        @include fontset(14,2.14,.28,300,#6d6d6d);
        padding: 0 16px;
        @media (max-width:767px) {
            padding: 0;
            margin-top: 5px;
        }
    }
    .editor_Content ul{
        padding-left: 0px;
        list-style:none;
        margin-left: 5px;
        li{
            position: relative;
            padding-left: 10px;
            &::before{
                position: absolute;
                content: '';
                left: 0;
                top: 14px;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: #6d6d6d;
            }
        }
    }
}

.sh-banner1{
    height: 460px;
    width: 100%;
    position: relative;
    @media (max-width:991px) {
        background-image: linear-gradient(180deg,#006de7 71%,#21a0b3 89%,#01dba5 109%);
        padding-bottom: 50px;
    }
    @media (max-width:768px) {
        height: auto;
    }
    @media (max-width:540px) {
        background-image: linear-gradient(180deg,#006de7 54%,#21a0b3 89%,#01dba5 109%);
    }
    @media (max-width:390px) {
        background-image: linear-gradient(180deg,#006de7 39%,#21a0b3 82%,#01dba5 115%);
    }
    .bgcover{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .bgcover.pc{
        display: block;
        width: 100%;
        height: 460px;
        background-size: cover !important;
        @media (max-width:767px) {
            display: none;
        }
    }
    .bgcover.mo{
        display: none;
        padding-bottom: 83.9%;
        background-size: cover !important;
        z-index: 0;
        @media (min-width:768px) {
            display: none;
        }
    }
    .center-bx{
        display: flex;
        align-items: center;
        max-width: 1054px;
        padding: 0 15px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width:768px) {
            display: block;
            top: auto;
            transform: none;
            padding: 0 25px;
            padding-top: 8%;
        }
        .r-img{
            order: 2;
            min-width: 395px;
            max-width: 395px;
            margin-left: auto;
            @media (max-width:991px) {
                min-width:  39%;
            }
            @media (max-width:768px) {
                max-width: 100%;
                margin: 0 auto;
                text-align: center;
            }
        }
        .l-bx{
            order: 1;
            margin-right: 4%;
            max-width: calc(96% - 395px);
            @media (max-width:991px) {
                max-width: 581px;
                margin: 0 auto;
            }
            @media (max-width:768px) {
                max-width: 100%;
                margin-right: 0;
            }
            .ti{
                @include fontset(60,1,1.5,500,#ffffff);
                height: 71px;
                overflow: hidden;
                text-shadow: #424141 1px 0 4px;
                // text-align: justify;
                @media (max-width:991px) {
                    max-height: initial;
                    font-size: pxToEm(40);
                    line-height: 1.2;
                    height: 53px;
                }
            }
            .des{
                @include fontset(14,1.71,.35,300,#ffffff);
                max-height: 235px;
                overflow: hidden;
                margin-top: 15px;
                text-shadow: #424141 1px 0 4px;
                text-align: justify;
                @media (max-width:991px) {
                    max-height: initial;
                    margin-top: 20px;
                    line-height: 1.9;
                }
            }
        }
    }
    .b-wave{
        position: absolute;
        bottom: -1px;
        width: 100%;
        img{
            width: 100%;
        }
    }
}


.sh-banner2{
    height: 254px;
    width: 100%;
    position: relative;
    @media (max-width:767px) {
        padding-bottom: 52%;
        height: 0;
    }
    .bgcover{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .bgcover.pc{
        display: block;
        width: 100%;
        height: 254px;
        background-size: cover !important;
        @media (max-width:767px) {
            display: none;
        }
    }
    .bgcover.mo{
        display: block;
        padding-bottom: 52%;
        background-size: cover !important;
        z-index: 0;
        @media (min-width:768px) {
            display: none;
        }
    }
    .center-bx{
        display: flex;
        align-items: center;
        max-width: 1054px;
        padding: 0 15px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        @media (max-width:768px) {
            position: absolute;
            display: block;
        }
       .b-ti{
           @include fontset(60,1,1.5,500,#ffffff);
           @media (max-width:768px) {
               font-size: pxToEm(40);
           }
       }
    }
    .b-wave{
        position: absolute;
        bottom: -1px;
        width: 100%;
        img{
            width: 100%;
        }
    }
}

// pagelist

.page{
    text-align: center;
    font-size: pxToEm(16);
    font-family: $font_content;
    margin-bottom: 70px;
    @media(max-width:1200px){
        text-align: center;
    }
    @media(max-width:767px){
        margin-bottom: 55px;
        margin-top: 15px;
    }
    @media(max-width:567px){
        position: relative;

    }
    dd a{
        color: #6d6d6d;
        transition: .4s;
        position: relative;
        @media (min-width:768px) {
            &:hover{
                color: $hover;
                &::before{
                    opacity: .2;
                }
            }    
        }
        &::before{
            position: absolute;
            content: '';
            width: 15px;
            height: 25px;
            background-color: $hover;
            opacity: 0;
            transition: .4s;
            z-index: -1;
            border-radius: 5px 0 5px 0;
            transform: skewX(-32deg);
            transform-origin: bottom;
            top: 8px;
            left: 18%;
        }
        
    }
    dd{
        width: 40px;
        @media(max-width:767px){
            width: 13%;
        }
        @media(max-width:370px){
            width: 9%;
        }
        
    }
    .active a{
        color: $hover !important;
        font-weight: 400 !important;
        &::before{
            opacity: .2 !important;
        }
        @media (min-width:768px) {
            &:hover{
                color: $hover !important;
            }    
        }
    }
    


}
.page .ltbn{
    margin-right: 10px;
    @media (max-width:1200px) {
        margin-right: 0;
    }
    @media(max-width:567px){
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
    }
    
}
.page .rtbn{
    margin-left: 5px;
    @media (max-width:1200px) {
        margin-right: 0;
    }
}
.page .nopage.rtbn a{
    @media (min-width:992px) {
        &:hover{
            &::before{
                right: 7px;
            }
            &::after{
                left: 0;
            }
        }    
    }
}
.page .nopage.ltbn a{
    @media (min-width:992px) {
        &:hover{
            &::before{
                left: 7px;
            }
            &::after{
                right: 0;
            }
        }    
    }
}
.page dt.ltbn a{
    border-radius: 0;
    background: none;
    height: 40px;
    width: 40px;
    opacity: 1;
    position: relative;
    transition: .4s;
    top: 4px;
    @media (min-width:992px) {
        &:hover{
           &::before{
            left: -5%;
           }
           &::after{
            right: 22%;
           }
        }    
    }
    @media (max-width:1200px) {
        margin-right: 0;
    }
    
    &::before{
        border: none;
        width: 8px;
        height: 1px;
        background: $hover;
        position: absolute;
        top: 17px;
        left:  7px;
        transition: .4s;
        transform: rotate(-42deg);
    }
    &::after{
        position: absolute;
        content: '';
        bottom: 19px;
        right:0;
        width: 80%;
        height: 1px;
        background: $hover;
        transition: .4s;
    }
}
.page dt.rtbn a{
    border-radius: 0;
    background: none;
    height: 40px;
    width: 40px;
    opacity: 1;
    position: relative;
    transition: .4s;
    margin-left: 0;
    top: 4px;
    @media (min-width:992px) {
        &:hover{
           &::before{
            right: -5%;
           }
           &::after{
            left: 20%;
           }
        }    
    }
    @media(max-width:567px){
        position: absolute;
        top: 62%;
        transform: translateY(-50%);
        right: 0px;
    }
    

    &::before{
        border: none;
        width: 8px;
        height: 1px;
        background: $hover;
        position: absolute;
        top: 17px;
        right: 7px;
        transition: .4s;
        transform: rotate(42deg);
    }
    &::after{
        position: absolute;
        content: '';
        bottom: 19px;
        left:0;
        width: 80%;
        height: 1px;
        background: $hover;
        transition: .4s;
    }
    
}

.tag{
    display: inline-block;
    padding: 10px;
    border: 1px solid $hover;
    border-radius: 8px;
    @include fontset(14,1,.28,300,$hover);
    transition: .4s;
    background-color: #ffffff;
    @media (min-width:768px) {
        &:hover{
            background-color: $hover;
            color: #ffffff;
        }
    }
    @media (max-width:767px) {
        border-radius: 4px;
        padding: 8px;
    }
}

#chat-application{
    right: auto;
    left: 35px;
    @media (max-width:767px) {
        left: 15px;
        bottom: -10px !important;
    }

}