.scalistPage{
    main{
       
        @media (max-width:991px) {
            padding-bottom: 95px;
        }
    }
    .item1{
        margin-top: 85px;
        margin-bottom: 75px;
        @media (max-width:991px) {
            margin-top: 55px;
            margin-bottom: 25px;
        }
        .Breadcrumb{
            ul{
                display: flex;
                li{
                    @include fontset(16,2.25,0,500,#6d6d6d);
                    display: flex;
                    align-items: center;
                    &:last-child{
                        color: $hover;
                    }
                    &:not(:last-child){
                        margin-right: 15px;
                    }
                    @media (max-width:767px) {
                        line-height: 1.25;
                    }
                }
                span{
                    margin-right: 15px;
                    position: relative;
                    top: -1px;
                }
            }
        }
    }
    .item2{
        .it2-bx{
            @media (min-width:1200px) {
                max-width: 1120px;
            }
        }
    }
}
.scadetailPage{
    .item1{
        margin-top: 70px;
        margin-bottom: 65px;
        @media (max-width:991px) {
            margin-top: 30px;
            margin-bottom: 25px;
        }
        .Breadcrumb{
            ul{
                display: flex;
                li{
                    @include fontset(16,2.25,0,500,#6d6d6d);
                    display: flex;
                    align-items: center;
                    &:last-child{
                        color: $hover;
                    }
                    &:not(:last-child){
                        margin-right: 15px;
                    }
                    @media (max-width:767px) {
                        line-height: 1.25;
                    }
                    a{
                        @include fontset(16,2.25,0,500,#6d6d6d);
                        position: relative;
                        @media (max-width:767px) {
                            line-height: 1.25;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            bottom: -5px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: #6d6d6d;
                            opacity: 0;
                            transition: .4s ease;
                        }
                        @media (min-width:768px) {
                            &:hover{
                                &::before{
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
                span{
                    margin-right: 15px;
                    position: relative;
                    top: -1px;
                }
            }
        }
    }
    .item2{
        .it2-bx{
            display: flex;
            @media (max-width:991px) {
                display: block;
                margin-bottom: 65px;
            }
            .l-bx{
                max-width: 495px;
                width: 100%;
                margin-right: 30px;
                @media (max-width:991px) {
                    margin: 0 auto;
                }
            }
            .r-bx{
                width: calc(100% - 525px);
                @media (max-width:991px) {
                    width: 100%;
                }
                .ti{
                    position: relative;
                    @include fontset(26,1.3,0,500,$hover);
                    margin-bottom: 50px;
                    @media (max-width:991px) {
                        margin-top: 40px;
                        margin-bottom: 35px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        top: -5px;
                        left: -16px;
                        transform-origin:bottom;
                        transform:skewX(-32deg);
                        background-color: $hover;
                        opacity: .2;
                        border-radius: 5px 0 5px 0 ;
                        width: 19px;
                        height: 32px;
                        @media (max-width:991px) {
                            border-radius: 2.5px 0 2.5px 0;
                            width: 19px;
                            height: 29px;
                            top: 0px;
                            left: -17px;
                        }
                    }
                }
                .des-ti{
                    @include fontset(18,1,0,300,$hover);
                    padding-bottom: 15px;
                    border-bottom: 1px solid $hover;
                    margin-bottom: 10px;
                    @media (max-width:991px) {
                        margin-bottom: 15px;
                    }
                }
                .editor_Content{
                    @include fontset(16,1.88,.32,300,#6d6d6d);
                    @media (max-width:767px) {
                        padding: 0;
                    }
                }
                .editor_Content ul{
                    padding-left: 0px;
                    list-style:none;
                    margin-left: 5px;
                    li{
                        position: relative;
                        padding-left: 10px;
                        &::before{
                            position: absolute;
                            content: '';
                            left: 0;
                            top: 14px;
                            width: 3px;
                            height: 3px;
                            border-radius: 50%;
                            background-color: #6d6d6d;
                        }
                    }
                }
                .down-bx{
                        margin-top: 38px;
                        @media (max-width:991px) {
                            margin-top: 40px;
                        }
                    ul{
                        li{
                            display: flex;
                            justify-content: space-between;
                            padding: 12px 0 ;
                            align-items: center;
                            @media (max-width:991px) {
                                padding: 8px 0;
                            }
                            &:not(:last-child){
                                border-bottom: 1px solid #dcdcdc;
                            }
                            div{
                                @include fontset(18,2.67,0,500,#6d6d6d);
                            }
                            a{
                                display: block;
                                width: 40px;
                                height: 40px;
                                position: relative;
                                @media (max-width:991px) {
                                    width: 36px;
                                    height: 36px;
                                }
                                &::before{
                                    position: absolute;
                                    content: '';
                                    @include position(50%,50%,-50%,-50%);
                                    width: 48px;
                                    height: 48px;
                                    border: 1px solid $hover;
                                    border-radius: 50%;
                                    opacity: 0;
                                    transition: .4s;
                                }
                                @media (min-width:992px) {
                                    &:hover{
                                        &::before{
                                            opacity: 1;
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .item3{
        .des-ti{
            @include fontset(18,1,0,300,$hover);
            margin-top: 30px;
            padding-bottom: 15px;
            border-bottom: 1px solid $hover;
            text-align: left;
            @media (max-width:991px) {
                margin-top: 0;
            }
        }
        .it3-bx{
            .editor_Content{
                // @include fontset(16,1.88,.32,300,#6d6d6d);
                margin-top: 40px;
                margin-bottom: 45px;
                @media (max-width:767px) {
                    padding: 0;
                }
            }
            // .editor_Content ul{
            //     padding-left: 0px;
            //     list-style:none;
            //     margin-left: 5px;
            //     li{
            //         position: relative;
            //         padding-left: 10px;
            //         &::before{
            //             position: absolute;
            //             content: '';
            //             left: 0;
            //             top: 14px;
            //             width: 3px;
            //             height: 3px;
            //             border-radius: 50%;
            //             background-color: #6d6d6d;
            //         }
            //     }
            // }
            .sh-btn1{
                margin: 0 auto;
            }
            .it3-obx{
                padding-bottom: 40px;
                margin-bottom: 20px;
                border-bottom: 1px solid $hover;
                @media (max-width:991px) {
                    margin-bottom: 45px;
                }
            }
            .sh-mbtn{
                color: $hover;
                &::before,&::after{
                    background-color: $hover;
                }
            }
        }
    }
    .item4{
        margin-top: 60px;
        @media (max-width:991px) {
            margin-top: 70px;
            margin-bottom: 15px;
        }
        .re-ti{
            @include fontset(26,1.38,.65,500,$hover);
            text-align: center;
            margin-bottom: 35px;
        }
        .thr-list>li{
            width: 33.33333%;
            margin-bottom: 100px;
            flex-wrap: wrap;
            &:not(:last-child){
                // border-right: 1px solid #d2d2d2;
            }
            @media (max-width:991px) and (min-width:768px) {
                // width: 50%;
            }
            @media (max-width:767px) {
                width: 100%;
                padding-bottom: 15px;
                margin-bottom: 25px;
            }
            @media (max-width:767px) {
                &:not(:last-child){
                    border-bottom: 1px solid #d2d2d2;
                }
            }
        }
        .thr-list{
            display: flex;
            // align-items: center;
            flex-wrap: wrap;
            li{
                @media (min-width:992px) {
                    &:not(:last-child){
                        a .top-img{
                            border-right:1px solid #d2d2d2;
                        }
                    }
                }
                @media (max-width:991px) and (min-width:768px) {
                    &:not(:nth-child(3n)){
                        a .top-img{
                            border-right: 1px solid #d2d2d2;
                        }
                    }
                    &:last-child{
                        a .top-img{
                            border-right: none;
                        }
                    }
                }
               
               
                a{
                    display: block;
                  
                    .top-img{
                        position: relative;
                        padding: 0 16px;
                        margin-bottom: 5px;
                        padding-bottom: 100%;
                        @media (max-width:767px) {
                            margin-bottom: 0px;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            width: calc(100% - 32px);
                            height: 100%;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            background-color: $hover;
                            pointer-events: none;
                            opacity: 0;
                            transition: .4s;
                            border-radius: 15px 0 15px 0;
                            
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            background: url(../images/b_bt.png) no-repeat center center / cover;
                            width: 67px;
                            height: 67px;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            transition: .2s ease;
                            pointer-events: none;
                            opacity: 0;
                        }
                        img{
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: -1;
                        }
                    }
                    .ti{
                        @include fontset(26,1.85,0,500,$hover);
                        padding: 0 16px;
                        @media (max-width:767px) {
                            padding: 0;
                        }
                    }
                    @media (min-width:768px) {
                        &:hover{
                            .top-img::before{
                                opacity: .5;
                            }
                            .top-img::after{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .editor_Content{
                @include fontset(14,2.14,.28,300,#6d6d6d);
                padding: 0 16px;
                @media (max-width:767px) {
                    padding: 0;
                }
            }
            .editor_Content ul{
                padding-left: 0px;
                list-style:none;
                margin-left: 5px;
                li{
                    position: relative;
                    padding-left: 10px;
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 14px;
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        background-color: #6d6d6d;
                    }
                }
            }
        }
    }
}

.rulePage{
    .item1{
        margin-top: 70px;
        margin-bottom: 90px;
        @media (max-width:767px) {
            margin-top: 60px;
            margin-bottom: 70px;
        }
    }
}

.aboutPage{
    .sh-banner1{
        height: 370px;
        @media (max-width:991px) {
            background-image:none;
        }
        @media (max-width:768px) {
            height: 0;
            padding-bottom: 84.3%;
        }
        .bgcover.pc{
            height: 370px;
        }
        .bgcover.mo{
            @media (max-width:767px) {
                padding-bottom: 84.3%;
            }
        }
        .center-bx{
            @media (max-width:991px) {
                display: flex;
                padding-top: 7%;
                justify-content: center;
            }
            @media (max-width:767px) {
                display: block;
                padding-top: 10%;
            }
        }
        .center-bx .r-img{
            @media (max-width:991px) {
               max-width: calc(100% - 381px);
            }
            @media (max-width:768px) {
                max-width: 65%;
            }
        }
        .center-bx .l-bx{
            margin-right: 13%;
            @media (max-width:991px) {
                margin-right: 0;
                margin: 0;
            }
        }
        .center-bx .l-bx .des{
            font-size: pxToEm(20);
            @media (max-width:768px) {
                margin-top: 0;
                font-size: pxToEm(16);
                margin-top: 15px;
            }
        }
    }
    .item1{
        margin-top: 90px;
        margin-bottom: 130px;
        @media (max-width:991px) {
            margin-top: 85px;
            margin-bottom: 60px;
        }
    }
    
}