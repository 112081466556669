@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
/* > reset */
/******************************************/
/*		slick
/******************************************/
/* Slider */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		mCustomScrollbar
/******************************************/
/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
/******************************************/
/*		fullpage
/******************************************/
@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, textarea, p, blockquote {
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

table {
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

strong {
  font-weight: bold; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
  color: #444; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

/* Custom CSS
 * --------------------------------------- */
body {
  font-family: arial,helvetica;
  color: #333;
  color: rgba(0, 0, 0, 0.5); }

.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  position: relative; }

h1 {
  font-size: 6em; }

p {
  font-size: 2em; }

.intro p {
  width: 50%;
  margin: 0 auto;
  font-size: 1.5em; }

.section {
  text-align: center; }

#menu li {
  display: inline-block;
  margin: 10px;
  color: #000;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 10px; }

#menu li.active {
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  color: #fff; }

#menu li a {
  text-decoration: none;
  color: #000; }

#menu li.active a:hover {
  color: #000; }

#menu li:hover {
  background: rgba(255, 255, 255, 0.8); }

#menu li a,
#menu li.active a {
  padding: 9px 18px;
  display: block; }

#menu li.active a {
  color: #fff; }

#menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin: 0; }

.twitter-share-button {
  position: fixed;
  z-index: 99;
  right: 149px;
  top: 9px; }

#download {
  margin: 10px 0 0 0;
  padding: 15px 10px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -ms-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(top, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

#download a {
  text-decoration: none;
  color: #fff; }

#download:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #2F96B4;
  background-position: 0 -15px;
  -webkit-transition: background-position .1s linear;
  -moz-transition: background-position .1s linear;
  -ms-transition: background-position .1s linear;
  -o-transition: background-position .1s linear;
  transition: background-position .1s linear; }

#infoMenu {
  height: 20px;
  color: #f2f2f2;
  position: fixed;
  z-index: 70;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
  padding: 8px 0 8px 0; }

#infoMenu ul {
  padding: 0 40px; }

#infoMenu li a {
  display: block;
  margin: 0 22px 0 0;
  color: #333; }

#infoMenu li a:hover {
  text-decoration: underline; }

#infoMenu li {
  display: inline-block;
  position: relative; }

#examplesList {
  display: none;
  background: #282828;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 20px;
  float: left;
  position: absolute;
  bottom: 29px;
  right: 0;
  width: 638px;
  text-align: left; }

#examplesList ul {
  padding: 0; }

#examplesList ul li {
  display: block;
  margin: 5px 0; }

#examplesList ul li a {
  color: #BDBDBD;
  margin: 0; }

#examplesList ul li a:hover {
  color: #f2f2f2; }

#examplesList .column {
  float: left;
  margin: 0 20px 0 0; }

#examplesList h3 {
  color: #f2f2f2;
  font-size: 1.2em;
  margin: 0 0 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  padding: 0 0 5px 0; }

/* Demos Menu
 * --------------------------------------- */
#demosMenu {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

/*把變數帶入mixin中，此mixin只就單純把media queries寫到mixin中，
並且使用上面設立的變數來調動media queries的width*/
main[role="main"], section, article {
  zoom: 1; }
  main[role="main"]:before, section:before, article:before, main[role="main"]:after, section:after, article:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role="main"]:after, section:after, article:after {
    clear: both; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection {
  color: #ffffff;
  background: #0073d8; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 16px;
  color: #000000;
  word-break: break-word;
  letter-spacing: 0.1em;
  z-index: 0;
  background: #ffffff;
  -webkit-text-size-adjust: 100%;
  opacity: 0;
  transition: 1.2s;
  font-family: "Noto Sans TC"; }

body.loading {
  opacity: 1;
  transition: opacity 1.2s; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 56px;
  outline: none;
  padding: 0;
  color: #000;
  border: 1px solid #ffffff;
  font-size: 1rem;
  border-radius: 5px; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 0.9375rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    transition: .4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 218px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #666;
  line-height: 18px; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666;
  line-height: 43px; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666;
  line-height: 43px; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #666;
  line-height: 18px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type='date'],
input[type='time'] {
  -webkit-appearance: none; }

select {
  font-family: "Noto Sans TC", "微軟正黑體", Verdana, sans-serif;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

select::-ms-expand {
  display: none; }

.selectBox {
  background: #FFF; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 110px; }
  @media (max-width: 991px) {
    main[role="main"] {
      margin-top: 60px; } }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
/*----------------------tag_list------------------------*/
.tag_list a {
  color: #ffffff;
  text-decoration: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #434343;
  padding: 3px 7px;
  font-size: 0.9375rem; }
  .tag_list a:not(:last-child) {
    margin-right: 15px; }
  .tag_list a:hover {
    background: #2f3a88; }

/*----------------------編輯器------------------------*/
.editor_Content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 0;
  line-height: 1.88;
  color: #6d6d6d;
  letter-spacing: 0.4px; }
  .editor_Content header {
    width: 100%;
    padding-bottom: 31px;
    margin-bottom: 29px;
    border-bottom: solid 1px #7d7d7d;
    display: flex; }
    .editor_Content header h2 {
      width: 80%;
      font-size: 1.25rem;
      line-height: 1.4;
      color: #ffffff;
      padding-right: 2%; }
    .editor_Content header .tag_list {
      text-align: right;
      width: -moz-calc(100% - 73%);
      width: -webkit-calc(100% - 73%);
      width: calc(100% - 73%); }
  .editor_Content ul, .editor_Content ol {
    padding-left: 40px; }
  .editor_Content ol {
    list-style: decimal; }
  .editor_Content ul {
    list-style: disc; }
  .editor_Content img {
    max-width: 100%;
    height: auto !important; }
  .editor_Content b, .editor_Content strong {
    font-weight: bold; }
  .editor_Content strong {
    font-size: inherit;
    font-style: inherit; }
  .editor_Content em {
    font-style: italic; }
  .editor_Content a {
    text-decoration: underline;
    color: #0782C1; }
  .editor_Content iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Content iframe {
        height: auto !important; } }
  .editor_Content h1, .editor_Content h2, .editor_Content h3, .editor_Content h4, .editor_Content h5, .editor_Content h6 {
    margin: inherit; }
  .editor_Content blockquote {
    font-style: italic;
    padding: 2px 20px 0 8px;
    margin-left: 40px;
    border-left: 5px solid #ccc; }
  .editor_Content td, .editor_Content th, .editor_Content tr {
    margin: initial;
    padding: initial; }
  .editor_Content table {
    border-collapse: inherit; }

.editor_Box {
  margin: auto;
  line-height: 1.5;
  font-size: 0.9375rem;
  color: #6d6d6d; }
  .editor_Box ul,
  .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: auto !important; } }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------back------------------------*/
.back_btn,
.more_btn {
  width: 100%;
  margin-top: 70px;
  font-size: 1.125rem; }
  .back_btn a,
  .more_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
    .back_btn a:hover,
    .more_btn a:hover {
      background: #4759d9;
      opacity: .5; }

/*----------------------上下則------------------------*/
.seesaw {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin-top: 61px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .seesaw a {
    color: #de4242;
    text-decoration: none; }
  .seesaw .left_button:before,
  .seesaw .right_button:before,
  .seesaw .back_button {
    transition: 0.4s; }
  .seesaw .left_button,
  .seesaw .right_button {
    position: absolute; }
    .seesaw .left_button:before,
    .seesaw .right_button:before {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: #de4242;
      margin-bottom: 2px; }
    .seesaw .left_button:hover:before,
    .seesaw .right_button:hover:before {
      width: 90px; }
  .seesaw .left_button {
    right: 0; }
  .seesaw .right_button {
    left: 0;
    text-align: right; }
  .seesaw .back_button {
    font-size: 1rem;
    width: 230px;
    height: auto;
    padding: 11px 0;
    border: 1px solid #de4242;
    text-align: center; }
    .seesaw .back_button:hover {
      background: #de4242; }
      .seesaw .back_button:hover a {
        color: #ffffff; }

/*----------------------GO TOP------------------------*/
.show-topbtn.top_btn::before, .show-topbtn.top_btn::after {
  opacity: 1; }

.show-topbtn.top_btn span {
  opacity: 1; }

.top_btn {
  cursor: pointer;
  transition: .4s;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  display: block;
  padding-right: 8px;
  padding-top: 30px;
  transition: opacity,padding .4s; }
  .top_btn span {
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.5px;
    font-weight: 500;
    color: #000000;
    color: #2cd9d2;
    writing-mode: vertical-rl;
    opacity: 0;
    transition: .4s;
    transform: rotate(180deg);
    top: -6px;
    position: relative; }
    .top_btn span::before {
      position: absolute;
      content: '';
      left: -8px;
      top: 0px;
      width: 1px;
      height: 49px;
      background-color: #2cd9d2;
      transition: .4s;
      opacity: 1; }
    .top_btn span::after {
      position: absolute;
      content: '';
      left: -4px;
      width: 1px;
      bottom: -17px;
      height: 10px;
      transform: rotate(45deg);
      background-color: #2cd9d2;
      transition: .4s;
      opacity: 1; }
  @media screen and (max-width: 768px) {
    .top_btn {
      bottom: 26px; } }
  .top_btn.active {
    opacity: 1; }
  .top_btn:hover {
    color: #2cd9d2; }
  @media (min-width: 768px) {
    .top_btn:hover {
      padding-top: 45px; } }
  .top_btn.fix {
    position: absolute;
    bottom: 7px; }
    @media screen and (max-width: 768px) {
      .top_btn.fix {
        position: absolute;
        bottom: 20px; } }

/*----------------------頁數------------------------*/
.page dt.ltbn a:before, .page dt.rtbn a:before {
  display: block;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.page {
  font-size: 1.125rem;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 0 auto; }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block; }
    .page dt a,
    .page dd a {
      display: block;
      height: 32px;
      line-height: 30px;
      text-decoration: none;
      color: #ffffff; }
      .page dt a:hover,
      .page dd a:hover {
        color: #2f3a88; }
  .page dd {
    width: 2.3%; }
  .page dd.active a {
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    background: none;
    padding: 0;
    color: #4759d9;
    font-weight: bold; }
  .page dt.rtbn a,
  .page dt.ltbn a {
    width: 32px;
    height: 32px;
    transition: 0.4s;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    background: #2b2c2e; }
  .page .nopage {
    opacity: 0.2; }
  .page dt.ltbn a {
    margin-right: 17px; }
    .page dt.ltbn a:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 7px 7px 0;
      border-color: transparent #ffffff transparent transparent; }
  .page dt.rtbn a {
    margin-left: 17px; }
    .page dt.rtbn a:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 0 7px 7px;
      border-color: transparent transparent transparent #ffffff; }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  .top_btn {
    right: 25px; }
  right: 20px; }

@media (max-width: 767px) {
  .page {
    font-size: 1.0625rem; }
    .page dd {
      width: 11%; }
    .page dt.ltbn a {
      margin-right: 0.875rem; }
      .page dt.ltbn a:before {
        border-width: 5px 5px 5px 0; }
    .page dt.rtbn a {
      margin-left: 0.875rem; }
      .page dt.rtbn a:before {
        border-width: 5px 0 5px 5px; }
    .page dt.ltbn a,
    .page dt.rtbn a {
      width: 28px;
      height: 28px; }
  .back_btn,
  .more_btn {
    font-size: 1.0625rem; }
    .back_btn a,
    .more_btn a {
      padding: 12.5px 25px;
      border-radius: 4px; } }

/*----------------------header------------------------*/
.header_show {
  transition: .4S;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 10; }
  .header_show li {
    list-style: none; }
  .header_show.active {
    background: #131314; }

.top-headebx {
  display: flex;
  align-items: center;
  height: 46px;
  background-color: #0073d8;
  justify-content: space-between;
  padding: 0 40px; }
  @media (max-width: 991px) {
    .top-headebx {
      display: none; } }
  .top-headebx .l-bx {
    display: flex; }
    .top-headebx .l-bx a {
      display: block;
      position: relative;
      padding-left: 25px;
      color: #ffffff; }
      .top-headebx .l-bx a:first-child {
        margin-right: 35px; }
    .top-headebx .l-bx .tel::before {
      position: absolute;
      content: '';
      left: 0;
      background: url(../images/header_phone_icon.png) no-repeat center center/15px;
      top: 2px;
      width: 15px;
      height: 15px; }
    .top-headebx .l-bx .mail::before {
      position: absolute;
      content: '';
      left: 0;
      background: url(../images/email_icon.png) no-repeat center center/15px;
      top: 2px;
      width: 15px;
      height: 15px; }
  .top-headebx .r-bx ul {
    display: flex;
    align-items: center; }
    .top-headebx .r-bx ul li:not(:last-child) {
      margin-right: 30px; }
    .top-headebx .r-bx ul li a {
      color: #ffffff;
      height: 46px;
      display: block;
      line-height: 2.7;
      position: relative; }
      @media (min-width: 768px) {
        .top-headebx .r-bx ul li a:hover::before {
          opacity: 1; } }
      .top-headebx .r-bx ul li a::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ffffff;
        opacity: 0;
        transition: .4s; }

.header_box {
  position: relative;
  width: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  height: 64px; }

.header_box.sear-ing nav {
  animation: downani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: none; }

@keyframes downani {
  0% {
    top: 0;
    opacity: 1; }
  100% {
    top: 15px;
    opacity: 0; } }

.header_box.sear-ing .search-img {
  animation: sedownani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: none; }

@keyframes sedownani {
  0% {
    top: 0;
    opacity: 1; }
  100% {
    top: 10px;
    opacity: 0; } }

.header_box.sear-ing .search-clos {
  animation: sedani 1s 0.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: auto; }

@keyframes sedani {
  0% {
    top: -10px;
    opacity: 0; }
  100% {
    top: 0px;
    opacity: 1; } }

.header_box.sear-ing .search .inp-bx {
  animation: inputani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: auto; }

@keyframes inputani {
  0% {
    top: 10px;
    opacity: 0; }
  100% {
    top: 5px;
    opacity: 1; } }

.header_box.searclos-ing nav {
  animation: upani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: auto; }

@keyframes upani {
  0% {
    top: 15px;
    opacity: 0; }
  100% {
    top: 0px;
    opacity: 1; } }

.header_box.searclos-ing .search-img {
  animation: seaupani 1s 0.3s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: auto;
  opacity: 0; }

@keyframes seaupani {
  0% {
    top: 10px;
    opacity: 0; }
  100% {
    top: 0px;
    opacity: 1; } }

.header_box.searclos-ing .search-clos {
  animation: clupani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: none; }

@keyframes clupani {
  0% {
    top: 0px;
    opacity: 1; }
  100% {
    top: -10px;
    opacity: 0; } }

.header_box.searclos-ing .search .inp-bx {
  animation: inputdoani 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
  pointer-events: none; }

@keyframes inputdoani {
  0% {
    top: 5px;
    opacity: 1; }
  100% {
    top: 10px;
    opacity: 0; } }

.logo {
  width: 86px;
  height: 35px;
  background: url(../images/logo_mb.png) no-repeat;
  background-size: contain;
  text-indent: -10000px;
  margin: 25px 0; }
  .logo a {
    width: 100%;
    height: 100%;
    display: block; }

.search {
  position: fixed;
  right: 37px;
  width: 47px;
  height: 47px;
  top: 54px; }
  @media (max-width: 991px) {
    .search {
      display: block;
      position: relative;
      right: auto;
      top: auto;
      width: 100%;
      height: 80px;
      padding: 0 40px;
      border-bottom: 1px solid #a4d4ff;
      border-top: 1px solid #a4d4ff; } }
  .search .search-img {
    border: 1px solid rgba(255, 255, 255, 0); }
    @media (min-width: 768px) {
      .search .search-img:hover {
        border: 1px solid #0073d8; } }
  .search a {
    display: block;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 50%;
    transition: .4s;
    padding: 3px;
    width: 47px;
    height: 47px;
    position: absolute;
    top: 0;
    right: 0; }
    @media (max-width: 991px) {
      .search a {
        width: 20px;
        height: 20px;
        position: relative; } }
    .search a img {
      width: 41px;
      height: 40px; }
      @media (max-width: 991px) {
        .search a img {
          width: 20px;
          height: 20px; } }
  .search .inp-bx {
    position: absolute;
    height: 30px;
    width: 330px;
    right: 60px;
    top: 10px;
    opacity: 0;
    pointer-events: none; }
    @media (max-width: 991px) {
      .search .inp-bx {
        opacity: 1;
        pointer-events: auto;
        right: auto;
        left: 75px;
        width: 100%;
        top: 25px; } }
    .search .inp-bx input {
      height: 30px;
      width: 330px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #0073d8; }
      @media (max-width: 991px) {
        .search .inp-bx input {
          width: calc(100% - 115px); } }
      .search .inp-bx input:focus {
        border: none;
        border-bottom: 1px solid #0073d8; }
  .search .search-img {
    right: -3px; }
    @media (max-width: 991px) {
      .search .search-img {
        right: auto;
        padding-top: 25px;
        padding-left: 0; } }
  .search .search-clos {
    width: 47px;
    height: 47px;
    opacity: 0;
    pointer-events: none;
    top: 0;
    text-align: center;
    padding: 0;
    right: -3px; }
    .search .search-clos img {
      width: 27px;
      height: 28px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

/*----------------------nav------------------------*/
nav {
  position: relative; }

.nav_box {
  position: relative;
  font-size: 1.0625rem;
  line-height: 1;
  letter-spacing: 0px;
  font-weight: 400;
  color: #000000;
  margin: 0 auto;
  transition: .4s;
  left: -43px; }
  @media (max-width: 991px) {
    .nav_box {
      left: auto; } }
  .nav_box nav {
    display: flex; }
    @media (max-width: 1200px) {
      .nav_box nav {
        flex-wrap: wrap; } }
  .nav_box ul {
    display: flex;
    position: relative; }
    @media (max-width: 1200px) {
      .nav_box ul {
        width: 100%; } }
    @media (min-width: 1201px) {
      .nav_box ul .active a {
        color: #0073d8; }
        .nav_box ul .active a::before {
          opacity: 1; } }
    .nav_box ul li.hoing {
      background-color: #0073d8; }
      .nav_box ul li.hoing > a {
        color: #ffffff; }
    .nav_box ul li {
      position: relative;
      text-align: center;
      transition: .4s; }
      .nav_box ul li a {
        padding: 24px 20px;
        color: #0073d8;
        transition: .4s; }
        .nav_box ul li a::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #0073d8;
          left: 0;
          bottom: -2px;
          opacity: 0;
          transition: .4s; }
  .nav_box a {
    width: 100%;
    display: block;
    color: #000000;
    text-decoration: none;
    font-weight: 300;
    font-size: 1rem; }
    .nav_box a:after {
      transition: .4s;
      position: relative;
      content: '';
      width: 0;
      height: 2px;
      background: #4759d9;
      bottom: -26px;
      left: 8px;
      opacity: 0; }
    @media (max-width: 992px) {
      .nav_box a {
        font-weight: 400; } }
  .nav_box .activeh {
    color: #0073d8; }
  .nav_box .chlan-box {
    display: flex;
    padding: 4px 15px;
    border: solid 1px #a0a0a0;
    border-radius: 99px; }
    .nav_box .chlan-box div:not(:last-child) {
      margin-right: 15px;
      position: relative; }
      @media (max-width: 1200px) {
        .nav_box .chlan-box div:not(:last-child)::before {
          content: '';
          position: absolute;
          width: 2px;
          height: 19px;
          top: 0;
          right: -1vw;
          background: #dedede; } }
    .nav_box .chlan-box div {
      transition: .4s; }
      @media (min-width: 1200px) {
        .nav_box .chlan-box div:hover a {
          color: #0073d8; } }
      @media (max-width: 1200px) {
        .nav_box .chlan-box div {
          width: 30%;
          text-align: center; } }
    .nav_box .chlan-box .active {
      color: #0073d8; }
    @media (max-width: 1200px) {
      .nav_box .chlan-box {
        width: 100%;
        border: none;
        padding: 23px 0 20px;
        border-bottom: 1px solid #dedede;
        border-radius: 0; } }
  .nav_box .nav-moreboxbg {
    display: block; }
    @media (max-width: 992px) {
      .nav_box .nav-moreboxbg {
        display: block; } }
  .nav_box .nav-morebox {
    position: fixed;
    background: #0073d8;
    left: 0;
    top: 110px;
    width: 100%;
    text-align: left;
    display: flex;
    height: auto;
    box-shadow: 0 0px 5px rgba(13, 5, 9, 0.1);
    padding: 15px 0;
    display: none; }
    @media (max-width: 1200px) {
      .nav_box .nav-morebox {
        min-height: 215px;
        height: auto; } }
    @media (max-width: 992px) {
      .nav_box .nav-morebox {
        box-shadow: none;
        padding-top: 20px;
        padding-bottom: 30px; } }
    .nav_box .nav-morebox .mo {
      display: none; }
    .nav_box .nav-morebox .h-leftimg {
      margin-right: 45px;
      width: 184px; }
      .nav_box .nav-morebox .h-leftimg .h-txbx {
        font-size: 1.875rem;
        color: #0072bc;
        font-weight: 700; }
        .nav_box .nav-morebox .h-leftimg .h-txbx span {
          font-size: 1rem;
          color: #000000;
          font-weight: 500; }
    .nav_box .nav-morebox .mr {
      margin-right: 40px; }
    .nav_box .nav-morebox .h-listitem-box {
      margin: 0 auto;
      margin-top: 0px;
      background-color: #0073d8; }
      @media (max-width: 992px) {
        .nav_box .nav-morebox .h-listitem-box {
          min-height: calc(100vh- 54px); } }
      .nav_box .nav-morebox .h-listitem-box a:not(:first-child) {
        margin-top: 0px; }
      .nav_box .nav-morebox .h-listitem-box a:before {
        width: 2px;
        height: 70%;
        opacity: 0;
        bottom: auto;
        top: 3; }
      .nav_box .nav-morebox .h-listitem-box a {
        font-weight: 400;
        position: relative; }
      .nav_box .nav-morebox .h-listitem-box h4 {
        font-weight: 400;
        color: #0073d8; }
        @media (max-width: 992px) {
          .nav_box .nav-morebox .h-listitem-box h4 {
            font-size: 1rem; } }
      .nav_box .nav-morebox .h-listitem-box ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; }
        @media (max-width: 992px) {
          .nav_box .nav-morebox .h-listitem-box ul {
            display: flex;
            justify-content: start; } }
        .nav_box .nav-morebox .h-listitem-box ul .img-bx {
          position: relative;
          width: 50px;
          height: 50px;
          margin: 0 auto; }
          @media (max-width: 991px) {
            .nav_box .nav-morebox .h-listitem-box ul .img-bx {
              width: 34px;
              height: 34px; } }
          .nav_box .nav-morebox .h-listitem-box ul .img-bx img {
            width: 50px;
            height: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: .4s; }
            @media (max-width: 991px) {
              .nav_box .nav-morebox .h-listitem-box ul .img-bx img {
                width: 34px;
                height: 34px;
                position: relative;
                top: auto;
                left: auto;
                transform: none; } }
          .nav_box .nav-morebox .h-listitem-box ul .img-bx .nthov {
            opacity: 1; }
        @media (min-width: 992px) {
          .nav_box .nav-morebox .h-listitem-box ul li:hover:hover .nthov {
            opacity: 0; }
          .nav_box .nav-morebox .h-listitem-box ul li:hover:hover .hov {
            opacity: 1; }
          .nav_box .nav-morebox .h-listitem-box ul li:hover:hover .des {
            color: #2cd9d2; } }
        .nav_box .nav-morebox .h-listitem-box ul li a {
          font-size: 1rem;
          line-height: 1.25;
          font-weight: 500;
          color: #ffffff;
          padding: 0; }
          @media (max-width: 992px) {
            .nav_box .nav-morebox .h-listitem-box ul li a {
              font-size: 1rem;
              background-color: #00a0e9;
              border-bottom: 1px solid #ffffff;
              display: block;
              height: 100%;
              padding: 40px 0; } }
        .nav_box .nav-morebox .h-listitem-box ul li {
          width: calc((58.5vw - 23.7%) / 5);
          margin-right: 0;
          text-align: center; }
          @media (max-width: 1200px) {
            .nav_box .nav-morebox .h-listitem-box ul li {
              width: calc((58.5vw - 23.7%) / 3); } }
          @media (max-width: 992px) {
            .nav_box .nav-morebox .h-listitem-box ul li {
              width: 50%;
              border-bottom: none; }
              .nav_box .nav-morebox .h-listitem-box ul li:nth-child(odd) {
                border-right: 1px solid #ffffff; } }
          .nav_box .nav-morebox .h-listitem-box ul li .des {
            transition: .4s;
            text-align: center;
            line-height: 1.5;
            font-weight: 400;
            margin-top: 4px;
            padding: 0 20px; }
      @media (min-width: 768px) {
        .nav_box .nav-morebox .h-listitem-box .h-topbor {
          border-top: 1px solid #0073d8;
          padding-top: 20px;
          margin-top: 20px !important;
          padding-bottom: 0; } }
      @media (min-width: 992px) {
        .nav_box .nav-morebox .h-listitem-box .h-solbx {
          display: flex; }
          .nav_box .nav-morebox .h-listitem-box .h-solbx a {
            width: auto; }
            .nav_box .nav-morebox .h-listitem-box .h-solbx a:not(:last-child) {
              margin-right: 5%; } }
      @media (min-width: 768px) {
        .nav_box .nav-morebox .h-listitem-box .h-inves {
          padding-bottom: 6px; } }
      @media (min-width: 992px) {
        .nav_box .nav-morebox .h-listitem-box .h-solul {
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid #0073d8; } }
      .nav_box .nav-morebox .h-listitem-box .h-scanerul {
        padding-bottom: 0px; }
        @media (max-width: 992px) {
          .nav_box .nav-morebox .h-listitem-box .h-scanerul {
            height: 100%;
            padding-bottom: 0; } }
      .nav_box .nav-morebox .h-listitem-box .h-invesull li {
        width: calc((58.5vw - 23.7%) / 7); }
        @media (max-width: 1200px) {
          .nav_box .nav-morebox .h-listitem-box .h-invesull li {
            width: calc((58.5vw - 23.7%) / 6); } }
      .nav_box .nav-morebox .h-listitem-box .h-listitem-sbx {
        display: flex; }
      .nav_box .nav-morebox .h-listitem-box .h-listitem {
        position: relative;
        margin-top: 20px;
        display: none; }
        @media (max-width: 992px) {
          .nav_box .nav-morebox .h-listitem-box .h-listitem {
            display: block; } }
        .nav_box .nav-morebox .h-listitem-box .h-listitem:before {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          width: 1px;
          height: 88%;
          background: #bfbfbf; }
          @media (min-width: 768px) {
            .nav_box .nav-morebox .h-listitem-box .h-listitem:before {
              width: 1px;
              height: 88%; } }
        .nav_box .nav-morebox .h-listitem-box .h-listitem a:not(:first-child) {
          margin-top: 5px; }
        .nav_box .nav-morebox .h-listitem-box .h-listitem a:before {
          width: 0px; }
        .nav_box .nav-morebox .h-listitem-box .h-listitem a {
          color: #000000;
          font-weight: 400;
          font-size: 0.9375rem;
          transition: .4s; }
          @media (min-width: 768px) {
            .nav_box .nav-morebox .h-listitem-box .h-listitem a:hover {
              color: #0073d8; } }
          @media (max-width: 992px) {
            .nav_box .nav-morebox .h-listitem-box .h-listitem a {
              font-size: 1rem; } }
    .nav_box .nav-morebox .h-supbx {
      display: flex; }
      .nav_box .nav-morebox .h-supbx a {
        width: auto; }
        .nav_box .nav-morebox .h-supbx a:not(:last-child) {
          margin-right: 5%; }
      @media (max-width: 992px) {
        .nav_box .nav-morebox .h-supbx {
          display: block; } }
    .nav_box .nav-morebox .mt {
      margin-top: 0;
      width: 69.5vw; }
    @media (max-width: 992px) {
      .nav_box .nav-morebox {
        width: 50vw;
        left: auto;
        right: -50vw;
        display: block;
        padding-left: 0;
        top: 0;
        background: #ffffff;
        height: 100vh;
        z-index: 11;
        padding: 0;
        transition: .4s; }
        .nav_box .nav-morebox .mo {
          display: block; }
        .nav_box .nav-morebox .prevar {
          background: url(../images/h-prevarr.png) no-repeat 94%;
          background-size: 29px;
          width: 100%;
          height: 25px;
          padding: 27px 0;
          position: relative;
          text-align: center; }
          .nav_box .nav-morebox .prevar::before {
            content: none; }
          .nav_box .nav-morebox .prevar div {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            font-weight: 500;
            font-size: 1.25rem; }
        .nav_box .nav-morebox .h-leftimg {
          display: none; }
        .nav_box .nav-morebox .h-listitem-box {
          width: 100%;
          margin-top: 0; }
          .nav_box .nav-morebox .h-listitem-box .h-listitem-sbx {
            display: block;
            position: fixed;
            top: 0;
            right: -60vw;
            margin-top: 0;
            height: 100%;
            background: #ffffff;
            z-index: 17;
            transition: .4s;
            width: 50vw;
            padding: 0 18px; }
            .nav_box .nav-morebox .h-listitem-box .h-listitem-sbx .h-listitem {
              position: relative;
              height: auto;
              padding: 0;
              width: 100%;
              transition: none; }
          .nav_box .nav-morebox .h-listitem-box .h-listitem {
            display: block;
            position: fixed;
            top: 0;
            margin-top: 0;
            background: #ffffff;
            z-index: 15;
            height: 100vh;
            width: 50vw;
            right: -60vw;
            padding: 0 18px;
            transition: .4s; }
            .nav_box .nav-morebox .h-listitem-box .h-listitem::before {
              height: 0; }
            .nav_box .nav-morebox .h-listitem-box .h-listitem a:not(:first-child) {
              margin-top: 0; }
            .nav_box .nav-morebox .h-listitem-box .h-listitem a::before {
              width: 100%;
              height: 1px;
              left: 0;
              bottom: 0; }
            .nav_box .nav-morebox .h-listitem-box .h-listitem a:hover {
              color: #000000; }
          .nav_box .nav-morebox .h-listitem-box a {
            padding-left: 0; }
          .nav_box .nav-morebox .h-listitem-box a:before {
            height: 1px;
            width: 100%;
            background: #dedede;
            top: auto;
            bottom: 0; }
          .nav_box .nav-morebox .h-listitem-box a:not(:first-child) {
            margin-top: 0; } }
    @media (max-width: 992px) {
      .nav_box .nav-morebox {
        width: 100vw;
        right: -100vw; }
        .nav_box .nav-morebox .h-listitem-box .h-listitem-sbx {
          width: 100vw;
          right: -100vw; }
        .nav_box .nav-morebox .h-listitem-box .h-listitem {
          width: 100vw;
          right: -100vw; }
        .nav_box .nav-morebox .h-listitem {
          width: 100vw;
          right: -100vw; } }
  @media (max-width: 992px) {
    .nav_box .shnextpage {
      right: 0 !important; }
      .nav_box .shnextpage .nav-morebox {
        right: 0; }
    .nav_box .nav-morebox .h-listitem-box .shnextpage .h-listitem {
      right: 0; }
    .nav_box .shnextpageb .nav-morebox {
      right: -50vw; }
    .nav_box .nextar {
      background: url(../images/h-nextarr.png) no-repeat 97%;
      background-size: 12px; } }
  .nav_box .selectli .svg {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 992px) {
      .nav_box .selectli .svg path {
        fill: #0073d8 !important; } }
  .nav_box .selectli::before {
    position: absolute;
    content: '';
    border-top: 8px solid #000;
    border-left: 5px dashed transparent;
    border-right: 5px dashed transparent;
    left: 130px;
    width: 10px;
    height: 10px;
    bottom: auto;
    opacity: 1;
    background: none;
    top: 53%;
    transform: translateY(-50%); }
    @media (max-width: 992px) {
      .nav_box .selectli::before {
        border-top: 8px solid #0073d8; } }
  .nav_box select {
    border: none;
    font-size: 1rem;
    font-weight: 300;
    height: 58px;
    padding-left: 43px;
    background: none; }
    @media (max-width: 992px) {
      .nav_box select {
        color: #0073d8; } }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: none; }

.menu-wrapper {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  bottom: auto;
  margin: auto;
  width: 25px;
  height: 10px;
  cursor: pointer;
  z-index: 10;
  transition: .4s; }

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 25px;
  height: 2px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em; }

.hamburger-menu {
  position: relative;
  transform: translateY(4px);
  background: none;
  transition: all 0ms 300ms;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }
  .hamburger-menu span::before {
    position: absolute;
    content: '';
    width: 30px;
    height: 2px;
    background: #0073d8;
    top: -5px;
    left: -5px; }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: none;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu:after {
  content: "";
  position: absolute;
  right: 0;
  top: 4px;
  background: #0073d8;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px;
  width: 15px; }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

@media (max-width: 992px) {
  .header_box {
    padding: 0 40px; }
  .nav_box ul li:not(:last-child) {
    margin-right: 22px; } }

@media (max-width: 992px) {
  .logo {
    width: 100px;
    transition: .4s;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    margin: 0;
    height: 25px; }
  .header_box {
    width: 100%;
    height: 60px;
    font-size: 1.0625rem;
    margin: 0 auto;
    padding: 0 20px; }
  .nav_box nav {
    width: 100%;
    padding: 0 40px; } }

@media (max-width: 992px) and (max-width: 992px) {
  .navop {
    right: 0; }
    .navop .nav-morebox {
      overflow: scroll; } }

@media (max-width: 992px) {
  .nav_box {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: #ffffff;
    transition: left .4s; } }
  @media (max-width: 992px) and (max-width: 1200px) {
    .nav_box {
      width: 50vw;
      left: auto;
      right: -50vw; }
      .nav_box .shnextpage.nav-morebox {
        right: -50vw; } }
  @media (max-width: 992px) and (max-width: 992px) {
    .nav_box {
      width: 100vw;
      height: 100vh;
      right: 0;
      top: 60px;
      overflow-x: hidden;
      overflow-y: scroll;
      display: none;
      padding-bottom: 90px; }
      .nav_box .shnextpage.nav-morebox {
        right: -100vw; } }
  @media (max-width: 992px) and (max-width: 350px) {
    .nav_box {
      height: 90vh; } }

@media (max-width: 992px) {
    .nav_box ul {
      display: block; }
      .nav_box ul li {
        width: 100%; }
        .nav_box ul li a {
          padding: 23px 0 20px 0;
          text-align: left;
          text-decoration: none;
          font-size: 0.9375rem; } }
        @media (max-width: 992px) and (max-width: 992px) {
          .nav_box ul li a {
            font-size: 1rem;
            padding: 28px 0; } }

@media (max-width: 992px) {
      .nav_box ul li.selectli {
        border-bottom: none !important; }
    .nav_box ul > li:not(:last-child) {
      margin-right: 0; }
  .menu-wrapper {
    display: block; } }

@media (max-width: 992px) {
  .navop {
    right: 0; } }

.h-mo-bgc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -1;
  height: 100%;
  background-color: rgba(220, 220, 220, 0.8);
  transition: .1s;
  transition-delay: .4s;
  opacity: 0;
  pointer-events: none; }

@media (max-width: 1200px) {
  .mobgshow {
    opacity: 1;
    transition-delay: 0s !important; } }

@media (max-width: 992px) {
  .h-lan {
    display: none; } }

.h-lan .svg {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0px; }
  .h-lan .svg path {
    transition: .4s; }

.h-lan .lanfrlink {
  display: flex;
  align-items: center; }

@media (min-width: 992px) {
  .h-lan:hover .svg path {
    fill: #0073d8 !important; }
  .h-lan:hover .lanfrlink {
    color: #0073d8; }
    .h-lan:hover .lanfrlink::before {
      border-top: 8px solid #0073d8; } }

.h-lan .h-hovbox {
  position: fixed;
  margin-top: 33px;
  background: #ffffff;
  opacity: 0;
  transition: .4s;
  box-shadow: 2px 0px 5px rgba(13, 5, 9, 0.1);
  padding-bottom: 5px;
  letter-spacing: 0; }
  .h-lan .h-hovbox a {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center; }

.showlanbx .h-hovbox {
  opacity: 1 !important; }

.thr-linkbx {
  display: none; }
  @media (max-width: 991px) {
    .thr-linkbx {
      display: block;
      width: 100%;
      padding: 0 40px; }
      .thr-linkbx ul li a {
        font-size: 1rem;
        padding: 28px 0;
        color: #0073d8; } }

.tm-bx {
  display: none; }
  @media (max-width: 991px) {
    .tm-bx {
      display: block;
      width: 100%; }
      .tm-bx ul li {
        border-top: 1px solid #a4d4ff; }
        .tm-bx ul li a {
          font-size: 1rem;
          padding: 28px 0;
          color: #0073d8;
          position: relative;
          display: block;
          width: calc(100% - 80px);
          margin: 0 auto; }
        .tm-bx ul li:first-child a {
          padding-left: 40px; }
          .tm-bx ul li:first-child a::before {
            position: absolute;
            content: '';
            left: 0;
            background: url(../images/phone_icon_mb.png) no-repeat center center/15px;
            width: 26px;
            height: 26px;
            opacity: 1;
            top: 50%;
            transform: translateY(-50%); }
        .tm-bx ul li:nth-child(2) a {
          padding-left: 40px; }
          .tm-bx ul li:nth-child(2) a::before {
            position: absolute;
            content: '';
            left: 0;
            background: url(../images/mail_icon_mb.png) no-repeat center center/15px;
            width: 26px;
            height: 26px;
            opacity: 1;
            top: 50%;
            transform: translateY(-50%); } }

.h-search {
  top: -3px; }
  @media (max-width: 992px) {
    .h-search {
      display: none; } }
  .h-search .svg {
    width: 20px;
    height: 20px; }
    .h-search .svg path {
      transition: .4s; }
  @media (min-width: 768px) {
    .h-search:hover .svg path {
      fill: #0073d8 !important; } }

.mo-serach {
  display: none; }
  @media (max-width: 992px) {
    .mo-serach {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 65px; } }

.animate::before {
  animation: humanibe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

.animate::after {
  animation: humaniaf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

.animate span::before {
  animation: humanspbe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

@keyframes humanibe {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes humaniaf {
  0% {
    transform: rotate(0deg);
    top: 8px;
    width: 15px; }
  70% {
    transform: rotate(-55deg);
    top: 0px;
    width: 30px; }
  100% {
    transform: rotate(-45deg);
    top: 0px;
    width: 30px; } }

@keyframes humanspbe {
  0% {
    transform: rotate(0deg);
    top: -9px; }
  70% {
    transform: rotate(55deg);
    top: 0px; }
  100% {
    transform: rotate(45deg);
    top: 0px; } }

.colsanimate::before {
  animation: colshumbf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

.colsanimate::after {
  animation: colshumaf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

.colsanimate span::before {
  animation: colspanbe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

@keyframes colshumbf {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes colshumaf {
  0% {
    transform: rotate(-45deg);
    top: 0px; }
  70% {
    transform: rotate(5deg);
    top: 8px; }
  100% {
    transform: rotate(0deg);
    top: 8px; } }

@keyframes colspanbe {
  0% {
    transform: rotate(45deg);
    top: 0px; }
  70% {
    transform: rotate(-5deg);
    top: -4px; }
  100% {
    transform: rotate(0deg);
    top: -4px; } }

.moshow {
  display: none; }
  @media (max-width: 992px) {
    .moshow {
      display: block; } }

.novalhid {
  display: none !important; }

.ioshead {
  top: 0 !important; }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(6) .h-listitem-box > a {
    padding-top: 32px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(6) .h-listitem-box a:first-child {
    padding-top: 48px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(5) .h-listitem-box > a {
    padding-top: 32px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(5) .h-listitem-box a:first-child {
    padding-top: 48px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(4) .h-listitem-box > a {
    padding-top: 32px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(4) .h-listitem-box a:first-child {
    padding-top: 48px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(7) .h-listitem-box > a {
    padding-top: 32px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(7) .h-listitem-box a:first-child {
    padding-top: 48px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(3) .h-listitem-box > a {
    padding-top: 32px; } }

@media (max-width: 992px) {
  .nav_box nav > ul > li:nth-child(3) .h-listitem-box a:first-child {
    padding-top: 48px; } }

.iostop {
  height: 100vh;
  position: fixed; }

footer {
  z-index: -10;
  padding: 25px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #ffffff; }
  @media (max-width: 991px) {
    footer {
      display: block;
      padding: 45px 25px 65px 25px;
      border-top: 1px solid #0073d8; } }
  footer .footer-oubx {
    display: flex;
    width: 100%;
    justify-content: space-between; }
    @media (max-width: 991px) {
      footer .footer-oubx {
        display: block;
        max-width: 420px;
        margin: 0 auto;
        width: 100%; } }
  footer .l-bx {
    display: flex; }
    @media (max-width: 991px) {
      footer .l-bx {
        display: block; } }
    footer .l-bx .f-logo {
      background: url(../images/logo_mb.png) no-repeat center center/cover;
      width: 86px;
      height: 35px; }
      @media (max-width: 991px) {
        footer .l-bx .f-logo {
          width: 60px;
          height: 25px; } }
    footer .l-bx ul {
      margin-left: 30px; }
      @media (max-width: 991px) {
        footer .l-bx ul {
          margin-left: 0;
          margin-top: 15px; } }
      footer .l-bx ul li {
        display: flex;
        font-size: 1rem;
        line-height: 2.5;
        letter-spacing: 0.32px;
        font-weight: 400;
        color: #6d6d6d; }
        @media (max-width: 1200px) {
          footer .l-bx ul li {
            display: block; } }
        @media (max-width: 991px) {
          footer .l-bx ul li {
            line-height: 1; } }
        footer .l-bx ul li a {
          display: block;
          font-size: 1rem;
          line-height: 2.5;
          letter-spacing: 0.32px;
          font-weight: 400;
          color: #6d6d6d; }
        footer .l-bx ul li .tel, footer .l-bx ul li .dress {
          margin-left: 25px; }
          @media (max-width: 1200px) {
            footer .l-bx ul li .tel, footer .l-bx ul li .dress {
              margin-left: 0; } }
        footer .l-bx ul li .cony {
          padding-left: 23px;
          position: relative;
          min-width: 195px; }
          @media (max-width: 991px) {
            footer .l-bx ul li .cony {
              line-height: 2.2; } }
          footer .l-bx ul li .cony::before {
            position: absolute;
            content: '';
            background: url(../images/company_icon_mb.png) no-repeat center/cover;
            width: 15px;
            height: 19px;
            left: 0;
            top: 11px; }
            @media (max-width: 991px) {
              footer .l-bx ul li .cony::before {
                width: 13px;
                height: 17px;
                top: 9px; } }
        footer .l-bx ul li .tel {
          padding-left: 23px;
          position: relative; }
          @media (max-width: 991px) {
            footer .l-bx ul li .tel {
              line-height: 2.2; } }
          footer .l-bx ul li .tel::before {
            position: absolute;
            content: '';
            background: url(../images/phone_icon_mb.png) no-repeat center/cover;
            width: 15px;
            height: 15px;
            left: 0;
            top: 12px; }
            @media (max-width: 991px) {
              footer .l-bx ul li .tel::before {
                width: 13px;
                height: 13px; } }
        footer .l-bx ul li .mail {
          padding-left: 23px;
          position: relative;
          min-width: 195px; }
          @media (max-width: 991px) {
            footer .l-bx ul li .mail {
              line-height: 2.2; } }
          footer .l-bx ul li .mail::before {
            position: absolute;
            content: '';
            background: url(../images/mail_icon_mb.png) no-repeat center/cover;
            width: 19px;
            height: 14px;
            left: 0;
            top: 14px; }
            @media (max-width: 991px) {
              footer .l-bx ul li .mail::before {
                width: 16px;
                height: 11px; } }
        footer .l-bx ul li .dress {
          padding-left: 23px;
          position: relative; }
          @media (max-width: 991px) {
            footer .l-bx ul li .dress {
              line-height: 1.6;
              margin-top: 5px; } }
          footer .l-bx ul li .dress::before {
            position: absolute;
            content: '';
            background: url(../images/address_icon_mb.png) no-repeat center/cover;
            width: 16px;
            height: 20px;
            left: 0;
            top: 11px; }
            @media (max-width: 991px) {
              footer .l-bx ul li .dress::before {
                width: 14px;
                height: 17px;
                top: 5px; } }
  @media (max-width: 991px) {
    footer .r-bx {
      margin-top: 58px; } }
  footer .r-bx .pch-btn {
    margin-left: auto;
    margin-bottom: 16px; }
    @media (max-width: 991px) {
      footer .r-bx .pch-btn {
        margin: 0;
        margin-bottom: 10px; } }
    footer .r-bx .pch-btn div {
      position: relative;
      padding-left: 34px; }
      footer .r-bx .pch-btn div::before {
        position: absolute;
        content: '';
        width: 22px;
        height: 21px;
        left: 19px;
        top: 13px;
        background: url(../images/shop_bt_icon_mb.png) no-repeat center/cover;
        transition: .4s; }
        @media (max-width: 991px) {
          footer .r-bx .pch-btn div::before {
            width: 20px;
            height: 19px;
            top: 11px; } }
    @media (min-width: 768px) {
      footer .r-bx .pch-btn:hover div:before {
        background: url(../images/hoshop_bt_icon_mb.png) no-repeat center/cover; } }
  footer .r-bx .copright {
    font-size: 1rem;
    line-height: 1.88;
    letter-spacing: 0.32px;
    font-weight: 400;
    color: #6d6d6d; }
    @media (max-width: 991px) {
      footer .r-bx .copright {
        letter-spacing: 0; } }
  footer .r-bx .a-bx {
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 991px) {
      footer .r-bx .a-bx {
        justify-content: flex-start; } }
  footer .r-bx .rule {
    font-size: 1rem;
    line-height: 1.88;
    letter-spacing: 0.32px;
    font-weight: 500;
    color: #0073d8;
    padding-bottom: 3px;
    border-bottom: 1px solid #0073d8; }
  footer .r-bx .mak {
    font-size: 1rem;
    line-height: 1.88;
    letter-spacing: 0.32px;
    font-weight: 400;
    color: #6d6d6d;
    padding-left: 12px;
    margin-left: 8px;
    position: relative; }
    footer .r-bx .mak::before {
      position: absolute;
      content: '';
      left: 0;
      top: 8px;
      height: 60%;
      width: 1px;
      background-color: #6d6d6d; }

.r-fixfb {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 64px;
  height: 88px;
  border-radius: 15px 0 0 0;
  z-index: 999;
  background: url(../images/fb_icon_sidebar_mb.png) no-repeat center center/21px;
  background-color: #0073d8; }
  @media (max-width: 991px) {
    .r-fixfb {
      width: 46px;
      height: 63px;
      background: url(../images/fb_icon_sidebar_mb.png) no-repeat center center/17px;
      background-color: #0073d8;
      border-radius: 7.5px 0 0 0; } }
  .r-fixfb::before {
    position: absolute;
    content: '';
    width: 68px;
    height: 96px;
    border-radius: 15px 0 0 0;
    border: 1px solid #0073d8;
    opacity: 0;
    transition: .4s;
    right: 0;
    top: -4px; }
    @media (max-width: 991px) {
      .r-fixfb::before {
        border-radius: 7.5px 0 0 0; } }
  @media (min-width: 991px) {
    .r-fixfb:hover::before {
      opacity: 1; } }

/******************************************/
/*		pages
/******************************************/
.indexPage .index-banner {
  position: relative;
  margin-bottom: 120px;
  /* Animation */
  /*Shrinking for mobile*/ }
  @media (max-width: 768px) {
    .indexPage .index-banner {
      margin-bottom: 70px; } }
  .indexPage .index-banner .container {
    padding: 0 10px; }
    @media (min-width: 1200px) {
      .indexPage .index-banner .container {
        max-width: 1386px; } }
  .indexPage .index-banner .ind-cbx {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media (max-width: 991px) {
      .indexPage .index-banner .ind-cbx {
        display: block; } }
    @media (max-width: 991px) {
      .indexPage .index-banner .ind-cbx {
        padding: 0 40px;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        padding-top: 16%; } }
    .indexPage .index-banner .ind-cbx .l-bx, .indexPage .index-banner .ind-cbx .r-img {
      z-index: 50;
      position: relative; }
    .indexPage .index-banner .ind-cbx .ti {
      font-size: 3.75rem;
      line-height: 1.33;
      letter-spacing: 1.2px;
      font-weight: 500;
      color: #ffffff;
      max-height: 158px;
      overflow: hidden;
      text-shadow: #424141 1px 0 4px; }
      @media (max-width: 768px) {
        .indexPage .index-banner .ind-cbx .ti {
          font-size: 2.5rem;
          max-height: 110px; } }
    .indexPage .index-banner .ind-cbx .des {
      margin-top: 40px;
      font-size: 1.375rem;
      line-height: 1.64;
      letter-spacing: 0.4px;
      font-weight: 500;
      color: #ffffff;
      max-height: 109px;
      overflow: hidden;
      text-shadow: #424141 1px 0 4px;
      text-align: justify; }
      @media (max-width: 768px) {
        .indexPage .index-banner .ind-cbx .des {
          font-size: 1.25rem;
          max-height: 124px;
          margin-top: 10px;
          font-weight: 400; } }
    .indexPage .index-banner .ind-cbx .l-bx {
      margin-left: 6.8%;
      margin-right: 3.2%;
      max-width: 445px;
      min-width: 445px;
      order: 1; }
      @media (max-width: 991px) {
        .indexPage .index-banner .ind-cbx .l-bx {
          margin: 0 auto;
          margin-top: 30px;
          max-width: 100%;
          min-width: 100%; } }
    .indexPage .index-banner .ind-cbx .r-img {
      width: calc( 90% - 445px);
      order: 2; }
      @media (max-width: 991px) {
        .indexPage .index-banner .ind-cbx .r-img {
          width: 58%;
          margin: 0 auto; } }
      @media (max-width: 768px) {
        .indexPage .index-banner .ind-cbx .r-img {
          width: 87%;
          margin: 0 auto; } }
  .indexPage .index-banner .bn-it {
    display: none; }
  .indexPage .index-banner .pc-banner {
    position: relative;
    height: 806px; }
    @media (max-width: 768px) {
      .indexPage .index-banner .pc-banner {
        height: 0;
        padding-bottom: 160%; } }
  .indexPage .index-banner .pc-banner .bgcover {
    width: 100vw;
    position: absolute;
    padding-bottom: 806px;
    top: 0;
    left: 0;
    background-size: cover !important; }
    @media (max-width: 768px) {
      .indexPage .index-banner .pc-banner .bgcover {
        padding-bottom: 160%; } }
  .indexPage .index-banner .bgcover.mo {
    display: block; }
    @media (min-width: 768px) {
      .indexPage .index-banner .bgcover.mo {
        display: none; } }
  .indexPage .index-banner .bgcover.pc {
    display: block; }
    @media (max-width: 767px) {
      .indexPage .index-banner .bgcover.pc {
        display: none; } }
  .indexPage .index-banner .wave-bx {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
  .indexPage .index-banner .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px; }
  .indexPage .index-banner .content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: white; }
  .indexPage .index-banner .parallax > use {
    animation: move-forever 15s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite; }
  .indexPage .index-banner .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 4s; }
  .indexPage .index-banner .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 5s; }
  .indexPage .index-banner .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 7s; }
  .indexPage .index-banner .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 10s; }

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0); }
  100% {
    transform: translate3d(85px, 0, 0); } }
  @media (max-width: 768px) {
    .indexPage .index-banner .waves {
      height: 40px;
      min-height: 40px; }
    .indexPage .index-banner .content {
      height: 30vh; }
    .indexPage .index-banner h1 {
      font-size: 24px; } }
  .indexPage .index-banner .show-me::before {
    background-color: #ffffff; }
  .indexPage .index-banner .show-box {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    z-index: 10;
    display: block; }
    @media (max-width: 767px) {
      .indexPage .index-banner .show-box {
        left: 0px; } }
    .indexPage .index-banner .show-box .go-it {
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: relative;
      margin-bottom: 15px; }
      @media (max-width: 991px) {
        .indexPage .index-banner .show-box .go-it {
          margin-bottom: 30px; } }
      .indexPage .index-banner .show-box .go-it::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        border: solid 1px #ffffff;
        transition: .4s;
        width: 6px;
        height: 38px; }
        @media (max-width: 767px) {
          .indexPage .index-banner .show-box .go-it::before {
            width: 5px;
            height: 48px; } }
      @media (min-width: 768px) {
        .indexPage .index-banner .show-box .go-it:hover::before {
          background-color: #ffffff; } }

.indexPage .item2 .it2-bx {
  position: relative; }
  @media (max-width: 991px) and (min-width: 769px) {
    .indexPage .item2 .it2-bx {
      max-width: 100%; } }
  .indexPage .item2 .it2-bx .tit-blsq {
    text-align: center;
    margin-bottom: 70px; }
    @media (max-width: 767px) {
      .indexPage .item2 .it2-bx .tit-blsq {
        margin-bottom: 30px; } }
  .indexPage .item2 .it2-bx ul {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .indexPage .item2 .it2-bx ul {
        display: block; } }
    .indexPage .item2 .it2-bx ul li {
      width: 50%;
      border-bottom: 1px solid #d2d2d2;
      padding: 20px 40px;
      padding-bottom: 50px;
      text-align: center;
      position: relative; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-bx ul li {
          width: 100%;
          padding: 20px 0px 20px; } }
      .indexPage .item2 .it2-bx ul li a {
        display: block; }
      .indexPage .item2 .it2-bx ul li:nth-child(odd) {
        border-right: 1px solid #d2d2d2; }
        @media (max-width: 767px) {
          .indexPage .item2 .it2-bx ul li:nth-child(odd) {
            border-right: none; } }
      .indexPage .item2 .it2-bx ul li:last-child, .indexPage .item2 .it2-bx ul li:nth-last-child(2) {
        border-bottom: none; }
        .indexPage .item2 .it2-bx ul li:last-child::before, .indexPage .item2 .it2-bx ul li:nth-last-child(2)::before {
          transform: none;
          transform: translateX(-50%);
          top: auto;
          bottom: 0;
          height: 97%; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-bx ul li:nth-last-child(2) {
          border-bottom: 1px solid #d2d2d2; } }
      .indexPage .item2 .it2-bx ul li::before {
        position: absolute;
        content: '';
        width: 95%;
        height: 95%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #0073d8;
        pointer-events: none;
        opacity: 0;
        transition: .4s;
        border-radius: 15px 0 15px 0; }
      .indexPage .item2 .it2-bx ul li::after {
        position: absolute;
        content: '';
        background: url(../images/b_bt.png) no-repeat center center/cover;
        width: 72px;
        height: 73px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        opacity: 0;
        transition: .2s ease; }
      @media (min-width: 768px) {
        .indexPage .item2 .it2-bx ul li:hover::before {
          opacity: .5; }
        .indexPage .item2 .it2-bx ul li:hover::after {
          opacity: 1; } }
    .indexPage .item2 .it2-bx ul .des-ti {
      font-size: 1.625rem;
      line-height: 1.46;
      letter-spacing: 0.5px;
      font-weight: 400;
      color: #0073d8;
      max-height: 37px;
      overflow: hidden; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-bx ul .des-ti {
          margin-top: 20px; } }
    .indexPage .item2 .it2-bx ul .des {
      margin-top: 20px;
      font-size: 1rem;
      line-height: 1.88;
      letter-spacing: 0.3px;
      font-weight: 300;
      color: #6d6d6d;
      max-height: 150px;
      overflow: hidden; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-bx ul .des {
          max-height: 185px;
          margin-top: 12px; } }

@keyframes fadel {
  0% {
    opacity: .2; }
  50% {
    opacity: 0; }
  100% {
    opacity: .2; } }
  .indexPage .item2 .it2-bx .l-sqbx {
    position: absolute;
    left: -540px;
    top: 220px; }
    @media (max-width: 767px) {
      .indexPage .item2 .it2-bx .l-sqbx {
        display: none; } }
    .indexPage .item2 .it2-bx .l-sqbx .t-bx, .indexPage .item2 .it2-bx .l-sqbx .b-bx {
      display: flex; }
    .indexPage .item2 .it2-bx .l-sqbx .b-bx {
      margin-left: 15px; }
    .indexPage .item2 .it2-bx .l-sqbx .sq {
      width: 90px;
      height: 150px;
      transform-origin: bottom;
      transform: skewX(-32deg);
      background-color: #bce0ff;
      opacity: .2;
      border-radius: 15px 0 15px 0;
      margin: 0 15px;
      margin-bottom: 30px; }
    .indexPage .item2 .it2-bx .l-sqbx .ani1 {
      animation: fadel 5s 1s linear infinite; }
    .indexPage .item2 .it2-bx .l-sqbx .ani2 {
      animation: fadel 5s 1.5s linear infinite; }
    .indexPage .item2 .it2-bx .l-sqbx .ani3 {
      animation: fadel 5s 2s linear infinite; }
    .indexPage .item2 .it2-bx .l-sqbx .ani4 {
      animation: fadel 5s 2.5s linear infinite; }
    .indexPage .item2 .it2-bx .l-sqbx .ani5 {
      animation: fadel 5s 3s linear infinite; }
    .indexPage .item2 .it2-bx .l-sqbx .ani6 {
      animation: fadel 5s 3.5s linear infinite; }
  .indexPage .item2 .it2-bx .r-sqbx {
    position: absolute;
    right: -540px;
    bottom: 120px; }
    @media (max-width: 767px) {
      .indexPage .item2 .it2-bx .r-sqbx {
        display: none; } }
    .indexPage .item2 .it2-bx .r-sqbx .t-bx, .indexPage .item2 .it2-bx .r-sqbx .b-bx {
      display: flex; }
    .indexPage .item2 .it2-bx .r-sqbx .b-bx {
      margin-left: 15px; }
    .indexPage .item2 .it2-bx .r-sqbx .sq {
      width: 90px;
      height: 150px;
      transform-origin: bottom;
      transform: skewX(-32deg);
      background-color: #0073d8;
      opacity: .2;
      border-radius: 15px 0 15px 0;
      margin: 0 15px;
      margin-bottom: 30px; }
    .indexPage .item2 .it2-bx .r-sqbx .ani1 {
      animation: fadel 5s 1s linear infinite; }
    .indexPage .item2 .it2-bx .r-sqbx .ani2 {
      animation: fadel 5s 1.5s linear infinite; }
    .indexPage .item2 .it2-bx .r-sqbx .ani3 {
      animation: fadel 5s 2s linear infinite; }
    .indexPage .item2 .it2-bx .r-sqbx .ani4 {
      animation: fadel 5s 2.5s linear infinite; }
    .indexPage .item2 .it2-bx .r-sqbx .ani5 {
      animation: fadel 5s 3s linear infinite; }
    .indexPage .item2 .it2-bx .r-sqbx .ani6 {
      animation: fadel 5s 3.5s linear infinite; }

.indexPage .item3 {
  position: relative;
  height: 801px;
  background: url(../images/2bg.jpg) no-repeat center center/cover;
  margin-top: 100px; }
  @media (max-width: 767px) {
    .indexPage .item3 {
      background: url(../images/2bg_mb.jpg) no-repeat center center/cover;
      height: auto;
      margin-top: 30px; } }
  .indexPage .item3 .it3-bx {
    display: flex;
    align-items: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 991px) {
      .indexPage .item3 .it3-bx {
        display: block; } }
    @media (max-width: 767px) {
      .indexPage .item3 .it3-bx {
        top: 0;
        transform: none;
        padding: 90px 25px; } }
    .indexPage .item3 .it3-bx .l-img {
      margin-right: 6%;
      width: calc(94% - 450px); }
      @media (max-width: 991px) {
        .indexPage .item3 .it3-bx .l-img {
          width: 59%;
          margin: 0 auto; } }
      @media (max-width: 767px) {
        .indexPage .item3 .it3-bx .l-img {
          width: 90%; } }
    .indexPage .item3 .it3-bx .r-bx {
      max-width: 450px; }
      @media (max-width: 991px) {
        .indexPage .item3 .it3-bx .r-bx {
          margin: 0 auto; } }
      .indexPage .item3 .it3-bx .r-bx .tit-blsq {
        position: relative; }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .r-bx .tit-blsq {
            margin-top: 45px;
            padding-left: 25px; } }
        .indexPage .item3 .it3-bx .r-bx .tit-blsq::before {
          position: absolute;
          transform-origin: bottom;
          transform: skewX(-32deg);
          content: "";
          top: -5px;
          background-color: #348ddb;
          opacity: 1;
          z-index: -1;
          width: 31px;
          height: 48px;
          left: -25px;
          border-radius: 5px 0 5px 0; }
          @media (max-width: 767px) {
            .indexPage .item3 .it3-bx .r-bx .tit-blsq::before {
              z-index: 1;
              left: 0;
              background-color: #3598d6; } }
        .indexPage .item3 .it3-bx .r-bx .tit-blsq div {
          color: #ffffff;
          line-height: 1.3;
          z-index: 2;
          max-height: 104px;
          overflow: hidden;
          margin-bottom: 32px;
          text-shadow: #424141 1px 0 4px; }
          @media (max-width: 767px) {
            .indexPage .item3 .it3-bx .r-bx .tit-blsq div {
              max-height: 157px; } }
          .indexPage .item3 .it3-bx .r-bx .tit-blsq div::before {
            width: 0; }
      .indexPage .item3 .it3-bx .r-bx .des {
        font-size: 1rem;
        line-height: 1.88;
        letter-spacing: 0.32px;
        font-weight: 300;
        color: #ffffff;
        max-height: 210px;
        overflow: hidden;
        text-shadow: #424141 1px 0 4px;
        text-align: justify; }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .r-bx .des {
            max-height: inherit;
            overflow: auto; } }
      .indexPage .item3 .it3-bx .r-bx .sh-mbtn {
        margin-top: 60px; }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .r-bx .sh-mbtn {
            margin-top: 50px; } }
  .indexPage .item3 .t-wav {
    width: 100%;
    position: absolute;
    top: -1px; }
    .indexPage .item3 .t-wav img {
      width: 100%; }
  .indexPage .item3 .b-wav {
    width: 100%;
    position: absolute;
    bottom: -1px; }
    .indexPage .item3 .b-wav img {
      width: 100%; }

.indexPage .item4 {
  margin-top: 90px; }
  @media (max-width: 767px) {
    .indexPage .item4 {
      margin-top: 70px; } }
  .indexPage .item4 .it4-bx {
    display: flex;
    align-items: center;
    position: relative; }
    @media (max-width: 991px) {
      .indexPage .item4 .it4-bx {
        display: block; } }
    .indexPage .item4 .it4-bx .l-img {
      order: 2;
      width: calc(94% - 450px); }
      @media (max-width: 991px) {
        .indexPage .item4 .it4-bx .l-img {
          width: 59%;
          margin: 0 auto; } }
      @media (max-width: 767px) {
        .indexPage .item4 .it4-bx .l-img {
          width: 90%; } }
    .indexPage .item4 .it4-bx .r-bx {
      max-width: 450px;
      order: 1;
      margin-right: 6%; }
      @media (max-width: 991px) {
        .indexPage .item4 .it4-bx .r-bx {
          margin: 0 auto; } }
      .indexPage .item4 .it4-bx .r-bx .tit-blsq {
        position: relative; }
        @media (max-width: 767px) {
          .indexPage .item4 .it4-bx .r-bx .tit-blsq {
            margin-top: 45px;
            padding-left: 25px; } }
        .indexPage .item4 .it4-bx .r-bx .tit-blsq::before {
          position: absolute;
          transform-origin: bottom;
          transform: skewX(-32deg);
          content: "";
          top: -5px;
          opacity: .2;
          z-index: -1;
          width: 31px;
          height: 48px;
          left: -25px;
          border-radius: 5px 0 5px 0;
          background-color: #0073d8; }
          @media (max-width: 767px) {
            .indexPage .item4 .it4-bx .r-bx .tit-blsq::before {
              left: 0; } }
        .indexPage .item4 .it4-bx .r-bx .tit-blsq div {
          color: #0073d8;
          line-height: 1.3;
          z-index: 2;
          max-height: 104px;
          overflow: hidden;
          margin-bottom: 32px; }
          .indexPage .item4 .it4-bx .r-bx .tit-blsq div::before {
            width: 0; }
          @media (max-width: 767px) {
            .indexPage .item4 .it4-bx .r-bx .tit-blsq div {
              max-height: 157px; } }
      .indexPage .item4 .it4-bx .r-bx .des {
        font-size: 1rem;
        line-height: 1.88;
        letter-spacing: 0.32px;
        font-weight: 300;
        color: #6d6d6d;
        max-height: 210px;
        overflow: hidden;
        text-align: justify; }
        @media (max-width: 767px) {
          .indexPage .item4 .it4-bx .r-bx .des {
            max-height: inherit;
            overflow: auto; } }
      .indexPage .item4 .it4-bx .r-bx .sh-mbtn {
        margin-top: 60px;
        color: #0073d8; }
        .indexPage .item4 .it4-bx .r-bx .sh-mbtn::before, .indexPage .item4 .it4-bx .r-bx .sh-mbtn::after {
          background-color: #0073d8; }
        @media (max-width: 767px) {
          .indexPage .item4 .it4-bx .r-bx .sh-mbtn {
            margin-top: 50px; } }

.indexPage .hoving5.item5::before {
  left: 200%;
  transition: 3s ease; }

.indexPage .item5 {
  background-color: #0073d8;
  text-align: center;
  position: relative;
  margin-top: 90px; }
  @media (max-width: 767px) {
    .indexPage .item5 {
      margin-top: 55px; } }
  .indexPage .item5::before {
    position: absolute;
    content: '';
    background: linear-gradient(145deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%; }
  .indexPage .item5 a {
    display: block;
    padding-top: 60px;
    padding-bottom: 75px;
    position: relative; }
    @media (max-width: 767px) {
      .indexPage .item5 a {
        padding-top: 35px;
        padding-bottom: 60px; } }
  .indexPage .item5 .tit-blsq {
    position: relative; }
    @media (max-width: 767px) {
      .indexPage .item5 .tit-blsq {
        margin-top: 45px;
        padding-left: 25px; } }
    .indexPage .item5 .tit-blsq div {
      color: #ffffff;
      line-height: 1.3;
      z-index: 2;
      margin-bottom: 27px; }
      @media (max-width: 767px) {
        .indexPage .item5 .tit-blsq div {
          max-height: 157px; } }
      .indexPage .item5 .tit-blsq div::before {
        content: "";
        width: 31px;
        height: 48px;
        left: -25px;
        border-radius: 5px 0 5px 0;
        top: -5px;
        opacity: 1;
        z-index: -1;
        background-color: #348ddb; }
  .indexPage .item5 .des {
    font-size: 1rem;
    line-height: 1.88;
    letter-spacing: 0.32px;
    font-weight: 300;
    color: #ffffff; }
    @media (max-width: 767px) {
      .indexPage .item5 .des {
        max-width: 228px;
        margin: 0 auto; } }

@media (max-width: 991px) {
  .scalistPage main {
    padding-bottom: 95px; } }

.scalistPage .item1 {
  margin-top: 85px;
  margin-bottom: 75px; }
  @media (max-width: 991px) {
    .scalistPage .item1 {
      margin-top: 55px;
      margin-bottom: 25px; } }
  .scalistPage .item1 .Breadcrumb ul {
    display: flex; }
    .scalistPage .item1 .Breadcrumb ul li {
      font-size: 1rem;
      line-height: 2.25;
      letter-spacing: 0px;
      font-weight: 500;
      color: #6d6d6d;
      display: flex;
      align-items: center; }
      .scalistPage .item1 .Breadcrumb ul li:last-child {
        color: #0073d8; }
      .scalistPage .item1 .Breadcrumb ul li:not(:last-child) {
        margin-right: 15px; }
      @media (max-width: 767px) {
        .scalistPage .item1 .Breadcrumb ul li {
          line-height: 1.25; } }
    .scalistPage .item1 .Breadcrumb ul span {
      margin-right: 15px;
      position: relative;
      top: -1px; }

@media (min-width: 1200px) {
  .scalistPage .item2 .it2-bx {
    max-width: 1120px; } }

.scadetailPage .item1 {
  margin-top: 70px;
  margin-bottom: 65px; }
  @media (max-width: 991px) {
    .scadetailPage .item1 {
      margin-top: 30px;
      margin-bottom: 25px; } }
  .scadetailPage .item1 .Breadcrumb ul {
    display: flex; }
    .scadetailPage .item1 .Breadcrumb ul li {
      font-size: 1rem;
      line-height: 2.25;
      letter-spacing: 0px;
      font-weight: 500;
      color: #6d6d6d;
      display: flex;
      align-items: center; }
      .scadetailPage .item1 .Breadcrumb ul li:last-child {
        color: #0073d8; }
      .scadetailPage .item1 .Breadcrumb ul li:not(:last-child) {
        margin-right: 15px; }
      @media (max-width: 767px) {
        .scadetailPage .item1 .Breadcrumb ul li {
          line-height: 1.25; } }
      .scadetailPage .item1 .Breadcrumb ul li a {
        font-size: 1rem;
        line-height: 2.25;
        letter-spacing: 0px;
        font-weight: 500;
        color: #6d6d6d;
        position: relative; }
        @media (max-width: 767px) {
          .scadetailPage .item1 .Breadcrumb ul li a {
            line-height: 1.25; } }
        .scadetailPage .item1 .Breadcrumb ul li a::before {
          position: absolute;
          content: '';
          bottom: -5px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #6d6d6d;
          opacity: 0;
          transition: .4s ease; }
        @media (min-width: 768px) {
          .scadetailPage .item1 .Breadcrumb ul li a:hover::before {
            opacity: 1; } }
    .scadetailPage .item1 .Breadcrumb ul span {
      margin-right: 15px;
      position: relative;
      top: -1px; }

.scadetailPage .item2 .it2-bx {
  display: flex; }
  @media (max-width: 991px) {
    .scadetailPage .item2 .it2-bx {
      display: block;
      margin-bottom: 65px; } }
  .scadetailPage .item2 .it2-bx .l-bx {
    max-width: 495px;
    width: 100%;
    margin-right: 30px; }
    @media (max-width: 991px) {
      .scadetailPage .item2 .it2-bx .l-bx {
        margin: 0 auto; } }
  .scadetailPage .item2 .it2-bx .r-bx {
    width: calc(100% - 525px); }
    @media (max-width: 991px) {
      .scadetailPage .item2 .it2-bx .r-bx {
        width: 100%; } }
    .scadetailPage .item2 .it2-bx .r-bx .ti {
      position: relative;
      font-size: 1.625rem;
      line-height: 1.3;
      letter-spacing: 0px;
      font-weight: 500;
      color: #0073d8;
      margin-bottom: 50px; }
      @media (max-width: 991px) {
        .scadetailPage .item2 .it2-bx .r-bx .ti {
          margin-top: 40px;
          margin-bottom: 35px; } }
      .scadetailPage .item2 .it2-bx .r-bx .ti::before {
        position: absolute;
        content: '';
        top: -5px;
        left: -16px;
        transform-origin: bottom;
        transform: skewX(-32deg);
        background-color: #0073d8;
        opacity: .2;
        border-radius: 5px 0 5px 0;
        width: 19px;
        height: 32px; }
        @media (max-width: 991px) {
          .scadetailPage .item2 .it2-bx .r-bx .ti::before {
            border-radius: 2.5px 0 2.5px 0;
            width: 19px;
            height: 29px;
            top: 0px;
            left: -17px; } }
    .scadetailPage .item2 .it2-bx .r-bx .des-ti {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0px;
      font-weight: 300;
      color: #0073d8;
      padding-bottom: 15px;
      border-bottom: 1px solid #0073d8;
      margin-bottom: 10px; }
      @media (max-width: 991px) {
        .scadetailPage .item2 .it2-bx .r-bx .des-ti {
          margin-bottom: 15px; } }
    .scadetailPage .item2 .it2-bx .r-bx .editor_Content {
      font-size: 1rem;
      line-height: 1.88;
      letter-spacing: 0.32px;
      font-weight: 300;
      color: #6d6d6d; }
      @media (max-width: 767px) {
        .scadetailPage .item2 .it2-bx .r-bx .editor_Content {
          padding: 0; } }
    .scadetailPage .item2 .it2-bx .r-bx .editor_Content ul {
      padding-left: 0px;
      list-style: none;
      margin-left: 5px; }
      .scadetailPage .item2 .it2-bx .r-bx .editor_Content ul li {
        position: relative;
        padding-left: 10px; }
        .scadetailPage .item2 .it2-bx .r-bx .editor_Content ul li::before {
          position: absolute;
          content: '';
          left: 0;
          top: 14px;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #6d6d6d; }
    .scadetailPage .item2 .it2-bx .r-bx .down-bx {
      margin-top: 38px; }
      @media (max-width: 991px) {
        .scadetailPage .item2 .it2-bx .r-bx .down-bx {
          margin-top: 40px; } }
      .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li {
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        align-items: center; }
        @media (max-width: 991px) {
          .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li {
            padding: 8px 0; } }
        .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li:not(:last-child) {
          border-bottom: 1px solid #dcdcdc; }
        .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li div {
          font-size: 1.125rem;
          line-height: 2.67;
          letter-spacing: 0px;
          font-weight: 500;
          color: #6d6d6d; }
        .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li a {
          display: block;
          width: 40px;
          height: 40px;
          position: relative; }
          @media (max-width: 991px) {
            .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li a {
              width: 36px;
              height: 36px; } }
          .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li a::before {
            position: absolute;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 48px;
            height: 48px;
            border: 1px solid #0073d8;
            border-radius: 50%;
            opacity: 0;
            transition: .4s; }
          @media (min-width: 992px) {
            .scadetailPage .item2 .it2-bx .r-bx .down-bx ul li a:hover::before {
              opacity: 1; } }

.scadetailPage .item3 .des-ti {
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: 0px;
  font-weight: 300;
  color: #0073d8;
  margin-top: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #0073d8;
  text-align: left; }
  @media (max-width: 991px) {
    .scadetailPage .item3 .des-ti {
      margin-top: 0; } }

.scadetailPage .item3 .it3-bx .editor_Content {
  margin-top: 40px;
  margin-bottom: 45px; }
  @media (max-width: 767px) {
    .scadetailPage .item3 .it3-bx .editor_Content {
      padding: 0; } }

.scadetailPage .item3 .it3-bx .sh-btn1 {
  margin: 0 auto; }

.scadetailPage .item3 .it3-bx .it3-obx {
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid #0073d8; }
  @media (max-width: 991px) {
    .scadetailPage .item3 .it3-bx .it3-obx {
      margin-bottom: 45px; } }

.scadetailPage .item3 .it3-bx .sh-mbtn {
  color: #0073d8; }
  .scadetailPage .item3 .it3-bx .sh-mbtn::before, .scadetailPage .item3 .it3-bx .sh-mbtn::after {
    background-color: #0073d8; }

.scadetailPage .item4 {
  margin-top: 60px; }
  @media (max-width: 991px) {
    .scadetailPage .item4 {
      margin-top: 70px;
      margin-bottom: 15px; } }
  .scadetailPage .item4 .re-ti {
    font-size: 1.625rem;
    line-height: 1.38;
    letter-spacing: 0.65px;
    font-weight: 500;
    color: #0073d8;
    text-align: center;
    margin-bottom: 35px; }
  .scadetailPage .item4 .thr-list > li {
    width: 33.33333%;
    margin-bottom: 100px;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .scadetailPage .item4 .thr-list > li {
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 25px; } }
    @media (max-width: 767px) {
      .scadetailPage .item4 .thr-list > li:not(:last-child) {
        border-bottom: 1px solid #d2d2d2; } }
  .scadetailPage .item4 .thr-list {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 992px) {
      .scadetailPage .item4 .thr-list li:not(:last-child) a .top-img {
        border-right: 1px solid #d2d2d2; } }
    @media (max-width: 991px) and (min-width: 768px) {
      .scadetailPage .item4 .thr-list li:not(:nth-child(3n)) a .top-img {
        border-right: 1px solid #d2d2d2; }
      .scadetailPage .item4 .thr-list li:last-child a .top-img {
        border-right: none; } }
    .scadetailPage .item4 .thr-list li a {
      display: block; }
      .scadetailPage .item4 .thr-list li a .top-img {
        position: relative;
        padding: 0 16px;
        margin-bottom: 5px;
        padding-bottom: 100%; }
        @media (max-width: 767px) {
          .scadetailPage .item4 .thr-list li a .top-img {
            margin-bottom: 0px; } }
        .scadetailPage .item4 .thr-list li a .top-img::before {
          position: absolute;
          content: '';
          width: calc(100% - 32px);
          height: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: #0073d8;
          pointer-events: none;
          opacity: 0;
          transition: .4s;
          border-radius: 15px 0 15px 0; }
        .scadetailPage .item4 .thr-list li a .top-img::after {
          position: absolute;
          content: '';
          background: url(../images/b_bt.png) no-repeat center center/cover;
          width: 67px;
          height: 67px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: .2s ease;
          pointer-events: none;
          opacity: 0; }
        .scadetailPage .item4 .thr-list li a .top-img img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1; }
      .scadetailPage .item4 .thr-list li a .ti {
        font-size: 1.625rem;
        line-height: 1.85;
        letter-spacing: 0px;
        font-weight: 500;
        color: #0073d8;
        padding: 0 16px; }
        @media (max-width: 767px) {
          .scadetailPage .item4 .thr-list li a .ti {
            padding: 0; } }
      @media (min-width: 768px) {
        .scadetailPage .item4 .thr-list li a:hover .top-img::before {
          opacity: .5; }
        .scadetailPage .item4 .thr-list li a:hover .top-img::after {
          opacity: 1; } }
    .scadetailPage .item4 .thr-list .editor_Content {
      font-size: 0.875rem;
      line-height: 2.14;
      letter-spacing: 0.28px;
      font-weight: 300;
      color: #6d6d6d;
      padding: 0 16px; }
      @media (max-width: 767px) {
        .scadetailPage .item4 .thr-list .editor_Content {
          padding: 0; } }
    .scadetailPage .item4 .thr-list .editor_Content ul {
      padding-left: 0px;
      list-style: none;
      margin-left: 5px; }
      .scadetailPage .item4 .thr-list .editor_Content ul li {
        position: relative;
        padding-left: 10px; }
        .scadetailPage .item4 .thr-list .editor_Content ul li::before {
          position: absolute;
          content: '';
          left: 0;
          top: 14px;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #6d6d6d; }

.rulePage .item1 {
  margin-top: 70px;
  margin-bottom: 90px; }
  @media (max-width: 767px) {
    .rulePage .item1 {
      margin-top: 60px;
      margin-bottom: 70px; } }

.aboutPage .sh-banner1 {
  height: 370px; }
  @media (max-width: 991px) {
    .aboutPage .sh-banner1 {
      background-image: none; } }
  @media (max-width: 768px) {
    .aboutPage .sh-banner1 {
      height: 0;
      padding-bottom: 84.3%; } }
  .aboutPage .sh-banner1 .bgcover.pc {
    height: 370px; }
  @media (max-width: 767px) {
    .aboutPage .sh-banner1 .bgcover.mo {
      padding-bottom: 84.3%; } }
  @media (max-width: 991px) {
    .aboutPage .sh-banner1 .center-bx {
      display: flex;
      padding-top: 7%;
      justify-content: center; } }
  @media (max-width: 767px) {
    .aboutPage .sh-banner1 .center-bx {
      display: block;
      padding-top: 10%; } }
  @media (max-width: 991px) {
    .aboutPage .sh-banner1 .center-bx .r-img {
      max-width: calc(100% - 381px); } }
  @media (max-width: 768px) {
    .aboutPage .sh-banner1 .center-bx .r-img {
      max-width: 65%; } }
  .aboutPage .sh-banner1 .center-bx .l-bx {
    margin-right: 13%; }
    @media (max-width: 991px) {
      .aboutPage .sh-banner1 .center-bx .l-bx {
        margin-right: 0;
        margin: 0; } }
  .aboutPage .sh-banner1 .center-bx .l-bx .des {
    font-size: 1.25rem; }
    @media (max-width: 768px) {
      .aboutPage .sh-banner1 .center-bx .l-bx .des {
        margin-top: 0;
        font-size: 1rem;
        margin-top: 15px; } }

.aboutPage .item1 {
  margin-top: 90px;
  margin-bottom: 130px; }
  @media (max-width: 991px) {
    .aboutPage .item1 {
      margin-top: 85px;
      margin-bottom: 60px; } }

.articlesearchPage .item1 {
  margin-top: 70px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .articlesearchPage .item1 {
      margin-bottom: 25px; } }
  .articlesearchPage .item1 .key-bx {
    display: flex;
    align-items: center; }
    .articlesearchPage .item1 .key-bx .ti {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0.45px;
      font-weight: 300;
      color: #6d6d6d; }
    .articlesearchPage .item1 .key-bx .tag {
      background-color: #0073d8;
      color: #ffffff;
      margin-left: 10px; }

.articlesearchPage .item2 {
  margin-bottom: 70px; }
  @media (max-width: 767px) {
    .articlesearchPage .item2 {
      margin-bottom: 40px; } }
  .articlesearchPage .item2 ul {
    display: flex;
    padding-bottom: 6px;
    border-bottom: 1px solid #0073d8; }
    .articlesearchPage .item2 ul li:first-child {
      margin-right: 6px; }
    .articlesearchPage .item2 ul li a {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0.45px;
      font-weight: 300;
      color: #0073d8;
      transition: .4s;
      border-radius: 10px 0 10px 0;
      padding: 10px 15px;
      display: block; }
      @media (min-width: 768px) {
        .articlesearchPage .item2 ul li a:hover {
          color: #ffffff;
          background-color: #0073d8; } }
      @media (max-width: 767px) {
        .articlesearchPage .item2 ul li a {
          border-radius: 5px 0 5px 0; } }
    .articlesearchPage .item2 ul li.active a {
      color: #ffffff;
      background-color: #0073d8; }

.articlesearchPage .item3 .it3-bx {
  display: flex; }
  @media (max-width: 767px) {
    .articlesearchPage .item3 .it3-bx {
      display: block; } }
  .articlesearchPage .item3 .it3-bx .r-bx {
    order: 2;
    width: 17.6%; }
    @media (max-width: 767px) {
      .articlesearchPage .item3 .it3-bx .r-bx {
        width: 100%;
        margin-bottom: 40px; } }
    .articlesearchPage .item3 .it3-bx .r-bx .ti {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0.45px;
      font-weight: 300;
      color: #0073d8;
      padding-bottom: 15px;
      padding-left: 12px;
      border-left: 1px solid #dcdcdc; }
      @media (max-width: 767px) {
        .articlesearchPage .item3 .it3-bx .r-bx .ti {
          padding-left: 0;
          border-left: none; } }
    .articlesearchPage .item3 .it3-bx .r-bx .tag-bx {
      padding-left: 12px;
      border-left: 1px solid #dcdcdc;
      position: relative; }
      @media (max-width: 767px) {
        .articlesearchPage .item3 .it3-bx .r-bx .tag-bx {
          padding-left: 0;
          border-left: none; } }
      .articlesearchPage .item3 .it3-bx .r-bx .tag-bx::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: -3px;
        width: 4px;
        height: 9px;
        background-color: #ffffff; }
      .articlesearchPage .item3 .it3-bx .r-bx .tag-bx a {
        margin-bottom: 10px; }
  .articlesearchPage .item3 .it3-bx .l-bx {
    order: 1;
    width: 79.3%;
    margin-right: 3%; }
    @media (max-width: 767px) {
      .articlesearchPage .item3 .it3-bx .l-bx {
        width: 100%;
        margin-right: 0; } }
    .articlesearchPage .item3 .it3-bx .l-bx ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      @media (max-width: 767px) {
        .articlesearchPage .item3 .it3-bx .l-bx ul {
          display: block; } }
      .articlesearchPage .item3 .it3-bx .l-bx ul li {
        width: 48.5%;
        margin-bottom: 24px; }
        @media (max-width: 767px) {
          .articlesearchPage .item3 .it3-bx .l-bx ul li {
            width: 100%;
            margin-bottom: 30px; } }
        @media (min-width: 768px) {
          .articlesearchPage .item3 .it3-bx .l-bx ul li:nth-last-child(2) {
            margin-bottom: 0; } }
        .articlesearchPage .item3 .it3-bx .l-bx ul li:last-child {
          margin-bottom: 0; }
        .articlesearchPage .item3 .it3-bx .l-bx ul li a {
          position: relative; }
          .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx {
            position: relative;
            margin-bottom: 24px;
            border-radius: 15px 0 15px 0; }
            @media (max-width: 767px) {
              .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx {
                border-radius: 7px 0 7px 0;
                margin-bottom: 15px; } }
            .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx img {
              border-radius: 15px 0 15px 0; }
              @media (max-width: 767px) {
                .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx img {
                  border-radius: 7px 0 7px 0; } }
            .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx::before {
              position: absolute;
              content: '';
              width: 100%;
              height: 100%;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              background-color: #0073d8;
              pointer-events: none;
              opacity: 0;
              transition: .4s;
              border-radius: 15px 0 15px 0; }
              @media (max-width: 767px) {
                .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx::before {
                  border-radius: 7px 0 7px 0; } }
            .articlesearchPage .item3 .it3-bx .l-bx ul li a .img-bx::after {
              position: absolute;
              content: '';
              background: url(../images/b_bt.png) no-repeat center center/cover;
              width: 67px;
              height: 67px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              transition: .2s ease;
              pointer-events: none;
              opacity: 0; }
          @media (min-width: 768px) {
            .articlesearchPage .item3 .it3-bx .l-bx ul li a:hover .img-bx::before {
              opacity: .5; }
            .articlesearchPage .item3 .it3-bx .l-bx ul li a:hover .img-bx::after {
              opacity: 1; } }
          .articlesearchPage .item3 .it3-bx .l-bx ul li a .date {
            font-size: 1rem;
            line-height: 1;
            letter-spacing: 0.4px;
            font-weight: 300;
            color: #bebebe;
            margin-bottom: 10px; }
            @media (max-width: 767px) {
              .articlesearchPage .item3 .it3-bx .l-bx ul li a .date {
                margin-bottom: 13px; } }
          .articlesearchPage .item3 .it3-bx .l-bx ul li a .des-ti {
            font-size: 1.625rem;
            line-height: 1.38;
            letter-spacing: 0.6px;
            font-weight: 500;
            color: #0073d8;
            margin-bottom: 3px; }
        .articlesearchPage .item3 .it3-bx .l-bx ul li .des {
          font-size: 1rem;
          line-height: 1.88;
          letter-spacing: 0.3px;
          font-weight: 300;
          color: #6d6d6d;
          overflow: hidden;
          display: -webkit-inline-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          vertical-align: middle; }
        .articlesearchPage .item3 .it3-bx .l-bx ul li .tag-bx {
          margin-top: 20px;
          height: 82px;
          overflow: hidden; }
          @media (max-width: 767px) {
            .articlesearchPage .item3 .it3-bx .l-bx ul li .tag-bx {
              margin-top: 20px;
              height: auto;
              max-height: 73px; } }
          .articlesearchPage .item3 .it3-bx .l-bx ul li .tag-bx a {
            margin-right: 5px;
            margin-bottom: 5px; }

.articlesearchPage .item3 .page {
  margin-top: 40px;
  margin-bottom: 100px; }
  @media (max-width: 767px) {
    .articlesearchPage .item3 .page {
      margin-bottom: 130px;
      margin-top: 50px; } }

@media (max-width: 991px) and (min-width: 768px) {
  .articlesearchPage .container {
    max-width: 100%; } }

.articlelistPage .item2 {
  margin-top: 70px; }
  @media (max-width: 767px) {
    .articlelistPage .item2 {
      margin-top: 40px; } }

.hiddendiv {
  display: none; }

@media (max-width: 991px) and (min-width: 768px) {
  .contactPage .container {
    max-width: 100%; } }

.contactPage .item {
  margin-top: 90px;
  margin-bottom: 100px;
  position: relative; }
  @media (max-width: 767px) {
    .contactPage .item {
      margin-top: 75px;
      margin-bottom: 70px; } }
  .contactPage .item .container {
    position: relative; }
  .contactPage .item .b-ti {
    font-size: 3.75rem;
    line-height: 1;
    letter-spacing: 1.5px;
    font-weight: 500;
    color: #0073d8;
    margin-bottom: 60px; }
    @media (max-width: 767px) {
      .contactPage .item .b-ti {
        font-size: 2.5rem;
        margin-bottom: 20px;
        position: relative; }
        .contactPage .item .b-ti::before {
          position: absolute;
          content: '';
          left: -125px;
          top: -36px;
          width: 115px;
          height: 175px;
          transform-origin: bottom;
          transform: skewX(-32deg);
          background-color: #bce0ff;
          opacity: .2;
          border-radius: 15px 0 15px 0;
          z-index: -1; } }
  .contactPage .item .con-bx {
    display: flex;
    margin-bottom: 70px; }
    @media (max-width: 767px) {
      .contactPage .item .con-bx {
        display: block;
        margin-bottom: 35px; } }
    .contactPage .item .con-bx .l-bx {
      background-color: #c7e5ff;
      padding-top: 70px;
      padding-left: 47px;
      padding-right: 20px;
      padding-bottom: 70px;
      width: 41.2%; }
      @media (max-width: 767px) {
        .contactPage .item .con-bx .l-bx {
          width: 100%;
          padding: 50px 20px 20px 20px; } }
      .contactPage .item .con-bx .l-bx ul {
        max-width: 305px; }
        .contactPage .item .con-bx .l-bx ul li {
          position: relative;
          padding-left: 50px;
          margin-bottom: 27px; }
          @media (max-width: 767px) {
            .contactPage .item .con-bx .l-bx ul li {
              margin-bottom: 20px; } }
          .contactPage .item .con-bx .l-bx ul li::before {
            position: absolute;
            content: '';
            left: 0;
            width: 35px;
            height: 35px; }
          .contactPage .item .con-bx .l-bx ul li .ti {
            font-size: 1.125rem;
            line-height: 1.75;
            letter-spacing: 0.3px;
            font-weight: 500;
            color: #004887;
            margin-bottom: 2px; }
          .contactPage .item .con-bx .l-bx ul li .des {
            font-size: 1rem;
            line-height: 1.75;
            letter-spacing: 0.3px;
            font-weight: 300;
            color: #004887; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(1)::before {
            background: url(../images/contact/map_icon_mb.png) center center/cover; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(2)::before {
            background: url(../images/contact/email_icon_mb.png) center center/cover; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(3)::before {
            background: url(../images/contact/phone_icon_mb.png) center center/cover; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(4)::before {
            background: url(../images/contact/fax_icon_mb.png) center center/cover; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(5)::before {
            background: url(../images/contact/editor_icon_mb.png) center center/cover; }
          .contactPage .item .con-bx .l-bx ul li:nth-child(6)::before {
            background: url(../images/contact/bank_icon_mb.png) center center/cover; }
    .contactPage .item .con-bx .r-bx {
      padding-left: 8%;
      padding-right: 7%;
      width: 59%;
      padding-top: 70px;
      background-color: #f6f6f6; }
      @media (max-width: 767px) {
        .contactPage .item .con-bx .r-bx {
          width: 100%;
          padding: 0 15px;
          padding-top: 50px;
          padding-bottom: 45px; } }
      .contactPage .item .con-bx .r-bx .form-bx .flex-bx {
        display: flex; }
        @media (max-width: 767px) {
          .contactPage .item .con-bx .r-bx .form-bx .flex-bx {
            display: block; } }
      .contactPage .item .con-bx .r-bx .form-bx .input-bx {
        width: 100%; }
        @media (max-width: 767px) {
          .contactPage .item .con-bx .r-bx .form-bx .input-bx {
            margin-top: 15px; } }
      .contactPage .item .con-bx .r-bx .form-bx .form-group {
        margin-bottom: 20px; }
      .contactPage .item .con-bx .r-bx .form-bx label {
        font-size: 1.125rem;
        line-height: 1;
        letter-spacing: 0.45px;
        font-weight: 500;
        color: #6d6d6d;
        margin-right: 5%;
        min-width: 84px; }
        .contactPage .item .con-bx .r-bx .form-bx label i {
          color: #0073d8; }
        @media (max-width: 767px) {
          .contactPage .item .con-bx .r-bx .form-bx label {
            font-size: 1rem; } }
      .contactPage .item .con-bx .r-bx .form-bx input {
        height: 40px;
        border-radius: 0;
        background-color: #ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 0);
        transition: .4s;
        padding-left: 11px; }
        .contactPage .item .con-bx .r-bx .form-bx input:focus {
          border-bottom: 1px solid #0073d8; }
      .contactPage .item .con-bx .r-bx .form-bx .form-textbx {
        margin-bottom: 30px; }
        @media (max-width: 767px) {
          .contactPage .item .con-bx .r-bx .form-bx .form-textbx {
            margin-bottom: 0; } }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .text-bx {
          width: 100%; }
          @media (max-width: 767px) {
            .contactPage .item .con-bx .r-bx .form-bx .form-textbx .text-bx {
              margin-top: 15px; } }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .focus.textarea-wrapper {
          border-bottom: 1px solid #0073d8; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .textarea-wrapper {
          height: 160px;
          overflow: hidden;
          border: none;
          padding-top: 5px;
          padding-bottom: 5px;
          background-color: #ffffff;
          transition: .4s;
          border-bottom: 1px solid rgba(255, 255, 255, 0); }
          @media (max-width: 767px) {
            .contactPage .item .con-bx .r-bx .form-bx .form-textbx .textarea-wrapper {
              height: 180px; } }
          .contactPage .item .con-bx .r-bx .form-bx .form-textbx .textarea-wrapper textarea {
            min-height: 148px;
            height: 0;
            overflow: hidden;
            border-bottom: none;
            padding-top: 5px;
            padding-left: 11px;
            text-rendering: auto;
            -webkit-writing-mode: horizontal-tb !important;
            background-color: #ffffff;
            resize: none;
            border: none;
            font-family: "Noto Sans TC", "微軟正黑體", Verdana, sans-serif;
            cursor: text;
            line-height: 1; }
            @media (max-width: 767px) {
              .contactPage .item .con-bx .r-bx .form-bx .form-textbx .textarea-wrapper textarea {
                min-height: 168px; } }
            .contactPage .item .con-bx .r-bx .form-bx .form-textbx .textarea-wrapper textarea:focus {
              border: none; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
          background-color: #000000; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerRail {
          background-color: #000000;
          width: 1px; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .mCSB_scrollTools {
          right: -5px; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .mCSB_inside > .mCSB_container {
          margin-right: 13px; }
        .contactPage .item .con-bx .r-bx .form-bx .form-textbx .mCSB_scrollTools .mCSB_draggerContainer {
          top: 0px;
          left: -12px; }
      .contactPage .item .con-bx .r-bx .form-bx .help-block {
        margin-top: 5px;
        font-size: 0.875rem;
        line-height: 1;
        letter-spacing: 0.35px;
        font-weight: 300;
        color: #0073d8; }
      .contactPage .item .con-bx .r-bx .form-bx .send-btn {
        width: 96px;
        height: 48px;
        margin-left: auto; }
        @media (max-width: 767px) {
          .contactPage .item .con-bx .r-bx .form-bx .send-btn div {
            line-height: 3.1; } }
  .contactPage .item .l-sqbx {
    position: absolute;
    left: -370px;
    top: 30px;
    z-index: -1; }
    @media (max-width: 767px) {
      .contactPage .item .l-sqbx {
        display: none; } }
    .contactPage .item .l-sqbx .t-bx, .contactPage .item .l-sqbx .b-bx {
      display: flex; }
    .contactPage .item .l-sqbx .b-bx {
      margin-left: 15px; }
      .contactPage .item .l-sqbx .b-bx .sq:first-child {
        position: relative;
        top: -50px; }
    .contactPage .item .l-sqbx .t-bx .sq:first-child {
      margin-top: 60px;
      position: relative;
      left: -50px; }
    .contactPage .item .l-sqbx .sq {
      width: 150px;
      height: 250px;
      transform-origin: bottom;
      transform: skewX(-32deg);
      background-color: #bce0ff;
      opacity: .2;
      border-radius: 15px 0 15px 0;
      margin: 0 15px;
      margin-bottom: 30px; }
  .contactPage .item .r-sqbx {
    position: absolute;
    right: -240px;
    bottom: 330px;
    z-index: -1; }
    @media (max-width: 767px) {
      .contactPage .item .r-sqbx {
        display: none; } }
    .contactPage .item .r-sqbx .t-bx {
      display: flex; }
      .contactPage .item .r-sqbx .t-bx .sq:nth-child(2) {
        position: relative;
        top: 130px;
        left: -70px; }
    .contactPage .item .r-sqbx .sq {
      width: 150px;
      height: 250px;
      transform-origin: bottom;
      transform: skewX(-32deg);
      background-color: #bce0ff;
      opacity: .2;
      border-radius: 15px 0 15px 0;
      margin: 0 15px;
      margin-bottom: 30px; }
  .contactPage .item .map-bx iframe {
    height: 320px; }

.searchlistPage .item1 {
  margin-top: 70px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .searchlistPage .item1 {
      margin-bottom: 25px; } }
  .searchlistPage .item1 .key-bx {
    display: flex;
    align-items: center; }
    .searchlistPage .item1 .key-bx .ti {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0.45px;
      font-weight: 300;
      color: #6d6d6d; }
    .searchlistPage .item1 .key-bx .s-des {
      font-size: 1.125rem;
      line-height: 1;
      letter-spacing: 0.2px;
      font-weight: 300;
      color: #0073d8;
      position: relative;
      margin-left: 5px; }
      .searchlistPage .item1 .key-bx .s-des::before {
        position: absolute;
        content: '';
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #0073d8; }

.articledetailPage .item2 {
  margin-top: 70px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .articledetailPage .item2 {
      margin-bottom: 25px; } }

.articledetailPage .item3 .t-bx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  @media (max-width: 767px) {
    .articledetailPage .item3 .t-bx {
      display: block; } }
  .articledetailPage .item3 .t-bx .tit-blsq {
    max-height: 52px;
    overflow: hidden; }
    @media (max-width: 767px) {
      .articledetailPage .item3 .t-bx .tit-blsq {
        margin-top: 20px;
        margin-bottom: 20px;
        max-height: initial;
        overflow: inherit; } }
    .articledetailPage .item3 .t-bx .tit-blsq div {
      font-size: 26px; }
      .articledetailPage .item3 .t-bx .tit-blsq div::before {
        top: 2px;
        left: 6px; }
        @media (max-width: 767px) {
          .articledetailPage .item3 .t-bx .tit-blsq div::before {
            top: -3px;
            left: 0;
            width: 40px;
            height: 60px; } }
  .articledetailPage .item3 .t-bx .date {
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.4px;
    font-weight: 500;
    color: #bebebe; }

.articledetailPage .item3 .it3-bx {
  display: block; }
  .articledetailPage .item3 .it3-bx .editor_Content {
    margin-top: 15px;
    margin-bottom: 60px; }
    @media (max-width: 767px) {
      .articledetailPage .item3 .it3-bx .editor_Content {
        margin-bottom: 40px; } }
  .articledetailPage .item3 .it3-bx .it3-inbx {
    padding-bottom: 30px;
    border-bottom: 1px solid #0073d8; }
    @media (max-width: 767px) {
      .articledetailPage .item3 .it3-bx .it3-inbx {
        padding-bottom: 45px; } }
  .articledetailPage .item3 .it3-bx .sh-mbtn {
    color: #0073d8;
    margin-top: 32px; }
    .articledetailPage .item3 .it3-bx .sh-mbtn::before, .articledetailPage .item3 .it3-bx .sh-mbtn::after {
      background-color: #0073d8; }
  .articledetailPage .item3 .it3-bx .tag-bx .tag {
    margin-right: 10px;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      .articledetailPage .item3 .it3-bx .tag-bx .tag {
        margin-right: 5px;
        margin-bottom: 5px; } }

.articledetailPage .item4 {
  margin-top: 60px;
  margin-bottom: 75px; }
  @media (max-width: 991px) {
    .articledetailPage .item4 {
      margin-top: 70px;
      margin-bottom: 15px; } }
  .articledetailPage .item4 .re-ti {
    font-size: 1.625rem;
    line-height: 1.38;
    letter-spacing: 0.65px;
    font-weight: 500;
    color: #0073d8;
    text-align: center;
    margin-bottom: 35px; }
  .articledetailPage .item4 .thr-list > li {
    width: 33.33333%;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .articledetailPage .item4 .thr-list > li {
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 25px; } }
    @media (max-width: 767px) {
      .articledetailPage .item4 .thr-list > li:not(:last-child) {
        border-bottom: 1px solid #d2d2d2; } }
  .articledetailPage .item4 .thr-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .articledetailPage .item4 .thr-list li {
      width: 32%; }
      .articledetailPage .item4 .thr-list li:not(:last-child) {
        margin-right: 2%; }
      @media (max-width: 767px) {
        .articledetailPage .item4 .thr-list li {
          width: 100%;
          margin-bottom: 25px;
          border-bottom: none !important; } }
      .articledetailPage .item4 .thr-list li a {
        position: relative; }
        .articledetailPage .item4 .thr-list li a .img-bx {
          position: relative;
          margin-bottom: 24px;
          border-radius: 15px 0 15px 0; }
          @media (max-width: 767px) {
            .articledetailPage .item4 .thr-list li a .img-bx {
              border-radius: 7px 0 7px 0;
              margin-bottom: 15px; } }
          .articledetailPage .item4 .thr-list li a .img-bx img {
            border-radius: 15px 0 15px 0; }
            @media (max-width: 767px) {
              .articledetailPage .item4 .thr-list li a .img-bx img {
                border-radius: 7px 0 7px 0; } }
          .articledetailPage .item4 .thr-list li a .img-bx::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: #0073d8;
            pointer-events: none;
            opacity: 0;
            transition: .4s;
            border-radius: 15px 0 15px 0; }
            @media (max-width: 767px) {
              .articledetailPage .item4 .thr-list li a .img-bx::before {
                border-radius: 7px 0 7px 0; } }
          .articledetailPage .item4 .thr-list li a .img-bx::after {
            position: absolute;
            content: '';
            background: url(../images/b_bt.png) no-repeat center center/cover;
            width: 67px;
            height: 67px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: .2s ease;
            pointer-events: none;
            opacity: 0; }
        @media (min-width: 768px) {
          .articledetailPage .item4 .thr-list li a:hover .img-bx::before {
            opacity: .5; }
          .articledetailPage .item4 .thr-list li a:hover .img-bx::after {
            opacity: 1; } }
        .articledetailPage .item4 .thr-list li a .date {
          font-size: 1rem;
          line-height: 1;
          letter-spacing: 0.4px;
          font-weight: 300;
          color: #bebebe;
          margin-bottom: 10px; }
          @media (max-width: 767px) {
            .articledetailPage .item4 .thr-list li a .date {
              margin-bottom: 13px; } }
        .articledetailPage .item4 .thr-list li a .des-ti {
          font-size: 1.625rem;
          line-height: 1.38;
          letter-spacing: 0.6px;
          font-weight: 500;
          color: #0073d8;
          margin-bottom: 3px;
          height: 70px;
          overflow: hidden; }
          @media (max-width: 767px) {
            .articledetailPage .item4 .thr-list li a .des-ti {
              height: auto; } }
      .articledetailPage .item4 .thr-list li .des {
        font-size: 1rem;
        line-height: 1.88;
        letter-spacing: 0.3px;
        font-weight: 300;
        color: #6d6d6d;
        overflow: hidden;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        vertical-align: middle; }
      .articledetailPage .item4 .thr-list li .tag-bx {
        margin-top: 20px;
        height: 82px;
        overflow: hidden; }
        @media (max-width: 767px) {
          .articledetailPage .item4 .thr-list li .tag-bx {
            margin-top: 20px;
            height: auto;
            max-height: 73px; } }
        .articledetailPage .item4 .thr-list li .tag-bx a {
          margin-right: 5px;
          margin-bottom: 5px; }

.bgcover {
  width: 100%;
  height: 0;
  background: 50%/cover; }

.mo {
  display: none; }
  @media (max-width: 767px) {
    .mo {
      display: block; } }

.pc {
  display: none; }
  @media (min-width: 768px) {
    .pc {
      display: block; } }

.container {
  padding: 0 33px;
  margin: 0 auto;
  width: 100%; }
  @media (max-width: 767px) {
    .container {
      padding: 0 25px; } }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1088px; } }

#top-menu-ul {
  position: relative;
  z-index: 1; }
  @media (max-width: 767px) {
    #top-menu-ul {
      margin: 0 auto 35px; } }
  @media (min-width: 768px) {
    #top-menu-ul {
      margin: 0 auto 50px; } }
  #top-menu-ul .item_Menu {
    margin: 0 auto;
    overflow: hidden;
    height: 30px; }
  #top-menu-ul .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: center; }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-flex; }
    #top-menu-ul .item_menu_Box li {
      height: 30px; }
    #top-menu-ul .item_menu_Box li a {
      position: relative;
      display: block;
      line-height: 1;
      letter-spacing: 0.6px;
      border-bottom: 1px solid #dbdbdb;
      font-size: 0.875rem;
      color: #000000; }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li a {
          padding: 7px 0 3px;
          margin: 0 20px 0 0; } }
      @media (min-width: 768px) {
        #top-menu-ul .item_menu_Box li a {
          margin: 0 10px;
          padding: 7px 0; } }
    #top-menu-ul .item_menu_Box li.active a {
      border-color: black;
      color: black; }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 10;
    background-color: #000000; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 0; }
  .flex-direction-nav a.flex-next {
    right: -18px; }
  .flex-direction-nav a.flex-prev {
    left: -18px; }

.open_flexslider .flex-direction-nav {
  display: block; }

.sh-btn1 {
  border-radius: 15px 0 15px 0;
  border: 1px solid #0073d8;
  width: 162px;
  height: 49px;
  text-align: center;
  display: block;
  transition: .4s; }
  .sh-btn1 div {
    font-size: 1rem;
    line-height: 2.9;
    letter-spacing: 0.32px;
    font-weight: 500;
    color: #0073d8;
    transition: .4s; }
  @media (min-width: 768px) {
    .sh-btn1:hover {
      background-color: #0073d8; }
      .sh-btn1:hover div {
        color: #ffffff; } }
  @media (max-width: 991px) {
    .sh-btn1 {
      border-radius: 7px 0 7px 0;
      width: 156px;
      height: 45px; }
      .sh-btn1 div {
        font-size: 0.9375rem; } }

.tit-blsq div {
  position: relative;
  font-size: 2.5rem;
  line-height: 2;
  letter-spacing: 0.8px;
  font-weight: 500;
  color: #0073d8;
  display: inline-block; }

.tit-blsq div:before {
  position: absolute;
  content: '';
  top: 10px;
  left: -23px;
  transform-origin: bottom;
  transform: skewX(-32deg);
  background-color: #0073d8;
  opacity: .2;
  border-radius: 5px 0 5px 0;
  width: 34px;
  height: 48px; }

.sh-mbtn {
  position: relative;
  font-size: 1rem;
  line-height: 2;
  letter-spacing: 0.8px;
  font-weight: 500;
  color: #ffffff;
  display: inline-block;
  padding-right: 96px;
  transition: .4s; }
  @media (min-width: 768px) {
    .sh-mbtn:hover {
      padding-right: 110px; } }
  .sh-mbtn::before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 7px;
    width: 80px;
    height: 1px;
    background-color: #ffffff;
    transition: .4s; }
  .sh-mbtn::after {
    position: absolute;
    content: '';
    right: -1px;
    bottom: 11px;
    width: 11px;
    height: 1px;
    background-color: #ffffff;
    transform: rotate(45deg); }
    @media (max-width: 767px) {
      .sh-mbtn::after {
        bottom: 10px;
        width: 8px; } }

.four-list > li {
  width: 25%;
  margin-bottom: 100px;
  flex-wrap: wrap; }
  @media (max-width: 991px) and (min-width: 768px) {
    .four-list > li {
      width: 50%; } }
  @media (max-width: 767px) {
    .four-list > li {
      width: 100%;
      padding-bottom: 15px;
      margin-bottom: 25px; } }
  @media (max-width: 767px) {
    .four-list > li:not(:last-child) {
      border-bottom: 1px solid #d2d2d2; } }

.four-list {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 992px) {
    .four-list li:not(:nth-child(4n)) a .top-img {
      border-right: 1px solid #d2d2d2; }
    .four-list li:last-child a .top-img {
      border-right: none; } }
  @media (max-width: 991px) and (min-width: 768px) {
    .four-list li:not(:nth-child(2n)) a .top-img {
      border-right: 1px solid #d2d2d2; }
    .four-list li:last-child a .top-img {
      border-right: none; } }
  .four-list li a {
    display: block; }
    .four-list li a .top-img {
      position: relative;
      padding: 0 16px;
      padding-bottom: 100%; }
      .four-list li a .top-img::before {
        position: absolute;
        content: '';
        width: calc(100% - 32px);
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #0073d8;
        pointer-events: none;
        opacity: 0;
        transition: .4s;
        border-radius: 15px 0 15px 0; }
      .four-list li a .top-img::after {
        position: absolute;
        content: '';
        background: url(../images/b_bt.png) no-repeat center center/cover;
        width: 67px;
        height: 67px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: .2s ease;
        pointer-events: none;
        opacity: 0; }
      .four-list li a .top-img img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1; }
    .four-list li a .ti {
      font-size: 1.625rem;
      line-height: 1.85;
      letter-spacing: 0px;
      font-weight: 500;
      color: #0073d8;
      padding: 0 16px; }
      @media (max-width: 767px) {
        .four-list li a .ti {
          padding: 0;
          margin-top: 15px; } }
    @media (min-width: 768px) {
      .four-list li a:hover .top-img::before {
        opacity: .5; }
      .four-list li a:hover .top-img::after {
        opacity: 1; } }
  .four-list .editor_Content {
    font-size: 0.875rem;
    line-height: 2.14;
    letter-spacing: 0.28px;
    font-weight: 300;
    color: #6d6d6d;
    padding: 0 16px; }
    @media (max-width: 767px) {
      .four-list .editor_Content {
        padding: 0;
        margin-top: 5px; } }
  .four-list .editor_Content ul {
    padding-left: 0px;
    list-style: none;
    margin-left: 5px; }
    .four-list .editor_Content ul li {
      position: relative;
      padding-left: 10px; }
      .four-list .editor_Content ul li::before {
        position: absolute;
        content: '';
        left: 0;
        top: 14px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #6d6d6d; }

.sh-banner1 {
  height: 460px;
  width: 100%;
  position: relative; }
  @media (max-width: 991px) {
    .sh-banner1 {
      background-image: linear-gradient(180deg, #006de7 71%, #21a0b3 89%, #01dba5 109%);
      padding-bottom: 50px; } }
  @media (max-width: 768px) {
    .sh-banner1 {
      height: auto; } }
  @media (max-width: 540px) {
    .sh-banner1 {
      background-image: linear-gradient(180deg, #006de7 54%, #21a0b3 89%, #01dba5 109%); } }
  @media (max-width: 390px) {
    .sh-banner1 {
      background-image: linear-gradient(180deg, #006de7 39%, #21a0b3 82%, #01dba5 115%); } }
  .sh-banner1 .bgcover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
  .sh-banner1 .bgcover.pc {
    display: block;
    width: 100%;
    height: 460px;
    background-size: cover !important; }
    @media (max-width: 767px) {
      .sh-banner1 .bgcover.pc {
        display: none; } }
  .sh-banner1 .bgcover.mo {
    display: none;
    padding-bottom: 83.9%;
    background-size: cover !important;
    z-index: 0; }
    @media (min-width: 768px) {
      .sh-banner1 .bgcover.mo {
        display: none; } }
  .sh-banner1 .center-bx {
    display: flex;
    align-items: center;
    max-width: 1054px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 768px) {
      .sh-banner1 .center-bx {
        display: block;
        top: auto;
        transform: none;
        padding: 0 25px;
        padding-top: 8%; } }
    .sh-banner1 .center-bx .r-img {
      order: 2;
      min-width: 395px;
      max-width: 395px;
      margin-left: auto; }
      @media (max-width: 991px) {
        .sh-banner1 .center-bx .r-img {
          min-width: 39%; } }
      @media (max-width: 768px) {
        .sh-banner1 .center-bx .r-img {
          max-width: 100%;
          margin: 0 auto;
          text-align: center; } }
    .sh-banner1 .center-bx .l-bx {
      order: 1;
      margin-right: 4%;
      max-width: calc(96% - 395px); }
      @media (max-width: 991px) {
        .sh-banner1 .center-bx .l-bx {
          max-width: 581px;
          margin: 0 auto; } }
      @media (max-width: 768px) {
        .sh-banner1 .center-bx .l-bx {
          max-width: 100%;
          margin-right: 0; } }
      .sh-banner1 .center-bx .l-bx .ti {
        font-size: 3.75rem;
        line-height: 1;
        letter-spacing: 1.5px;
        font-weight: 500;
        color: #ffffff;
        height: 71px;
        overflow: hidden;
        text-shadow: #424141 1px 0 4px; }
        @media (max-width: 991px) {
          .sh-banner1 .center-bx .l-bx .ti {
            max-height: initial;
            font-size: 2.5rem;
            line-height: 1.2;
            height: 53px; } }
      .sh-banner1 .center-bx .l-bx .des {
        font-size: 0.875rem;
        line-height: 1.71;
        letter-spacing: 0.35px;
        font-weight: 300;
        color: #ffffff;
        max-height: 235px;
        overflow: hidden;
        margin-top: 15px;
        text-shadow: #424141 1px 0 4px;
        text-align: justify; }
        @media (max-width: 991px) {
          .sh-banner1 .center-bx .l-bx .des {
            max-height: initial;
            margin-top: 20px;
            line-height: 1.9; } }
  .sh-banner1 .b-wave {
    position: absolute;
    bottom: -1px;
    width: 100%; }
    .sh-banner1 .b-wave img {
      width: 100%; }

.sh-banner2 {
  height: 254px;
  width: 100%;
  position: relative; }
  @media (max-width: 767px) {
    .sh-banner2 {
      padding-bottom: 52%;
      height: 0; } }
  .sh-banner2 .bgcover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
  .sh-banner2 .bgcover.pc {
    display: block;
    width: 100%;
    height: 254px;
    background-size: cover !important; }
    @media (max-width: 767px) {
      .sh-banner2 .bgcover.pc {
        display: none; } }
  .sh-banner2 .bgcover.mo {
    display: block;
    padding-bottom: 52%;
    background-size: cover !important;
    z-index: 0; }
    @media (min-width: 768px) {
      .sh-banner2 .bgcover.mo {
        display: none; } }
  .sh-banner2 .center-bx {
    display: flex;
    align-items: center;
    max-width: 1054px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; }
    @media (max-width: 768px) {
      .sh-banner2 .center-bx {
        position: absolute;
        display: block; } }
    .sh-banner2 .center-bx .b-ti {
      font-size: 3.75rem;
      line-height: 1;
      letter-spacing: 1.5px;
      font-weight: 500;
      color: #ffffff; }
      @media (max-width: 768px) {
        .sh-banner2 .center-bx .b-ti {
          font-size: 2.5rem; } }
  .sh-banner2 .b-wave {
    position: absolute;
    bottom: -1px;
    width: 100%; }
    .sh-banner2 .b-wave img {
      width: 100%; }

.page {
  text-align: center;
  font-size: 1rem;
  font-family: "Noto Sans TC", "微軟正黑體", Verdana, sans-serif;
  margin-bottom: 70px; }
  @media (max-width: 1200px) {
    .page {
      text-align: center; } }
  @media (max-width: 767px) {
    .page {
      margin-bottom: 55px;
      margin-top: 15px; } }
  @media (max-width: 567px) {
    .page {
      position: relative; } }
  .page dd a {
    color: #6d6d6d;
    transition: .4s;
    position: relative; }
    @media (min-width: 768px) {
      .page dd a:hover {
        color: #0073d8; }
        .page dd a:hover::before {
          opacity: .2; } }
    .page dd a::before {
      position: absolute;
      content: '';
      width: 15px;
      height: 25px;
      background-color: #0073d8;
      opacity: 0;
      transition: .4s;
      z-index: -1;
      border-radius: 5px 0 5px 0;
      transform: skewX(-32deg);
      transform-origin: bottom;
      top: 8px;
      left: 18%; }
  .page dd {
    width: 40px; }
    @media (max-width: 767px) {
      .page dd {
        width: 13%; } }
    @media (max-width: 370px) {
      .page dd {
        width: 9%; } }
  .page .active a {
    color: #0073d8 !important;
    font-weight: 400 !important; }
    .page .active a::before {
      opacity: .2 !important; }
    @media (min-width: 768px) {
      .page .active a:hover {
        color: #0073d8 !important; } }

.page .ltbn {
  margin-right: 10px; }
  @media (max-width: 1200px) {
    .page .ltbn {
      margin-right: 0; } }
  @media (max-width: 567px) {
    .page .ltbn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px; } }

.page .rtbn {
  margin-left: 5px; }
  @media (max-width: 1200px) {
    .page .rtbn {
      margin-right: 0; } }

@media (min-width: 992px) {
  .page .nopage.rtbn a:hover::before {
    right: 7px; }
  .page .nopage.rtbn a:hover::after {
    left: 0; } }

@media (min-width: 992px) {
  .page .nopage.ltbn a:hover::before {
    left: 7px; }
  .page .nopage.ltbn a:hover::after {
    right: 0; } }

.page dt.ltbn a {
  border-radius: 0;
  background: none;
  height: 40px;
  width: 40px;
  opacity: 1;
  position: relative;
  transition: .4s;
  top: 4px; }
  @media (min-width: 992px) {
    .page dt.ltbn a:hover::before {
      left: -5%; }
    .page dt.ltbn a:hover::after {
      right: 22%; } }
  @media (max-width: 1200px) {
    .page dt.ltbn a {
      margin-right: 0; } }
  .page dt.ltbn a::before {
    border: none;
    width: 8px;
    height: 1px;
    background: #0073d8;
    position: absolute;
    top: 17px;
    left: 7px;
    transition: .4s;
    transform: rotate(-42deg); }
  .page dt.ltbn a::after {
    position: absolute;
    content: '';
    bottom: 19px;
    right: 0;
    width: 80%;
    height: 1px;
    background: #0073d8;
    transition: .4s; }

.page dt.rtbn a {
  border-radius: 0;
  background: none;
  height: 40px;
  width: 40px;
  opacity: 1;
  position: relative;
  transition: .4s;
  margin-left: 0;
  top: 4px; }
  @media (min-width: 992px) {
    .page dt.rtbn a:hover::before {
      right: -5%; }
    .page dt.rtbn a:hover::after {
      left: 20%; } }
  @media (max-width: 567px) {
    .page dt.rtbn a {
      position: absolute;
      top: 62%;
      transform: translateY(-50%);
      right: 0px; } }
  .page dt.rtbn a::before {
    border: none;
    width: 8px;
    height: 1px;
    background: #0073d8;
    position: absolute;
    top: 17px;
    right: 7px;
    transition: .4s;
    transform: rotate(42deg); }
  .page dt.rtbn a::after {
    position: absolute;
    content: '';
    bottom: 19px;
    left: 0;
    width: 80%;
    height: 1px;
    background: #0073d8;
    transition: .4s; }

.tag {
  display: inline-block;
  padding: 10px;
  border: 1px solid #0073d8;
  border-radius: 8px;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.28px;
  font-weight: 300;
  color: #0073d8;
  transition: .4s;
  background-color: #ffffff; }
  @media (min-width: 768px) {
    .tag:hover {
      background-color: #0073d8;
      color: #ffffff; } }
  @media (max-width: 767px) {
    .tag {
      border-radius: 4px;
      padding: 8px; } }

#chat-application {
  right: auto;
  left: 35px; }
  @media (max-width: 767px) {
    #chat-application {
      left: 15px;
      bottom: -10px !important; } }
