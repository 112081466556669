
.indexPage{
    .index-banner{
        position: relative;
        margin-bottom: 120px;
        @media (max-width:768px) {
            margin-bottom: 70px;
        }
        .container{
            padding: 0 10px;
            @media (min-width:1200px) {
                max-width: 1386px;
            }
           
        }
        .ind-cbx{
            display: flex;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            @media (max-width:991px){
                display: block;
            }
            @media (max-width:991px){
                padding: 0 40px;
                top: 0;
                left: 50%;
                transform: translate(-50%,0);
                padding-top: 16%;
            }
            .l-bx,.r-img{
                z-index: 50;
                position: relative;
            }
            .ti{
                @include fontset(60,1.33,1.2,500,#ffffff);
                max-height: 158px;
                overflow: hidden;
                text-shadow: #424141 1px 0 4px;
                // text-align: justify;
                @media (max-width:768px){
                    font-size: pxToEm(40);
                    max-height: 110px;
                }
            }
            .des{
                margin-top: 40px;
                @include fontset(22,1.64,.4,500,#ffffff);
                max-height: 109px;
                overflow: hidden;
                text-shadow: #424141 1px 0 4px;
                text-align: justify;
                @media (max-width:768px){
                    font-size: pxToEm(20);
                    max-height: 124px;
                    margin-top: 10px;
                    font-weight: 400;
                }
            }
            .l-bx{
                margin-left: 6.8%;
                margin-right: 3.2%;
                max-width: 445px;
                min-width: 445px;
                order: 1;
                @media (max-width:991px){
                    margin: 0 auto;
                    margin-top: 30px;
                    max-width: 100%;
                    min-width: 100%;
                }
            }
            .r-img{
                width: calc( 90% - 445px );
                order: 2;
                @media (max-width:991px){
                    width: 58%;
                    margin: 0 auto;
                }
                @media (max-width:768px){
                    width: 87%;
                    margin: 0 auto;
                }
            }
        }
        .bn-it{
            display: none;
        }
        .pc-banner{
            position: relative;
            height: 806px;
            @media (max-width:768px) {
                height: 0;
                padding-bottom: 160%;
            }
        }
        .pc-banner .bgcover{
            width: 100vw;
            position: absolute;
            padding-bottom: 806px;
            top: 0;
            left: 0;
            background-size: cover !important;
            // opacity: 0;
            @media (max-width:768px) {
                padding-bottom: 160%;
            }
        }
        .bgcover.mo{
            display: block;
            @media (min-width:768px) {
                display: none;
            }
        }
        .bgcover.pc{
            display: block;
            @media (max-width:767px) {
                display: none;
            }
        }
        .wave-bx{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        
        }
        .waves {
            position:relative;
            width: 100%;
            height:15vh;
            margin-bottom:-7px; /*Fix for safari gap*/
            min-height:100px;
            max-height:150px;
          }
          
          .content {
            position:relative;
            height:20vh;
            text-align:center;
            background-color: white;
          }
          
          /* Animation */
          
          .parallax > use {
            animation: move-forever 15s cubic-bezier(.55,.5,.45,.5)     infinite;
          }
          .parallax > use:nth-child(1) {
            animation-delay: -2s;
            animation-duration: 4s;
          }
          .parallax > use:nth-child(2) {
            animation-delay: -3s;
            animation-duration: 5s;
          }
          .parallax > use:nth-child(3) {
            animation-delay: -4s;
            animation-duration: 7s;
          }
          .parallax > use:nth-child(4) {
            animation-delay: -5s;
            animation-duration: 10s;
          }
          @keyframes move-forever {
            0% {
             transform: translate3d(-90px,0,0);
            }
            100% { 
              transform: translate3d(85px,0,0);
            }
          }
          /*Shrinking for mobile*/
          @media (max-width: 768px) {
            .waves {
              height:40px;
              min-height:40px;
            }
            .content {
              height:30vh;
            }
            h1 {
              font-size:24px;
            }
          }
        .show-me{
            &::before{
                background-color: #ffffff;
            }
        }
        .show-box{
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            z-index: 10;
            display: block;
            @media (max-width:767px) {
                left: 0px;
            }
            .go-it{
                width: 40px;
                height: 40px;
                cursor: pointer;
                position: relative;
                margin-bottom: 15px;
                @media (max-width:991px) {
                    margin-bottom: 30px;
                }
                &::before{
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    border-radius: 3px;
                    border: solid 1px #ffffff;
                    transition: .4s;
                    width: 6px;
                    height: 38px;
                    @media (max-width:767px) {
                        width: 5px;
                        height: 48px;
                    }
                }
               @media (min-width:768px) {
                   &:hover{
                       &::before{
                        background-color: #ffffff;
                       }
                   }
               }
            }
        }
    }
    .item2{
        .it2-bx{
            position: relative;
            @media (max-width:991px) and (min-width:769px){
                max-width: 100%;
            }
            .tit-blsq{
                text-align: center;
                margin-bottom: 70px;
                @media (max-width:767px) {
                    margin-bottom: 30px;
                }
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                @media (max-width:767px) {
                    display: block;
                }
                li{
                    width: 50%;
                    border-bottom: 1px solid #d2d2d2;
                    padding: 20px 40px;
                    padding-bottom: 50px;
                    text-align: center;
                    position: relative;
                    @media (max-width:767px) {
                        width: 100%;
                        padding: 20px 0px 20px;
                    }
                    a{
                        display: block;
                    }
                    &:nth-child(odd){
                        border-right: 1px solid #d2d2d2;
                        @media (max-width:767px) {
                            border-right: none;
                        }
                    }
                    &:last-child,&:nth-last-child(2){
                        border-bottom: none;
                        &::before{
                            transform: none;
                            transform: translateX(-50%);
                            top: auto;
                            bottom: 0;
                            height: 97%;
                        }
                    }
                    &:nth-last-child(2){
                        @media (max-width:767px) {
                            border-bottom: 1px solid #d2d2d2;
                        }
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 95%;
                        height: 95%;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        background-color: $hover;
                        pointer-events: none;
                        opacity: 0;
                        transition: .4s;
                        border-radius: 15px 0 15px 0;
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        background: url(../images/b_bt.png) no-repeat center center / cover;
                        width: 72px;
                        height: 73px;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        pointer-events: none;
                        opacity: 0;
                        transition: .2s ease;
                    }
                    @media (min-width:768px) {
                        &:hover{
                            &::before{
                                opacity: .5;
                            }
                            &::after{
                                opacity: 1;
                            }
                        }
                    }
                }
                .des-ti{
                    @include fontset(26,1.46,.5,400,$hover);
                    max-height: 37px;
                    overflow: hidden;
                    @media (max-width:767px) {
                        margin-top: 20px;
                    }
                }
                .des{
                    margin-top: 20px;
                    @include fontset(16,1.88,.3,300,#6d6d6d);
                    max-height: 150px;
                    overflow: hidden;
                    @media (max-width:767px) {
                        max-height: 185px;
                        margin-top: 12px;
                    }
                }
            }
            @keyframes fadel {
                0%{
                    opacity: .2;
                }
                50%{
                    opacity: 0;
                }
                100%{
                    opacity: .2;
                }
            }
            .l-sqbx{
                position: absolute;
                left: -540px;
                top: 220px;
                @media (max-width:767px){
                    display: none;
                }
                .t-bx,.b-bx{
                    display: flex;
                }
                .b-bx{
                    margin-left: 15px;
                }
                .sq{
                    width: 90px;
                    height: 150px;
                    transform-origin:bottom;
                    transform:skewX(-32deg);
                    background-color: #bce0ff;
                    opacity: .2;
                    border-radius: 15px 0 15px 0 ;
                    margin: 0 15px;
                    margin-bottom: 30px;
                }
                .ani1{
                    animation: fadel 5s 1s linear infinite;
                }
                .ani2{
                    animation: fadel 5s 1.5s linear infinite;
                }
                .ani3{
                    animation: fadel 5s 2s linear infinite;
                }
                .ani4{
                    animation: fadel 5s 2.5s linear infinite;
                }
                .ani5{
                    animation: fadel 5s 3s linear infinite;
                }
                .ani6{
                    animation: fadel 5s 3.5s linear infinite;
                }
            }
            .r-sqbx{
                position: absolute;
                right: -540px;
                bottom: 120px;
                @media (max-width:767px){
                    display: none;
                }
                .t-bx,.b-bx{
                    display: flex;
                }
                .b-bx{
                    margin-left: 15px;
                }
                .sq{
                    width: 90px;
                    height: 150px;
                    transform-origin:bottom;
                    transform:skewX(-32deg);
                    background-color: #0073d8;
                    opacity: .2;
                    border-radius: 15px 0 15px 0 ;
                    margin: 0 15px;
                    margin-bottom: 30px;
                }
                .ani1{
                    animation: fadel 5s 1s linear infinite;
                }
                .ani2{
                    animation: fadel 5s 1.5s linear infinite;
                }
                .ani3{
                    animation: fadel 5s 2s linear infinite;
                }
                .ani4{
                    animation: fadel 5s 2.5s linear infinite;
                }
                .ani5{
                    animation: fadel 5s 3s linear infinite;
                }
                .ani6{
                    animation: fadel 5s 3.5s linear infinite;
                }
            }
        }
    }
    .item3{
        position: relative;
        height: 801px;
        background: url(../images/2bg.jpg) no-repeat center center / cover;
        margin-top: 100px;
        @media (max-width:767px) {
            background: url(../images/2bg_mb.jpg) no-repeat center center / cover;
            height: auto;
            margin-top: 30px;
        }
        .it3-bx{
            display: flex;
            align-items: center;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width:991px) {
                display: block;
            }
            @media (max-width:767px) {
                top: 0;
                transform:none;
                padding: 90px 25px;
            }
            .l-img{
                margin-right: 6%;
                width: calc(94% - 450px);
                @media (max-width:991px) {
                    width: 59%;
                    margin: 0 auto;
                }
                @media (max-width:767px) {
                    width: 90%;
                }
            }
            .r-bx{
                max-width: 450px;
                @media (max-width:991px) {
                    margin: 0 auto;
                }
                .tit-blsq{
                    position: relative;
                    @media (max-width:767px) {
                        margin-top: 45px;
                        padding-left: 25px;
                    }
                    &::before{
                        position: absolute;
                        transform-origin: bottom;
                        transform: skewX(-32deg);
                        content: "";
                        top: -5px;
                        background-color: #348ddb;
                        opacity: 1;
                        z-index: -1;
                        width: 31px;
                        height: 48px;
                        left: -25px;
                        border-radius: 5px 0 5px 0;
                        @media (max-width:767px) {
                            z-index: 1;
                            left: 0;
                            background-color: #3598d6;
                        }
                    }
                    div{
                        color: #ffffff;
                        line-height: 1.3;
                        z-index: 2;
                        max-height: 104px;
                        overflow: hidden;
                        margin-bottom: 32px;
                        text-shadow: #424141 1px 0 4px;
                        // text-align: justify;
                        @media (max-width:767px) {
                            max-height: 157px;
                        }
                        &::before{
                          width: 0;
                        }
                    }
                }
                .des{
                    @include fontset(16,1.88,.32,300,#ffffff);
                    max-height: 210px;
                    overflow: hidden;
                    text-shadow: #424141 1px 0 4px;
                    text-align: justify;
                    @media (max-width:767px) {
                        max-height: inherit;
                        overflow: auto;
                    }
                }
                .sh-mbtn{
                    margin-top: 60px;
                    @media (max-width:767px) {
                        margin-top: 50px;
                    }
                }
            }
        }
        .t-wav{
            width: 100%;
            position: absolute;
            top: -1px;
            img{
                width: 100%;
            }
        }
        .b-wav{
            width: 100%;
            position: absolute;
            bottom: -1px;
            img{
                width: 100%;
            }
        }
    }
    .item4{
        margin-top: 90px;
        @media (max-width:767px) {
            margin-top: 70px;
        }
        .it4-bx{
            display: flex;
            align-items: center;
            position: relative;
            @media (max-width:991px) {
                display: block;
            }
            .l-img{
                // margin-right: 6%;
                order: 2;
                width: calc(94% - 450px);
                @media (max-width:991px) {
                    width: 59%;
                    margin: 0 auto;
                }
                @media (max-width:767px) {
                    width: 90%;
                }
            }
            .r-bx{
                max-width: 450px;
                order: 1;
                margin-right: 6%;
                @media (max-width:991px) {
                    margin: 0 auto;
                }
                .tit-blsq{
                    position: relative;
                    @media (max-width:767px) {
                        margin-top: 45px;
                        padding-left: 25px;
                    }
                    &::before{
                        position: absolute;
                        transform-origin: bottom;
                        transform: skewX(-32deg);
                        content: "";
                        top: -5px;
                        opacity: .2;
                        z-index: -1;
                        width: 31px;
                        height: 48px;
                        left: -25px;
                        border-radius: 5px 0 5px 0;
                        background-color: #0073d8;
                        @media (max-width:767px) {
                            left: 0;
                        }
                    }
                    div{
                        color: #0073d8;
                        line-height: 1.3;
                        z-index: 2;
                        max-height: 104px;
                        overflow: hidden;
                        margin-bottom: 32px;
                        // text-align: justify;
                        &::before{
                          width: 0;
                        }
                        @media (max-width:767px) {
                            max-height: 157px;
                        }
                    }
                }
                .des{
                    @include fontset(16,1.88,.32,300,#6d6d6d);
                    max-height: 210px;
                    overflow: hidden;
                    text-align: justify;
                    @media (max-width:767px) {
                        max-height: inherit;
                        overflow: auto;
                    }
                }
                .sh-mbtn{
                    margin-top: 60px;
                    color: #0073d8;
                    &::before,&::after{
                        background-color: #0073d8;
                    }
                    @media (max-width:767px) {
                        margin-top: 50px;
                    }
                }
            }
        }
    }
    .hoving5.item5{
        &::before{
            left: 200%;
            transition: 3s ease;
        }
    }
    .item5{
        background-color: #0073d8;
        text-align: center;
        position: relative;
        margin-top: 90px;
        @media (max-width:767px) {
            margin-top: 55px;
        }
       
        &::before{
            position: absolute;
            content: '';
            background: linear-gradient(145deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(255,255,255,.8) 50%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%);
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
        }
      
        a{
            display: block;
            padding-top: 60px;
            padding-bottom: 75px;
            position: relative;
            @media (max-width:767px) {
                padding-top: 35px;
                padding-bottom: 60px;
            }
          
        }
        .tit-blsq{
            position: relative;
            @media (max-width:767px) {
                margin-top: 45px;
                padding-left: 25px;
            }
            div{
                color: #ffffff;
                line-height: 1.3;
                z-index: 2;
                margin-bottom: 27px;
                @media (max-width:767px) {
                    max-height: 157px;
                }
                &::before{
                content: "";
                  width: 31px;
                  height: 48px;
                  left: -25px;
                  border-radius: 5px 0 5px 0;
                  top: -5px;
                  opacity: 1;
                  z-index: -1;
                  background-color: #348ddb;
                   
                }
            }
        }
        .des{
            @include fontset(16,1.88,.32,300,#ffffff);
            @media (max-width:767px) {
                max-width: 228px;
                margin: 0 auto;
            }
        }
    }
}