/*----------------------header------------------------*/
$menuw : 50vw;
.header_show {
    transition: .4S;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    z-index: 10;
    li {
        list-style: none;
    }
    &.active{
        background: rgba(#131314,1);
    }
}
.top-headebx{
    display: flex;
    align-items: center;
    height: 46px;
    background-color: $hover;
    justify-content: space-between;
    padding: 0 40px;
    @media (max-width:991px) {
        display: none;
    }
    .l-bx{
        display: flex;
        a{
            display: block;
            position: relative;
            padding-left: 25px;
            color: #ffffff;
            &:first-child{
                margin-right: 35px;
            }
        }
        .tel{
            &::before{
                position: absolute;
                content: '';
                left: 0;
                background: url(../images/header_phone_icon.png) no-repeat center center / 15px;
                top: 2px;
                width: 15px;
                height: 15px;
            }
        }
        .mail{
            &::before{
                position: absolute;
                content: '';
                left: 0;
                background: url(../images/email_icon.png) no-repeat center center / 15px;
                top: 2px;
                width: 15px;
                height: 15px;
            }
        }
    }
    .r-bx{
        ul{
            display: flex;
            align-items: center;
            li{
                &:not(:last-child){
                    margin-right: 30px;
                }
                a{
                    color: #ffffff;
                    height: 46px;
                    display: block;
                    line-height: 2.7;
                    position: relative;
                    @media (min-width:768px){
                        &:hover{
                            &::before{
                                opacity: 1;
                            }
                        }
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: #ffffff;
                        opacity: 0;
                        transition: .4s;
                    }
                }
            }
        }
    }
}

.header_box {
    position: relative;
    width: 100%;
    padding: 0 40px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-size: pxToEm(14);
    // box-shadow: 0 3px 6px rgba(13, 5, 9, 0.1);
    height: 64px;
    // background: url(../images/bavv.jpg) top left no-repeat;
}
.header_box.sear-ing{
    nav{
        animation: downani 1s cubic-bezier(.77,0,.175,1) forwards;
        pointer-events: none;
    }
    @keyframes downani {
        0%{
            top: 0;
            opacity: 1 ;
        }
        100%{
            top: 15px;
            opacity: 0 ;
        }
    }

    .search-img{
        animation: sedownani 1s cubic-bezier(.77,0,.175,1) forwards;
        pointer-events: none;
    }
    @keyframes sedownani {
        0%{
            top: 0;
            opacity: 1 ;
        }
        100%{
            top: 10px;
            opacity: 0 ;
        }
    }

    .search-clos{
       
        animation: sedani 1s .3s cubic-bezier(.77,0,.175,1) forwards;
        pointer-events: auto;
    }
    @keyframes sedani {
        0%{
            top: -10px;
            opacity: 0;
        }
        100%{
            top: 0px;
            opacity: 1;
        }
    }

    .search .inp-bx{
        
        animation: inputani 1s cubic-bezier(.77,0,.175,1) forwards;
        pointer-events: auto;
    }
    @keyframes inputani {
        0%{
            top: 10px;
            opacity: 0;
        }
        100%{
            top: 5px;
            opacity: 1;
        }
    }
} 

.header_box.searclos-ing{
    nav{
        animation: upani 1s cubic-bezier(.77,0,.175,1) forwards;
        pointer-events: auto;
    }
    @keyframes upani {
        0%{
            top: 15px;
            opacity: 0;
        }
        100%{
            top: 0px;
            opacity: 1;
        }
    }

    .search-img{
        animation: seaupani 1s .3s cubic-bezier(.77,0,.175,1) forwards;
        pointer-events: auto;
        opacity: 0;
    }
    @keyframes seaupani {
        0%{
            top: 10px;
            opacity: 0;
        }
        100%{
            top: 0px;
            opacity: 1;
        }
    }

    .search-clos{
        animation: clupani 1s cubic-bezier(.77,0,.175,1) forwards;
        pointer-events: none;
    }
    @keyframes clupani {
        0%{
            top: 0px;
            opacity: 1;
        }
        100%{
            top: -10px;
            opacity: 0;
        }
    }

    .search .inp-bx{
     
        animation: inputdoani 1s cubic-bezier(.77,0,.175,1) forwards;
        pointer-events: none;
    }
    
    
    @keyframes inputdoani {
        0%{
            top: 5px;
            opacity: 1;
        }
        100%{
            top: 10px;
            opacity: 0;
        }
    }
}



.logo {
    width: 86px;
    height: 35px;
    background: url(../images/logo_mb.png) no-repeat;
    background-size: contain;
    text-indent: -10000px;
    margin: 25px 0;
    a {
        @include aLink();
    }
}
.search{
    position: fixed;
    right: 37px;
    width: 47px;
    height: 47px;
    top: 54px;
    @media (max-width:991px) {
        display: block;
        position: relative;
        right: auto;
        top: auto;
        width: 100%;
        height: 80px;
        padding: 0 40px;
        border-bottom: 1px solid #a4d4ff;
        border-top: 1px solid #a4d4ff;
    }
    .search-img{
        border: 1px solid rgba(255,255,255,0);
        @media (min-width:768px) {
            &:hover{
                border: 1px solid $hover;
            }
        }
    }
    a{
        display: block;
        border: 1px solid rgba(255,255,255,0);
        border-radius: 50%;
        transition: .4s;
        padding: 3px;
        width: 47px;
        height: 47px;
        position: absolute;
        top: 0;
        right: 0;
        @media (max-width:991px) {
            width: 20px;
            height: 20px;
            position: relative;
        }
        img{
            width: 41px;
            height: 40px;
            @media (max-width:991px) {
                width: 20px;
                height: 20px;
            }
        }
    }
    .inp-bx{
        position: absolute;
        height: 30px;
        width: 330px;
        right: 60px;
        top: 10px;
        opacity: 0;
        pointer-events: none;
        @media (max-width:991px) {
            opacity: 1;
            pointer-events: auto;
            right: auto;
            left: 75px;
            width: 100%;
            top: 25px;
        }
        input{
            height: 30px;
            width: 330px;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid $hover;
            @media (max-width:991px) {
                width: calc(100% - 115px);

            }
            &:focus{
                border: none;
                border-bottom: 1px solid $hover;
            }
        }
    }
    .search-img{
        right: -3px;
        @media (max-width:991px) {
            right: auto;
            padding-top: 25px;
            padding-left: 0;
        }
    }
    .search-clos{
        width: 47px;
        height: 47px;
        opacity: 0;
        pointer-events: none;
        top: 0;
        text-align: center;
        padding: 0;
        right: -3px;
        img{
            width: 27px;
            height: 28px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}


/*----------------------nav------------------------*/
nav{
    position: relative;
}
.nav_box {
    position: relative;
    @include fontset(17,1,0);
    margin: 0 auto;
    transition: .4s;
    left: -43px;
    @media (max-width:991px) {
        left: auto;
    }
    nav{
        display: flex;
        @media (max-width:1200px) {
            flex-wrap: wrap;
        }
    }
  
    ul {
        display: flex;
        position: relative;
        @media (max-width:1200px) {
            width: 100%;
        }
        @media (min-width:1201px) {
            .active{
                a{
                    color: $hover;
                    &::before{
                        opacity: 1;
                    }
                }
            }    
        }
        li.hoing{
            background-color: $hover;
            &>a{
              color: #ffffff;  
            }
        }
        li{
            position: relative;
            text-align: center;
            transition: .4s;
            // width: 80px;
            // padding: 0 20px;
            @media (min-width:992px) {
                a{
                        &:hover{
                    // color: $hover;
                    }
                }
                     
            }
            a{
                padding: 24px 20px;
                color: $hover;
                transition: .4s;
                &::before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background: $hover;
                    left: 0;
                    bottom: -2px;
                    opacity: 0;
                    transition: .4s;
                }
            }
         
        }
        li:not(:last-child){
        }
        // li:last-child{
        //     margin-right: 33px;
        // }
    }
    a{
        width: 100%;
        display: block;
        color: #000000;
        text-decoration: none;
        font-weight: 300;
        font-size: pxToEm(16);
        &:after{
            transition: .4s;
            position: relative;
            content:'';
            width: 0;
            height: 2px;
            background: $blue;
            bottom: -26px;
            left: 8px;
            opacity: 0;
        }
        @media (max-width:992px) {
            font-weight: 400;
        }
    }
    .activeh{
        color: $hover;
    }
    .chlan-box{
        display: flex;
        padding: 4px 15px;
        border: solid 1px #a0a0a0;
        border-radius: 99px;
        div:not(:last-child){
            margin-right: 15px;
            position: relative;
            @media (max-width:1200px) {
                &::before{
                    content: '';
                    position: absolute;
                    width: 2px;
                    height: 19px;;
                    top: 0;
                    right: -1vw;
                    background: #dedede;
                    }
            }
        }
        div{
            transition: .4s;
            @media (min-width:1200px) {
                &:hover{
                    a{
                    color: $hover;
                    }
                }
            }
            @media (max-width:1200px) {
                width: 30%;;
                text-align: center;
           
            }
        }
        .active{
            color: $hover;
        }
        @media (max-width:1200px) {
            width: 100%;
            border: none;
            padding: 23px 0 20px;
            border-bottom: 1px solid #dedede;
            border-radius: 0;
        }
    }
    .nav-moreboxbg{
        display: block;
        
        @media (max-width:992px) {
            display: block;
        }
    }
    .nav-morebox{
        position: fixed;
        background: $hover;
        left: 0;
        top: 110px;
        width: 100%;
        text-align: left;
        display: flex;
        height: auto;
        box-shadow: 0 0px 5px rgba(13,5,9,.1);
        padding: 15px 0 ;
        display: none;
        @media (max-width:1200px) {
            min-height:215px;
            height: auto;
        }
        @media (max-width:992px) {
            box-shadow: none;
            padding-top: 20px;
            padding-bottom: 30px;
        }
        .mo{
            display: none;
        }
        .h-leftimg{
            margin-right: 45px;
            width: 184px;
            .h-txbx{
                font-size: pxToEm(30);
                color: #0072bc;
                font-weight: 700;
                span{
                    font-size: pxToEm(16);
                    color: #000000;
                    font-weight: 500;
                }
            }
        }
        .mr{
            margin-right: 40px;
        }
        .h-listitem-box{
            margin: 0 auto;
            margin-top: 0px;
            background-color: $hover;
            @media (max-width:992px) {
                min-height: calc(100vh- 54px);
            }
            a:not(:first-child){
                margin-top: 0px;
            }
            a:before{
                width: 2px;
                height: 70%;
                opacity: 0;
                bottom: auto;
                top: 3;
            }
            a{
                font-weight: 400;
                position: relative;
            }
            h4{
                font-weight: 400;
                color: $hover;
                @media (max-width:992px) {
                    font-size: pxToEm(16);
                }
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content:center;
                @media (max-width:992px) {
                    display: flex;
                    justify-content: start;
                }
                .img-bx{
                    position: relative;
                    width: 50px;
                    height: 50px;
                    margin: 0 auto;
                    @media (max-width:991px) {
                        width: 34px;
                        height: 34px;
                    }
                    img{
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        opacity: 0;
                        transition: .4s;
                        @media (max-width:991px) {
                           width: 34px;
                           height: 34px;
                           position: relative;
                           top: auto;
                           left: auto;
                           transform:none;
                        }
                    }
                    .nthov{
                        opacity: 1;
                    }
                }
                li:hover{
                    @media (min-width:992px) {
                        &:hover{
                            .nthov{
                                opacity: 0;
                            }
                            .hov{
                                opacity: 1;
                            }
                            .des{
                                color: #2cd9d2;
                            }
                        }
                    }
                }
                li a{
                    font-size: pxToEm(16);
                    line-height: 1.25;
                    font-weight: 500;
                    color: #ffffff;
                    padding: 0;
                
                    @media (max-width:992px) {
                        font-size: pxToEm(16);
                        // padding: 15px 0;
                        background-color: #00a0e9;
                        border-bottom: 1px solid #ffffff;
                        display: block;
                        height: 100%;
                        padding: 40px 0;
                    }
                }
                li{
                    width: calc((58.5vw - 23.7%) / 5);
                    margin-right: 0;
                    text-align: center;
                    @media (max-width:1200px) {
                        width: calc((58.5vw - 23.7%) / 3);
                    }
                    @media (max-width:992px) {
                        width: 50%;
                        border-bottom: none;
                      &:nth-child(odd){
                          border-right: 1px solid #ffffff;
                      }
                    }
                    .des{
                        transition: .4s;
                        text-align: center;
                        line-height: 1.5;
                        font-weight: 400;
                        margin-top: 4px;
                        padding: 0 20px;
                    }
                }
            }
            .h-topbor{
                @media (min-width:768px) {
                    border-top: 1px solid $hover;
                    padding-top: 20px;
                    margin-top: 20px !important;
                    padding-bottom: 0;
                }
            }
            .h-solbx{
                @media (min-width:992px) {
                    display: flex;
                    a{
                        width: auto;
                        &:not(:last-child){
                        margin-right: 5%;
                        }
                    }
                }
            }
            .h-inves{
                @media (min-width:768px) {
                    padding-bottom: 6px;
                }
            }
            .h-solul{
                @media (min-width:992px) {
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    border-bottom: 1px solid $hover;
                }
            }
            .h-scanerul{
                padding-bottom: 0px;
                @media (max-width:992px) {
                    // padding-bottom: 14px;
                    // margin-bottom: 9px;
                    
                    // min-height: 100vh;
                    height: 100%;
                    padding-bottom: 0;
                }
            }
            .h-invesull{
                li{
                    width: calc((58.5vw - 23.7%) / 7);
                  
                    @media (max-width:1200px) {
                        width: calc((58.5vw - 23.7%) / 6);
                    }
                }
            }
            .h-listitem-sbx{
                display: flex;
            }
            .h-listitem{
                position: relative;
                margin-top: 20px;
                display: none;
                @media(max-width:992px){
                    display: block;
                }
                &:before{
                    content: '';
                    position: absolute;
                    top: 6px;
                    left: 0;
                    width: 1px;
                    height: 88%;
                    background: #bfbfbf;
                    @media (min-width:768px) {
                        width: 1px;
                        height: 88%;
                    }
                }
                a:not(:first-child){
                    margin-top: 5px;
                }
                a:before{
                    width: 0px;
                }
                a:first-child{
                    // 
                }
                a{
                    color: #000000;
                    font-weight: 400;
                    font-size: pxToEm(15);
                    transition: .4s;
                    @media (min-width:768px) {
                        &:hover{
                            color: $hover;
                        }
                    }
                    @media (max-width:992px) {
                        font-size: pxToEm(16);
                    }
                }
            }
        }
        .h-supbx{
            display: flex;
            a{
                width: auto;
                // height: 28px;
                &:not(:last-child){
                    margin-right: 5%;
                    
                }
            }
            @media (max-width:992px) {
                display: block;
            }
        }
        .mt{
            margin-top: 0;
            width: 69.5vw;
        }
        
        @media (max-width:992px) {
            
            width: $menuw;
            left: auto;
            right: -50vw ;
            display: block;
            padding-left: 0;
            top: 0;
            background: #ffffff;
            height: 100vh;
            z-index: 11;
            padding:0;
            transition: .4s;
            .mo{
                display: block;
            }
            .prevar{
                background: url(../images/h-prevarr.png) no-repeat 94%;
                background-size: 29px;
                width: 100%;
                height: 25px;
                padding: 27px 0;
                position: relative;
                text-align: center;
             
                &::before{
                    content: none;
                }
                div{
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    left: 50%;
                    font-weight: 500;
                    font-size: pxToEm(20);
                }

            }
            
            .h-leftimg{
                display: none;
            }
            
            .h-listitem-box{
                width: 100%;
                margin-top: 0;
                .h-listitem-sbx{
                    display: block;
                    position: fixed;
                    top: 0;
                    right: -60vw;
                    margin-top: 0;
                    height: 100%;
                    background: #ffffff;
                    z-index: 17;
                    transition: .4s;
                    width: $menuw;
                    padding: 0 18px;
                    .h-listitem{
                        position: relative;
                        height: auto;
                        padding: 0;
                        width: 100%;
                        transition: none;
                    }
                }
                .h-listitem{
                    display: block;
                    position: fixed;
                    top: 0;
                    margin-top: 0;
                    background: #ffffff;
                    z-index: 15;
                    height: 100vh;
                    width: $menuw;
                    right: -60vw;
                    padding: 0 18px;
                    transition: .4s;
                    &::before{
                        height: 0;
                    }
                    a:not(:first-child){
                        margin-top: 0;
                    }
                    a::before{
                        width: 100%;
                        height: 1px;
                        left: 0;
                        bottom: 0;
                    }
                    a:hover{
                        color: #000000;
                    }
                }
                a{
                    padding-left: 0;
                  
                }
                a:before{
                    height: 1px;
                    width: 100%;
                    background: #dedede;
                    top: auto;
                    bottom: 0;
                }
                a:not(:first-child){
                    margin-top: 0;
                }
            }
  
        }
        @media (max-width:992px) {
            width: 100vw;
            right: -100vw ;
            .h-listitem-box{
                .h-listitem-sbx{
                    width: 100vw;
                    right: -100vw ;
                }
                .h-listitem{
                    width: 100vw;
                    right: -100vw ;
                }
            }
            .h-listitem{
                width: 100vw;
                right: -100vw ;
            }
        }
    }
    @media (max-width:992px) {
        .shnextpage{
            right: 0 !important;
            .nav-morebox{
            right: 0;
            }
        }
        .nav-morebox .h-listitem-box .shnextpage .h-listitem{
            right: 0;
        }
        .shnextpageb{
            .nav-morebox{
                right: -50vw;
                }
        }
        .nextar{
            background: url(../images/h-nextarr.png) no-repeat 97%;
            background-size: 12px;
        }
       
    }
    .selectli{
        .svg{
            width: 25px;
            height: 25px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @media (max-width:992px) {
                path{
                    fill: $hover !important;
                }
            }
        }
        &::before{
            position: absolute;
            content: '';
            border-top: 8px solid #000;
            border-left: 5px dashed transparent;
            border-right: 5px dashed transparent;
            left: 130px;
            width: 10px;
            height: 10px;
            bottom: auto;
            opacity: 1;
            background: none;
            top: 53%;
            transform: translateY(-50%);
            @media (max-width:992px) {
                border-top: 8px solid $hover;
            }
        }
    }
    select{
        border: none;
        font-size: pxToEm(16);
        font-weight: 300;
        height: 58px;
        padding-left: 43px;
        background: none;
        @media (max-width:992px) {
            color: $hover;
        }
    }
}


/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: none;
}

$bar-width: 25px;
$bar-height: 2px;
$bar-spacing: 4px;
$bar-color:#fff;
.menu-wrapper {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    bottom: auto;
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    z-index: 10;
    transition: .4s;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
    width: $bar-width;
    height: $bar-height;
    @include border();
}

.hamburger-menu {
    position: relative;
    transform: translateY($bar-spacing);
    background: none;
    transition: all 0ms 300ms;
    @include border(99px);
    &.animate {
        // background: #000000;
    }
    span{
        // position: relative;
        &::before{
            position: absolute;
            content: '';
            width: 30px;
            height: $bar-height;
            background: $hover;
            top: -5px;
            left: -5px;
        }

    }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: none;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    right: 0;
    top: $bar-spacing;
    background: $hover;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
    width: 15px;
}


// .hamburger-menu.animate:after {
//     top: 0;
//     transform: rotate(45deg);
//     transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
//     ;
//     @include border(99px);
// }

// .hamburger-menu.animate:before {
//     bottom: 0;
//     transform: rotate(-45deg);
//     transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
//     ;
//     @include border(99px);
// }

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;
    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;
    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        &.i1 {
            top: 0%;
        }
        &.i2 {
            top: 25%;
        }
        &.i3 {
            top: 51%;
        }
    }
}

@media (max-width:992px){
    .header_box{
        padding: 0 40px;
    }
    .nav_box ul li:not(:last-child){
        margin-right: 22px;
    }
}


@media (max-width:992px){
    $header_heright: 60px;

    .logo{
        width: 100px;
        transition: .4s;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        margin: 0;
        height: 25px;
    }
    .header_box {
        width: 100%;
        height:  60px;
        font-size: pxToEm(17);
        margin: 0 auto;
        padding: 0 20px;
    }
    .header_box.open{
    }
    .nav_box nav{
        width: 100%;
        padding: 0 40px;
    }
    @media (max-width:992px) {
        .navop{
            right: 0;
            .nav-morebox{
                overflow: scroll;
            }
        }
    }
    .nav_box {
        display: block;   // 開
        position: fixed;
        top:  0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        background: #ffffff;
        transition: left .4s;
        @media (max-width:1200px) {
            width: $menuw;
            left: auto;
            right: -50vw;
            .shnextpage.nav-morebox{
                right: -50vw;
            }
        }
        @media (max-width:992px) {
            width: 100vw;
            height: 100vh;
            right: 0;
            top: 60px;
            overflow-x: hidden;
            overflow-y: scroll;
            display: none;
            padding-bottom: 90px;
            .shnextpage.nav-morebox{
                right: -100vw;
            }
        }
        @media (max-width:350px) {
            height: 90vh;
        }
        ul {
            display: block;
            li {
                width: 100%;
                a {
                    padding: 23px 0 20px 0;
                    text-align: left;
                    text-decoration: none;
                    font-size: pxToEm(15);
                    @media (max-width:992px) {
                        font-size: pxToEm(16);
                        padding: 28px 0;
                    }
                }
            }
            li.selectli{
                border-bottom: none !important;
            }
        }
        ul>li:not(:last-child) {
            margin-right: 0;
        }

    }
    .menu-wrapper {
        display: block;
    }

}
@media (max-width:992px){
    .navop{
        right: 0;
    }
}

.h-mo-bgc{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -1;
    height: 100%;
    background-color: rgba(220, 220, 220, 0.8);
    transition: .1s;
    transition-delay: .4s;
    opacity: 0;
    pointer-events: none;
}
.mobgshow{
    @media (max-width:1200px) {
        opacity: 1;
        transition-delay: 0s !important;   
    }
}
.h-lan{
    @media (max-width:992px) {
        display: none;
    }
    .svg{
        width: 25px;
        height: 25px;
        position: absolute;
        left: 0px;
        path{
            transition: .4s;
        }

    }
    .lanfrlink{
        display: flex;
        align-items: center;
    }
    @media (min-width:992px) {
        &:hover{
            .svg{
                path{
                    fill: $hover !important;
                }
            }
            .lanfrlink{
                color: $hover;
                &::before{
                    border-top: 8px solid $hover;
                }
            }
        }
    }
    .h-hovbox{
        position: fixed;
        margin-top: 33px;
        background: #ffffff;
        opacity: 0;
        transition: .4s;
        box-shadow: 2px 0px 5px rgba(13,5,9,.1);
        padding-bottom: 5px;
        letter-spacing: 0;
        a{
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;
        }
    }
}
.showlanbx{
    .h-hovbox{
    opacity: 1 !important;
    }
}
.thr-linkbx{
    display: none;
    @media (max-width:991px) {
        display: block;
        width: 100%;
        padding: 0 40px;
        ul li{
            a{
                font-size: pxToEm(16);
                padding: 28px 0;
                color: #0073d8;
            }
        }
    }
}
.tm-bx{
    display: none;
    @media (max-width:991px) {
        display: block;
        width: 100%;
        // padding: 0 40px;
        ul li{
            border-top: 1px solid #a4d4ff;
            a{
                font-size: pxToEm(16);
                padding: 28px 0;
                color: #0073d8;
                position: relative;
                display: block;
                width: calc(100% - 80px);
                margin: 0 auto;
            }
            &:first-child{
                a{
                    padding-left: 40px;
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        background: url(../images/phone_icon_mb.png) no-repeat center center / 15px;
                        width: 26px;
                        height: 26px;
                        opacity: 1;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            &:nth-child(2){
                a{
                    padding-left: 40px;
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        background: url(../images/mail_icon_mb.png) no-repeat center center / 15px;
                        width: 26px;
                        height: 26px;
                        opacity: 1;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}
.h-search{
    top: -3px;
    @media (max-width:992px) {
        display: none;
    }
    .svg{
        width: 20px;
        height: 20px;
        path{
            transition: .4s;
        }
    }
    @media (min-width:768px) {
        &:hover{
            .svg{
                path{
                    fill: $hover !important;
                }
            }
        }
    }
}
.mo-serach{
    display: none;
    @media (max-width:992px) {
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 65px;

    }
}

.animate{
    &::before{
        animation: humanibe .4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
    }
    &::after{
        animation: humaniaf .4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
    }
    span::before{
        animation: humanspbe .4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
    }
    @keyframes humanibe {
        0%{
           opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }
    @keyframes humaniaf {
        0%{
            transform: rotate(0deg);
            top:8px;
            width: 15px;
        }
        70%{
            transform: rotate(-55deg);
            top: 0px;
            width: 30px;
        }
        100%{
            transform: rotate(-45deg);
            top: 0px;
            width: 30px;
        }
    }
    @keyframes humanspbe {
        0%{
            transform: rotate(0deg);
            top: -9px;
        }
        70%{
            transform: rotate(55deg);
            top: 0px;
        }
        100%{
            transform: rotate(45deg);
            top: 0px;
        }
    }
}
.colsanimate{
    &::before{
        animation: colshumbf .4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
    }
    &::after{
        animation: colshumaf .4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
    }
    span::before{
        animation: colspanbe .4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
    }
    @keyframes colshumbf {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    @keyframes colshumaf {
        0%{
            transform: rotate(-45deg);
            top: 0px;
        }
        70%{
            transform: rotate(5deg);
            top:8px;
        }
        100%{
            transform: rotate(0deg);
            top:8px;
        }
    }
    @keyframes colspanbe {
        0%{
            transform: rotate(45deg);
            top: 0px;
        }
        70%{
            transform: rotate(-5deg);
            top: -4px;
        }
        100%{
            transform: rotate(0deg);
            top: -4px;
        }
    }
}
.moshow{
    display: none;
    @media (max-width:992px) {
        display: block;
    }
}

.novalhid{
    display: none !important;
}
.ioshead{
    top: 0 !important;
}

.nav_box nav>ul>li:nth-child(6) .h-listitem-box>a{
    @media (max-width:992px) {
        padding-top: 32px;
    }
}
.nav_box nav>ul>li:nth-child(6) .h-listitem-box a:first-child{
    @media (max-width:992px) {
        padding-top: 48px;
    }
}

.nav_box nav>ul>li:nth-child(5) .h-listitem-box>a{
    @media (max-width:992px) {
        padding-top: 32px;
    }
}
.nav_box nav>ul>li:nth-child(5) .h-listitem-box a:first-child{
    @media (max-width:992px) {
        padding-top: 48px;
    }
}

.nav_box nav>ul>li:nth-child(4) .h-listitem-box>a{
    @media (max-width:992px) {
        padding-top: 32px;
    }
}
.nav_box nav>ul>li:nth-child(4) .h-listitem-box a:first-child{
    @media (max-width:992px) {
        padding-top: 48px;
    }
}

.nav_box nav>ul>li:nth-child(7) .h-listitem-box>a{
    @media (max-width:992px) {
        padding-top: 32px;
    }
}
.nav_box nav>ul>li:nth-child(7) .h-listitem-box a:first-child{
    @media (max-width:992px) {
        padding-top: 48px;
    }
}

.nav_box nav>ul>li:nth-child(3) .h-listitem-box>a{
    @media (max-width:992px) {
        padding-top: 32px;
    }
}
.nav_box nav>ul>li:nth-child(3) .h-listitem-box a:first-child{
    @media (max-width:992px) {
        padding-top: 48px;
    }
}

.iostop{
    height: 100vh;
    position: fixed;
}