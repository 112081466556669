footer{
    z-index : -10;
    padding: 25px 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #ffffff;
    @media (max-width:991px) {
        display: block;
        padding: 45px 25px 65px 25px;
        border-top: 1px solid $hover;
    }
    .footer-oubx{
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media (max-width:991px) {
            display: block;
            max-width: 420px;
            margin: 0 auto;
            width: 100%;
        }
    }
    .l-bx{
        display: flex;
        @media (max-width:991px) {
            display: block;
        }
        .f-logo{
            background: url(../images/logo_mb.png) no-repeat center center/ cover;
            width: 86px;
            height: 35px;
            @media (max-width:991px) {
                width: 60px;
                height: 25px;
            }
        }
        ul{
            margin-left: 30px;
            @media (max-width:991px) {
                margin-left: 0;
                margin-top: 15px;
            }
            li{
                display: flex;
                @include fontset(16,2.5,.32,400,#6d6d6d);
                @media (max-width:1200px) {
                    display: block;
                }
                @media (max-width:991px) {
                    line-height: 1;
                }
                a{
                    display: block;
                    @include fontset(16,2.5,.32,400,#6d6d6d);
                }
                .tel,.dress{
                    margin-left: 25px;
                    @media (max-width:1200px) {
                        margin-left: 0;
                    }
                }
                .cony{
                    padding-left: 23px;
                    position: relative;
                    min-width: 195px;
                    
                    @media (max-width:991px) {
                        line-height: 2.2;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        background: url(../images/company_icon_mb.png) no-repeat center / cover;
                        width: 15px;
                        height: 19px;
                        left: 0;
                        top: 11px;
                        @media (max-width:991px) {
                            width: 13px;
                            height: 17px;
                            top: 9px;
                        }
                    }
                }
                .tel{
                    padding-left: 23px;
                    position: relative;
                    @media (max-width:991px) {
                        line-height: 2.2;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        background: url(../images/phone_icon_mb.png) no-repeat center / cover;
                        width: 15px;
                        height: 15px;
                        left: 0;
                        top: 12px;
                        @media (max-width:991px) {
                            width: 13px;
                            height: 13px;
                        }
                    }
                }
                .mail{
                    padding-left: 23px;
                    position: relative;
                    min-width: 195px;
                    @media (max-width:991px) {
                        line-height: 2.2;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        background: url(../images/mail_icon_mb.png) no-repeat center / cover;
                        width: 19px;
                        height: 14px;
                        left: 0;
                        top: 14px;
                        @media (max-width:991px) {
                            width: 16px;
                            height: 11px;
                        }
                    }
                }
                .dress{
                    padding-left: 23px;
                    position: relative;
                    @media (max-width:991px) {
                        line-height: 1.6;
                        margin-top: 5px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        background: url(../images/address_icon_mb.png) no-repeat center / cover;
                        width: 16px;
                        height: 20px;
                        left: 0;
                        top: 11px;
                        @media (max-width:991px) {
                            width: 14px;
                            height: 17px;
                            top: 5px;
                        }
                    }
                }
            }
        }
    }
    .r-bx{
        @media (max-width:991px) {
            margin-top: 58px;
        }
        .pch-btn{
            margin-left: auto;
            margin-bottom: 16px;
            @media (max-width:991px) {
                margin: 0;
                margin-bottom: 10px;
            }
            div{
                position: relative;
                padding-left: 34px;
                &::before{
                    position: absolute;
                    content: '';
                    width: 22px;
                    height: 21px;
                    left: 19px;
                    top: 13px;
                    background: url(../images/shop_bt_icon_mb.png) no-repeat center / cover;
                    transition: .4s;
                    @media (max-width:991px) {
                        width: 20px;
                        height: 19px;
                        top: 11px;
                    }
                }
            }
            @media (min-width:768px) {
                &:hover{
                    div:before{
                        background: url(../images/hoshop_bt_icon_mb.png) no-repeat center / cover;
                    }
                }
               
            }
        }
        .copright{
            @include fontset(16,1.88,.32,400,#6d6d6d);
            @media (max-width:991px) {
                letter-spacing: 0;
            }
        }
        .a-bx{
            display: flex;
            justify-content: flex-end;
            @media (max-width:991px) {
                justify-content: flex-start;
            }
        }
        .rule{
            @include fontset(16,1.88,.32,500,$hover);
            padding-bottom: 3px;
            border-bottom: 1px solid $hover;
        }
        .mak{
            @include fontset(16,1.88,.32,400,#6d6d6d);
            padding-left: 12px;
            margin-left: 8px;
            position: relative;
            &::before{
                position: absolute;
                content: '';
                left: 0;
                top: 8px;
                height: 60%;
                width: 1px;
                background-color: #6d6d6d;
            }
        }
    }
}
.r-fixfb{
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 64px;
    height: 88px;
    border-radius: 15px 0 0 0 ;
    z-index: 999;
    background: url(../images/fb_icon_sidebar_mb.png) no-repeat center center / 21px;
    background-color: #0073d8;
    @media (max-width:991px) {
        width: 46px;
        height: 63px;
        background: url(../images/fb_icon_sidebar_mb.png) no-repeat center center / 17px;
        background-color: #0073d8;
        border-radius: 7.5px 0 0 0 ;
    }
    &::before{
        position: absolute;
        content: '';
        width: 68px;
        height: 96px;
        border-radius: 15px 0 0 0 ;
        border: 1px solid $hover;
        opacity: 0;
        transition: .4s;
        right: 0;
        top: -4px;
        @media (max-width:991px) {
            border-radius: 7.5px 0 0 0 ;
        }
    }
    @media (min-width:991px) {
        &:hover{
            &::before{
                opacity: 1;
            }            
        }
    }
}

