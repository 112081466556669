
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap');
//-------------------------字型定義-------------------------//
// 
// @font-face {
//     font-family: "FuturaLT";//內文
//     src: url(../assets/FuturaLT.ttf);
// }

// @font-face {
//     font-family: "DIN-Black";//標題 選單
//     src: url(../assets/DINPro-Bold_13934.ttf);
// }



$font_content:  "Noto Sans TC","微軟正黑體" ,Verdana, sans-serif;
$font_noto: 'Noto Sans TC';



//-------------------------文字顏色-------------------------//
$black:#000;
$white:#ffffff;
$content_Txt:$black;
// $editor:#7d7d7d;//編輯器用
$blue:#4759d9;
$blue_u:#2f3a88;
$orange : #de4242;
$green:#00886a;
$green_hover:#88c7b9;
$path:#999999;
$hover:#0073d8;
$main_color:#666; //主要顏色

$dots:#545759;
$rad:#de4242;
$inpur_color:#eeeeee;
$seach_line:#dcdcdc;


//-------------------------文字大小定義-------------------------//
$txt_12:0.750rem;//12
$txt_13:0.813rem;//13
$txt_14:0.875rem;//14
$txt_15:0.938rem;//15
$txt_16:1.000rem;//16
$txt_17:1.063rem;//17
$txt_18:1.125rem;//18
$txt_19:1.188rem;//19
$txt_20:1.250rem;//20
$txt_21:1.313rem;//21
$txt_22:1.375rem;//22
$txt_23:1.438rem;//23
$txt_24:1.500rem;//24
$txt_25:1.563rem;//25
$txt_26:1.625rem;//26
$txt_27:1.688rem;//27
$txt_28:1.750rem;//28
$txt_29:1.813rem;//29
$txt_30:1.875rem;//30