.articlesearchPage{
    .item1{
        margin-top: 70px;
        margin-bottom: 60px;
        @media (max-width:767px) {
            margin-bottom: 25px;
        }
        .key-bx{
            display: flex;
            align-items: center;
            .ti{
                @include fontset(18,1,.45,300,#6d6d6d);
            }
            .tag{
                background-color: $hover;
                color: #ffffff;
                margin-left: 10px;
            }
        }
    }
    .item2{
        margin-bottom: 70px;
        @media (max-width:767px) {
            margin-bottom: 40px;
        }
        ul{
            display: flex;
            padding-bottom: 6px;
            border-bottom: 1px solid $hover;
            li{
                &:first-child{
                    margin-right: 6px;
                }
                a{
                    @include fontset(18,1,.45,300,$hover);
                    transition: .4s;
                    border-radius: 10px 0 10px 0 ;
                    padding: 10px 15px;
                    display: block;
                    @media (min-width:768px) {
                        &:hover{
                            color: #ffffff;
                            background-color: $hover;
                        }
                    }
                    @media (max-width:767px) {
                        border-radius: 5px 0 5px 0 ;
                    }
                }
            }
            li.active{
                a{
                    color: #ffffff;
                    background-color: $hover;
                }
            }
        }
    }
    .item3{
        .it3-bx{
            display: flex;
            @media (max-width:767px) {
                display: block;
            }
            .r-bx{
                order: 2;
                width: 17.6%;
                @media (max-width:767px) {
                    width: 100%;
                    margin-bottom: 40px;
                }
                .ti{
                    @include fontset(18,1,.45,300,$hover);
                    padding-bottom: 15px;
                    padding-left: 12px;
                    border-left: 1px solid #dcdcdc;
                    @media (max-width:767px) {
                        padding-left:0;
                        border-left: none;
                    }
                }
                .tag-bx{
                    padding-left: 12px;
                    border-left: 1px solid #dcdcdc;
                    position: relative;
                    @media (max-width:767px) {
                        padding-left:0;
                        border-left: none;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        bottom: 0;
                        left: -3px;
                        width: 4px;
                        height: 9px;
                        background-color: #ffffff;
                    }
                    a{
                        margin-bottom: 10px;
                        
                    }
                }
            }
            .l-bx{
                order: 1;
                width: 79.3%;
                margin-right: 3%;
                @media (max-width:767px) {
                    width: 100%;
                    margin-right: 0;
                }
                ul{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    @media (max-width:767px) {
                        display: block;
                    }
                    li{
                        width: 48.5%;
                        margin-bottom: 24px;
                        @media (max-width:767px) {
                            width: 100%;
                            margin-bottom: 30px;
                        }
                        @media (min-width:768px) {
                            &:nth-last-child(2){
                                margin-bottom: 0;
                            }
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                        
                        a{
                            position: relative;
                            .img-bx{
                                position: relative;
                                margin-bottom: 24px;
                                border-radius: 15px 0 15px 0;
                                @media (max-width:767px) {
                                    border-radius: 7px 0 7px 0;
                                    margin-bottom: 15px;
                                }
                                img{
                                    border-radius: 15px 0 15px 0;
                                    @media (max-width:767px) {
                                        border-radius: 7px 0 7px 0;
                                    }
                                }
                                &::before{
                                    position: absolute;
                                    content: '';
                                    width: 100%;
                                    height: 100%;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%,-50%);
                                    background-color: $hover;
                                    pointer-events: none;
                                    opacity: 0;
                                    transition: .4s;
                                    border-radius: 15px 0 15px 0;
                                    @media (max-width:767px) {
                                        border-radius: 7px 0 7px 0;
                                    }
                                    
                                }
                                &::after{
                                    position: absolute;
                                    content: '';
                                    background: url(../images/b_bt.png) no-repeat center center / cover;
                                    width: 67px;
                                    height: 67px;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%,-50%);
                                    transition: .2s ease;
                                    pointer-events: none;
                                    opacity: 0;
                                }
                            }
                            @media (min-width:768px) {
                                &:hover{
                                    .img-bx::before{
                                        opacity: .5;
                                    }
                                    .img-bx::after{
                                        opacity: 1;
                                    }
                                }
                            }
                            .date{
                                @include fontset(16,1,.4,300,#bebebe);
                                margin-bottom: 10px;
                                @media (max-width:767px) {
                                    margin-bottom: 13px;
                                }
                            }
                            .des-ti{
                                @include fontset(26,1.38,.6,500,$hover);
                                margin-bottom: 3px;
                            }
                        }
                        .des{
                            @include fontset(16,1.88,.3,300,#6d6d6d);
                            @include line-clamp(2);
                        }
                        .tag-bx{
                            margin-top: 20px;
                            height: 82px;
                            overflow: hidden;
                            @media (max-width:767px) {
                                margin-top: 20px;
                                height: auto;
                                max-height: 73px;
                            }
                            a{
                                margin-right: 5px;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
        .page{
            margin-top: 40px;
            margin-bottom: 100px;
            @media (max-width:767px) {
                margin-bottom: 130px;
                margin-top: 50px;
            }
        }
    }
    .container{
        @media (max-width:991px) and (min-width:768px){
            max-width: 100%;
        }
    }
}
.articlelistPage{
    .item2{
        margin-top: 70px;
        @media (max-width:767px) {
            margin-top: 40px;
        }
    }
}
.hiddendiv{
    display: none;
}

.contactPage{
    .container{
        @media (max-width:991px) and (min-width:768px){
            max-width: 100%;
        }
    }
    .item{
        margin-top: 90px;
        margin-bottom: 100px;
        position: relative;
        @media (max-width:767px) {
            margin-top: 75px;
            margin-bottom: 70px;
        }
        .container{
            position: relative;
        }
        .b-ti{
            @include fontset(60,1,1.5,500,$hover);
            margin-bottom: 60px;
            @media (max-width:767px) {
                font-size: pxToEm(40);
                margin-bottom: 20px;
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    left: -125px;
                    top: -36px;
                    width: 115px;
                    height: 175px;
                    transform-origin:bottom;
                    transform:skewX(-32deg);
                    background-color: #bce0ff;
                    opacity: .2;
                    border-radius: 15px 0 15px 0 ;
                    z-index: -1;
                }
            }
        }
        .con-bx{
            display: flex;
            margin-bottom: 70px;
            @media (max-width:767px) {
                display: block;
                margin-bottom: 35px;
            }
            .l-bx{
                background-color: #c7e5ff;
                padding-top: 70px;
                padding-left: 47px;
                padding-right: 20px;
                padding-bottom: 70px;
                width: 41.2%;
                @media (max-width:767px) {
                    width: 100%;
                    padding: 50px 20px 20px 20px;
                }
                ul{
                    max-width: 305px;
                    li{
                        position: relative;
                        padding-left: 50px;
                        margin-bottom: 27px;
                        @media (max-width:767px) {
                            margin-bottom: 20px;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            left: 0;
                            width: 35px;
                            height: 35px;
                        }
                        .ti{
                            @include fontset(18,1.75,.3,500,#004887);
                            margin-bottom: 2px;
                        }
                        .des{
                            @include fontset(16,1.75,.3,300,#004887);
                        }
                        &:nth-child(1){
                            &::before{
                                background: url(../images/contact/map_icon_mb.png) center center / cover;
                            }
                        }
                        &:nth-child(2){
                            &::before{
                                background: url(../images/contact/email_icon_mb.png) center center / cover;
                            }
                        }
                        &:nth-child(3){
                            &::before{
                                background: url(../images/contact/phone_icon_mb.png) center center / cover;
                            }
                        }
                        &:nth-child(4){
                            &::before{
                                background: url(../images/contact/fax_icon_mb.png) center center / cover;
                            }
                        }
                        &:nth-child(5){
                            &::before{
                                background: url(../images/contact/editor_icon_mb.png) center center / cover;
                            }
                        }
                        &:nth-child(6){
                            &::before{
                                background: url(../images/contact/bank_icon_mb.png) center center / cover;
                            }
                        }
                    }
                }
            }
            .r-bx{
                padding-left: 8%;
                padding-right: 7%;
                width: 59%;
                padding-top: 70px;
                background-color: #f6f6f6;
                @media (max-width:767px) {
                    width: 100%;
                    padding: 0 15px;
                    padding-top: 50px;
                    padding-bottom: 45px;
                }
                .form-bx{
                    .flex-bx{
                        display: flex;
                        @media (max-width:767px) {
                            display: block;
                        }
                    }
                    .input-bx{
                        width: 100%;
                        @media (max-width:767px) {
                            margin-top: 15px;
                        }
                    }
                    .form-group{
                        margin-bottom: 20px;
                    }
                    label{
                        i{
                            color: $hover;
                        }
                        @include fontset(18,1,.45,500,#6d6d6d);
                        margin-right: 5%;
                        min-width: 84px;
                        @media (max-width:767px) {
                            font-size: pxToEm(16);
                          
                        }
                    }
                    input{
                        height: 40px;
                        border-radius: 0;
                        background-color: #ffffff;
                        border-bottom: 1px solid rgba(255,255,255,0);
                        transition: .4s;
                        padding-left: 11px;
                        &:focus{
                            border-bottom: 1px solid $hover;
                        }
                    }

                    .form-textbx{
                        margin-bottom: 30px;
                        @media (max-width:767px) {
                            margin-bottom: 0;
                        }
                        .text-bx{
                            width: 100%;
                            @media (max-width:767px) {
                                margin-top: 15px;
                            }
                        }
                        .focus.textarea-wrapper{
                            border-bottom: 1px solid $hover;
                        }
                        .textarea-wrapper{
                            height: 160px;
                            overflow: hidden;
                            border:none;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            background-color: #ffffff;
                            transition: .4s;
                            border-bottom: 1px solid rgba(255,255,255,0);
                            @media (max-width:767px) {
                                height: 180px;
                            }
                            textarea{
                                min-height:148px;
                                height: 0;
                                overflow: hidden;
                                border-bottom:none;
                                padding-top:5px;
                                padding-left: 11px;
                                text-rendering: auto;
                                -webkit-writing-mode: horizontal-tb !important;
                                background-color: #ffffff;
                                resize: none;
                                border: none;
                                font-family: $font_content;
                                cursor:text;
                                line-height: 1;
                                @media (max-width:767px) {
                                    min-height: 168px;
                                }
                                &:focus{
                                            border: none ;
                                        }
                            }
                        }
                        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
                            background-color: #000000;
                            // width: 1px;
                        }
                        .mCSB_scrollTools .mCSB_draggerRail{
                            background-color: #000000;
                            width: 1px;
                        }
                        .mCSB_scrollTools{
                            right: -5px;
                        }
                        .mCSB_inside>.mCSB_container{
                            margin-right: 13px;
                        }
                        .mCSB_scrollTools .mCSB_draggerContainer{
                            top: 0px;
                            left: -12px;
                        }
                    }

                    .help-block{
                        margin-top: 5px;
                        @include fontset(14,1,.35,300,$hover);
                    }
                    .send-btn{
                        width: 96px;
                        height: 48px;
                        margin-left: auto;
                        div{
                            @media (max-width:767px) {
                                line-height: 3.1;
                            }
                        }
                    }
                }
            }
        }
        .l-sqbx{
            position: absolute;
            left: -370px;
            top: 30px;
            z-index: -1;
            @media (max-width:767px){
                display: none;
            }
            .t-bx,.b-bx{
                display: flex;
            }
            .b-bx{
                margin-left: 15px;
                .sq:first-child{
                    position: relative;
                    top: -50px;
                }
            }
            .t-bx{
                .sq{
                    &:first-child{
                        margin-top: 60px;
                        position: relative;
                        left: -50px;
                    }
                }
            }
            .sq{
                width: 150px;
                height: 250px;
                transform-origin:bottom;
                transform:skewX(-32deg);
                background-color: #bce0ff;
                opacity: .2;
                border-radius: 15px 0 15px 0 ;
                margin: 0 15px;
                margin-bottom: 30px;
                
            }
        }
        .r-sqbx{
            position: absolute;
            right: -240px;
            bottom: 330px;
            z-index: -1;
            @media (max-width:767px){
                display: none;
            }
            .t-bx{
                display: flex;
                .sq:nth-child(2){
                    position: relative;
                    top: 130px;
                    left: -70px;
                }
            }
           
            .sq{
                width: 150px;
                height: 250px;
                transform-origin:bottom;
                transform:skewX(-32deg);
                background-color: #bce0ff;
                opacity: .2;
                border-radius: 15px 0 15px 0 ;
                margin: 0 15px;
                margin-bottom: 30px;
            }
        }
        .map-bx{
            iframe{
                height: 320px;
            }
        }
    }
    
}

.searchlistPage{
    .item1{
        margin-top: 70px;
        margin-bottom: 60px;
        @media (max-width:767px) {
            margin-bottom: 25px;
        }
        .key-bx{
            display: flex;
            align-items: center;
            .ti{
                @include fontset(18,1,.45,300,#6d6d6d);
            }
            .s-des{
                @include fontset(18,1,.2,300,$hover);
                position: relative;
                margin-left: 5px;
                &::before{
                    position: absolute;
                    content: '';
                    bottom: -5px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $hover;
                }
            }
        }
    }
}
.articledetailPage{
    .item2{
        margin-top: 70px;
        margin-bottom: 60px;
        @media (max-width:767px) {
            margin-bottom: 25px;
        }
    }
    .item3{
        .t-bx{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            @media (max-width:767px) {
                display: block;
            }
            .tit-blsq{
                max-height: 52px;
                overflow: hidden;
                @media (max-width:767px) {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    max-height: initial;
                    overflow: inherit;
                }
                div{
                    font-size: 26px;
                    &::before{
                        top: 2px;
                        left: 6px;
                        @media (max-width:767px) {
                            top:-3px;
                            left: 0;
                            width: 40px;
                            height: 60px;
                        }
                    }
                }
            }
            .date{
                @include fontset(16,1,.4,500,#bebebe);
            }
        }
        .it3-bx{
            display: block;
            .editor_Content{
                margin-top: 15px;
                margin-bottom: 60px;
                @media (max-width:767px) {
                    margin-bottom: 40px;
                }
            }
            .it3-inbx{
                padding-bottom: 30px;
                border-bottom: 1px solid $hover;
                @media (max-width:767px) {
                    padding-bottom: 45px;
                }
            }
            .sh-mbtn{
                color: $hover;
                margin-top: 32px;
                &::before,&::after{
                    background-color: $hover;
                }
            }
            .tag-bx{
                .tag{
                    margin-right: 10px;
                    margin-bottom: 10px;   
                    @media (max-width:767px) {
                        margin-right: 5px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
    .item4{
        margin-top: 60px;
        margin-bottom: 75px;
        @media (max-width:991px) {
            margin-top: 70px;
            margin-bottom: 15px;
        }
        .re-ti{
            @include fontset(26,1.38,.65,500,$hover);
            text-align: center;
            margin-bottom: 35px;
        }
        .thr-list>li{
            width: 33.33333%;
            flex-wrap: wrap;
            @media (max-width:991px) and (min-width:768px) {
                // width: 50%;
            }
            @media (max-width:767px) {
                width: 100%;
                padding-bottom: 15px;
                margin-bottom: 25px;
            }
            @media (max-width:767px) {
                &:not(:last-child){
                    border-bottom: 1px solid #d2d2d2;
                }
            }
        }
        .thr-list{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li{
                width: 32%;
                &:not(:last-child){
                    margin-right: 2%;
                }
                @media (max-width:767px) {
                    width: 100%;
                    margin-bottom: 25px;
                    border-bottom: none !important;
                }
                
                a{
                    position: relative;
                    .img-bx{
                        position: relative;
                        margin-bottom: 24px;
                        border-radius: 15px 0 15px 0;
                        @media (max-width:767px) {
                            border-radius: 7px 0 7px 0;
                            margin-bottom: 15px;
                        }
                        img{
                            border-radius: 15px 0 15px 0;
                            @media (max-width:767px) {
                                border-radius: 7px 0 7px 0;
                            }
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            background-color: $hover;
                            pointer-events: none;
                            opacity: 0;
                            transition: .4s;
                            border-radius: 15px 0 15px 0;
                            @media (max-width:767px) {
                                border-radius: 7px 0 7px 0;
                            }
                            
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            background: url(../images/b_bt.png) no-repeat center center / cover;
                            width: 67px;
                            height: 67px;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            transition: .2s ease;
                            pointer-events: none;
                            opacity: 0;
                        }
                    }
                    @media (min-width:768px) {
                        &:hover{
                            .img-bx::before{
                                opacity: .5;
                            }
                            .img-bx::after{
                                opacity: 1;
                            }
                        }
                    }
                    .date{
                        @include fontset(16,1,.4,300,#bebebe);
                        margin-bottom: 10px;
                        @media (max-width:767px) {
                            margin-bottom: 13px;
                        }
                    }
                    .des-ti{
                        @include fontset(26,1.38,.6,500,$hover);
                        margin-bottom: 3px;
                        height: 70px;
                        overflow: hidden;
                        @media (max-width:767px) {
                            height: auto;
                        }
                    }
                }
                .des{
                    @include fontset(16,1.88,.3,300,#6d6d6d);
                    @include line-clamp(2);
                }
                .tag-bx{
                    margin-top: 20px;
                    height: 82px;
                    overflow: hidden;
                    @media (max-width:767px) {
                        margin-top: 20px;
                        height: auto;
                        max-height: 73px;
                    }
                    a{
                        margin-right: 5px;
                        margin-bottom: 5px;
                    }
                }
            }
           
        }
    }
}